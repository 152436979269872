import React from 'react';
import PropTypes from 'prop-types';
import labelStyle from './label.module.scss';
import ReactTooltip from 'react-tooltip';

const Label = props => {
  const getClassName = () => {
    let classes = '';
    if (props.bold) {
      classes += labelStyle.boldLabel + ' ';
    }
    if (props.neutral) {
      classes += labelStyle.neutralLabel + ' ';
    }
    if (props.capital) {
      classes += labelStyle.capitalLabel + ' ';
    }
    if (props.hidenText) {
      classes += labelStyle.hidenText + ' ';
    }
    if (props.hiden2Text) {
      classes += labelStyle.hiden2Text + ' ';
    }
    return classes + props.className;
  };

  const styleDefault = {
    fontSize: props.size ? props.size + 'px' : '1em',
    margin: props.margin ? props.margin : '0px',
    color: props.color ? props.color : '',
  };

  const styleObj = { ...styleDefault, ...props.style };

  return (
    <>
      <label
        style={styleObj}
        className={getClassName()}
        onClick={props.onClick}
        data-tip={props.dataTip}
        data-for={props.dataForId}
      >
        {props.label ? props.label : ''}
      </label>

      {props.dataTip && (
        <ReactTooltip
          id={props.dataForId}
          effect="solid"
          place="top"
          type="warning"
          className={labelStyle.tooltip}
        />
      )}

      {props.required ? (
        <span style={{ color: 'red', paddingLeft: '3px' }}>*</span>
      ) : (
        ''
      )}
    </>
  );
};

Label.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  bold: PropTypes.bool,
  neutral: PropTypes.bool,
  capital: PropTypes.bool,
  size: PropTypes.number,
  margin: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default Label;
