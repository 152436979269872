export default {
  sample_no_production_date_message:
    'Se debe seleccionar la fecha de fabricación',
  free_comment: 'Atributo libre',
  round_list_rounds: 'Sesión',
  round_edit_round: 'Editar Sesión',
  round_new_round: 'Nueva Sesión',
  round_remove_round: '¿Está seguro de que desea eliminar la Sesión',
  round_schedule: 'Programación',
  round_tasters_unselect_all: 'Eliminar todos los degustadores',
  round_tasters_select_all: 'Seleccionar todos los degustadores',
  round_close: 'Cerrar sesión ',
  round_close_message: '¿¿ estás seguro de que quieres completar esta ronda',
  round_create_site_message: '¡Está creando una sesión que no es de su planta!',
  round_closed_message: 'Las sesiones concluidas no se pueden editar',
  round_status_created: 'Creada',
  round_status_samples: 'Muestras',
  round_status_scheduled: 'Programada',
  round_status_tasters: 'Degustadores',
  round_status_in_progress: 'En Progreso',
  round_status_finished: 'Terminado',
  round_status_closed: 'Concluida',
  round_schedule_start_finish_date:
    'La fecha de inicio no puede ser mayor que la fecha de finalización',
  round_schedule_finish_date_no_start_date:
    'Primero debes seleccionar una fecha de inicio',
  round_disabled_discussion_tooltip:
    'No puedes ir a una Discusión de una ronda inconclusa.',
  round_delete_other_users_rounds:
    'No tienes permiso para eliminar una Sesión que no has creado',
  round_randomize_samples_order_button: 'Barajar',
  round_block_results_tooltip:
    'Bloquea los resultados de esta ronda para que no se descarguen en la pantalla de informes. Si el botón está deshabilitado, no tiene el privilegio de bloquear los resultados de las rondas desde esta ubicación.',
  round_block_results_confirm:
    ' ¿Estás seguro de que quieres bloquear/desbloquear los resultados de la ronda ',
  round_block_results_no_privilege:
    'No tiene el privilegio de bloquear / desbloquear los resultados de la ronda desde esta ubicación.',
  sample_list_samples: 'Muestras',
  sample_edit_sample: 'Editar Muestra',
  sample_new_sample: 'Nueva Muestra',
  sample_remove_sample: 'Suprimir Muestra',
  sample_upload_document: 'Cargar documentos',
  sample_download_documents: 'Documentos de muestra',
  sample_tasted_dates: 'Fecha de degustación',
  sample_upload_message: 'Para cargar varios documentos, agréguelos a un .zip',
  sample_not_for_kpi: 'No es para el KPI',
  sample_package: 'Envase',
  sample_volume: 'Volumen',
  sample_production_date: 'Fecha de Producción',
  sample_expire_date: 'Fecha de vencimiento',
  sample_trial_identifier: 'Nombre de de prueba',
  sample_project_name: 'Nombre del proyecto',
  sample_linked: 'Vinculado a una sesión',
  sample_expire_bigger_than_production:
    'La Fecha de Producción no puede ser mayor que la Fecha de Vencimiento',
  sample_no_site_message:
    'Es obligatorio seleccionar una Zona, un País y una Planta para crear una muestra.',
  sample_no_brand_message: 'Es obligatorio seleccionar una Marca',
  sample_duplicated_kpi_message:
    'Ya existe un KPI para el mes, la marca y el planta que seleccionó y no podrá guardar',
  sample_duplicated_project_message:
    'Ya existe una muestra con este nombre de proyecto e identificador de prueba para esta planta y marca, y no podrá guardar',
  sample_project_no_name_or_trial:
    'El nombre del proyecto y el identificador de prueba son obligatorios al crear una muestra del tipo de proyecto.',
  sample_production_to_project_confirm_message:
    'Está cambiando el tipo de esta muestra de PRODUCCIÓN a PROYECTO. ¿Desea continuar?',
  sample_project_to_production_confirm_message:
    'Está cambiando el tipo de esta muestra de PROYECTO a PRODUCCIÓN. ¿Desea continuar?',
  sample_no_volume_message:
    'El envase y el volumen son obligatorios al crear / editar una muestra.',
  sample_id_code_message: 'Incluya el número de lote completo',
  sample_show_all_samples_hint:
    'Mostrando muestras creadas hasta dentro de un año. Marque esta casilla si también desea ver muestras más antiguas',
  sample_show_all_samples_label: 'Mostrar todas las muestras',
  package_duplicate: 'Ya hay un envase con este nombre',
  common_production: 'Producción',
  common_project: 'Proyecto',
  group_list_groups: 'Grupos',
  group_edit_group: 'Editar Grupo',
  group_new_group: 'Nuevo Grupo',
  group_remove_group: 'Eliminar Grupo',
  group_close_modal: 'Finalizar',
  group_duplicated_user: '¡Este usuario ya ha sido agregado a este grupo!',
  group_no_name_message: 'Es necesario ingresar un nombre de grupo',
  group_no_zone_message: 'Es necesario seleccionar una zona',
  group_no_users_message: 'Es necesario seleccionar al menos un degustador',
  family_list_families: 'Estilos',
  family_edit_family: 'Editar estilo',
  family_new_family: 'Nuevo estilo',
  family_remove_family: 'Eliminar estilo',
  family_has_no_english_name:
    'Es obligatorio tener el nombre del estilo en inglés',
  brand_list_brands: 'Marcas',
  brand_edit_brand: 'Editar Marca',
  brand_new_brand: 'Nueva Marca',
  brand_remove_brand: 'Eliminar Marca',
  brand_profile_picture: 'Cargar la Imagen del perfil de la cerveza',
  brand_upload_brand_logo: 'Cargar el logo de la Marca',
  brand_duplicate: 'Aviso: ya existe una cerveza con este nombre',
  brand_has_no_english_name:
    'Debe completar un nombre de marca y un perfil de cerveza correspondiente',
  brand_upload_help_message: 'Solo archivos PNG y JPG',
  brand_has_no_product_type: 'Debe seleccionar un tipo de producto',
  package_list_packages: 'Envases',
  package_edit_package: 'Editar Envase',
  package_new_package: 'Nuevo Envase',
  package_remove_package: 'Eliminar Envase',
  package_has_no_english_name:
    'es obligatorio tener el nombre del Envase en inglés',
  package_volumes: 'Volúmenes',
  package_new_volume: 'Nuevo Volumen',
  package_remove_volume: 'Eliminar Volumen',
  degree_list_degrees: 'Intensidades',
  degree_edit_degree: 'Editar Intensidad',
  degree_new_degree: 'Nueva Intensidad',
  degree_remove_degree: 'Eliminar Intensidad',
  category_list_categories: 'Categorías',
  category_edit_category: 'Editar categoría de sabor ',
  category_new_category: 'Nueva categoría de sabor ',
  category_remove_category: 'Eliminar categoría de sabor',
  comment_list_comments: 'Atributos',
  comment_edit_comment: 'Editar atributo de sabor ',
  comment_new_comment: 'Nuevo atributo de sabor ',
  comment_remove_comment: 'Eliminar atributo de sabor',
  user_list_users: 'Usuarios',
  user_edit_user: 'Editar Usuario',
  user_new_user: 'Nuevo contratista de ABI',
  user_export_users: 'Export Users',
  user_list_profiles: 'Perfiles',
  user_profile_name: 'Nombre del perfil',
  user_profile_select: 'Seleccione un perfil',
  user_profile_remove: 'Eliminar perfil',
  user_profile_save: 'Guardar perfil',
  user_remove_user: 'Eliminar usuario',
  user_use_terms: 'Términos de uso aceptados',
  report_list_report: 'Informes',
  report_end_date: 'Fecha de conclusión',
  common_average_key_score: 'Average key score',
  common_score: 'Puntuación',
  common_privileges: 'Permisos',
  common_abi_name: 'Nombre ABI',
  common_id: 'ID',
  common_ranked: 'Clasificado',
  common_week: 'Semana',
  common_weeks: 'Semanas',
  common_days: 'Dias',
  common_category: 'Categoría de sabor ',
  common_category_sample: 'Categoría',
  common_degree: 'Intensidad',
  common_comment: 'Atributo de sabor  ',
  common_status: 'Estatus',
  common_zone: 'Zona',
  common_country: 'País',
  common_site: 'Planta',
  common_start_date: 'Fecha de Inicio',
  common_finish_date: 'Fecha de Finalización',
  common_round_type: 'Tipo de prueba',
  common_round: 'Sesión ',
  common_round_id: 'ID Sesión',
  common_tasters: 'Degustadores',
  common_sample_performance: 'Rendimiento de muestra',
  common_taste: 'Gusto',
  common_key_taster: 'Key Taster',
  common_sample: 'Muestra',
  common_samples: 'Muestras',
  common_sample_type: 'Tipo de muestra',
  common_family: 'Estilo',
  common_brand: 'Marca',
  common_product_type: 'Tipo de Producto',
  common_temperature: 'Condiciones de almacenamiento',
  common_storage_times: 'Tiempo de Almacenamiento',
  common_sku: 'SKU',
  common_batch: 'ID Código',
  common_kpi_month: 'KPI Mes',
  common_logo: 'Logo',
  common_name: 'Nombre',
  common_notes: 'Notas',
  common_email: 'E-mail',
  common_language: 'Idioma',
  common_terms_of_use: 'Términos de uso ',
  common_new: 'Nuevo',
  common_profile: 'Perfil',
  common_filter: 'Filtrar usuarios',
  common_download: 'Descargar',
  common_save: 'Guardar',
  common_cancel: 'Anular',
  common_search_users: 'Buscar Usuarios',
  common_search_group: 'Buscar Grupo',
  common_search_taster: 'Buscar Degustador',
  common_no_data: 'No hay datos para mostrar',
  common_loading: 'Cargando',
  common_cannot_be_empty: '{{field}} no puede estar vacío',
  common_select_option: 'Seleccionar opción…',
  common_per_page: 'Líneas por pagina',
  common_attributes: 'Atributos',
  common_not_started: 'No Empezado',
  common_in_progress: 'En progreso',
  common_finished: 'Terminada',
  common_start: 'comienzo',
  common_detail: 'Detalles',
  common_position: 'Position',
  common_score_scale: 'Escala de Puntuación',
  common_intensity: 'Intensidad',
  common_report_not_have_data: 'Las rondas seleccionadas no tuvieron votos',
  month_0: 'Enero',
  month_1: 'Febrero',
  month_2: 'Marzo',
  month_3: 'Abril',
  month_4: 'Mayo',
  month_5: 'Junio',
  month_6: 'Julio',
  month_7: 'Agosto',
  month_8: 'Septiembre',
  month_9: 'Octubre',
  month_10: 'Noviembre',
  month_11: 'Diciembre',
  dropdown_change_picture: 'Cambiar imagen',
  dropdown_language: 'Idioma',
  dropdown_logout: 'Terminar el modo de diálogo',
  round_not_started: 'No Empezado',
  round_in_progress: 'En progreso',
  round_finished: 'Terminada',
  round_filter_start: 'Comienzo',
  round_round_type_in_profile: 'In-Profile',
  tasting_list_rounds: 'Rondas de Lista',
  menu_tasting_session: 'Sesión de cata',
  menu_panel_management: 'Gestión de Paneles',
  menu_process_management: 'Gestión de Proceso',
  menu_product_management: 'Gestión de Productos',
  menu_attributes_management: 'Gestión de Atributos',
  tasting_finish_sample: 'Muestra de Acabado',
  tasting_comments: 'Comentarios',
  tasting_beer_profile: 'Perfil de cerveza',
  tasting_need_select_score_comments:
    'Debe establecer una puntuación y agregar al menos un comentario para continuar ...',
  tasting_finish_taste: 'Finalizar sabor',
  common_no_comments: 'Sin comentarios',
  review_submit_title: '¿Está listo para enviar los resultados?',
  review_submit_description: 'No podrás editar esto mas tarde',
  review_finish_taste: 'Concluir degustación',
  review_warning_sample_title: '¡Tienes muestras sin degustar!',
  review_warning_sample_description: '¿Quieres seguir sin probarlas?',
  review_warning_sample_review: 'Revisar',
  review_warning_sample_ok: 'Continuar',
  review_warning_tie_sample_title: '¡Tienes muestras de corbata!',
  review_warning_tie_sample_description:
    '¡No puedes continuar sin clasificarlos!',
  review_warning_tie_sample_review: 'Revisión',
  common_submit: 'Enviar',
  common_discussion: 'Discusión',
  discussion_order_number: 'Ordenar por posición',
  discussion_order_brewery: 'Ordenar por cervecería',
  discussion_order_ranking: 'Ordenar por clasificacion',
  discussion_order_brand: 'Ordenar por marca',
  discussion_order_score: 'Ordenar por puntuación',
  discussion_order_key_score: 'Ordenar por puntuación clave',
  discussion_order_overall_rank: 'Ordenar por puntaje de clasificación general',
  discussion_order_key_rank: 'Ordenar por clasificación clave',
  discussion_key_ranking: 'Clasificación clave',
  discussion_export_error: '¡No se pudo guardar el archivo!',
  discussion_ranking: 'Clasificación',
  discussion_overall_ranking: 'Clasificación general',
  common_brewery: 'Cervecería',
  common_trial_identifier: 'ID Prueba',
  common_key_score: 'Puntuación Key Taster',
  round_report: 'Exportar a Excel',
  common_comments: 'Comentarios',
  message_you_cannot_delete_all_comments:
    'No puede eliminar todos los comentarios al editar un voto!',
  common_message_empty_profile:
    'No se permite el nombre del perfil vacío o en blanco',
  common_message_out_score_range: 'puntuación fuera de rango',
  tasting_need_select_score: '¡Primero debes establecer una puntuación!',
  common_category_name: 'Categoría',
  categories_new_category: 'Nueva categoría',
  categories_remove_category: '¿Realmente desea eliminar la categoría',
  categories_list_categories: 'Listado de categorías',
  categories_list_all_categories: 'All Categories',
  common_category_sample_name: 'Muestras',
  categories_new_category_sample: 'Nueva muestra',
  categories_remove_category_sample: '¿Realmente desea eliminar la muestra',
  categories_list_categories_samples: 'Listado de muestra',
  common_duplicate: 'Duplicado',
  common_collection_point: 'Punto de colecta',
  common_collection_date: 'Fecha de colecta',
  common_brew_number: 'Número de fermentación/MES número',
  common_need_explain_nok: '¡No puede tener muestras NOK sin un comentario!',
  common_show_all: 'Mostrar todo',
  common_import: 'Importar',
  common_download_template: 'Descargar plantilla',
  common_need_volte_first: '¡No puedes comentar muestras sin votar primero!',
  common_name_template: 'Nombre de la plantilla',
  common_all: 'Todos',
  common_continue: 'Continuar',
  common_minimum_score: 'Min',
  common_maximum_score: 'Max',
  common_average_score: 'Prom',
  file_upload_fail:
    'No se pudo cargar el archivo, ocurrieron los siguientes errores: ',
  file_upload_success_with_error:
    'La carga fue exitosa excepto por lo siguiente: ',
  missing_fields: 'campos faltantes',
  column_error: 'error de columna',
  common_line: 'Línea',
  file_no_samples: '¡No se encontraron muestras en el archivo!',
  file_success_upload: '¡Cargar archivo exitoso!',
  file_duplicated_kpi_project: 'KPI o proyecto duplicado',
  round_schedule_maximum_length:
    'La longitud máxima del nombre de la ronda es de 40 caracteres.',
  sample_malt_varieties: 'Variedades de malta',
  malt_varieties_has_no_english_name:
    'Debe tener al menos un nombre de variedades de malta.',
  malt_varieties_duplicate:
    'Atención: hay una variedad de malta con este nombre.',
  malt_varieties_remove: 'Eliminar la variedad de malta',
  common_back: 'Atrás',
  common_next: 'Siguiente',
  malt_varieties_new: 'Nueva Variedad',
  reports_no_dates:
    '¡La fecha de inicio y la fecha de finalización son obligatorias!',
  reports_date_range_message:
    'El intervalo de fechas no puede ser superior a 1 año',
  round_save_select_site: 'Para guardar una ronda debes seleccionar un sitio',
  common_error: '¡UPS! Algo salió mal. Por favor, vuelva a intentarlo',
  common_horizontal: 'Horizontal',
  common_unit: 'Unidad',
  common_supplier: 'Proveedor',
  common_flavor: 'Sabor',
  concentration_of_the_base_beer: 'Concentración de la cerveza base',
  threshold_aml_method: 'Método AML (Método Ascendente de Límites)',
  threshold_component_concentration: 'Concentración de componentes',
  threshold_sample_different_help: '¿Qué muestra es diferente?',
  threshold_each_set_random_order: 'En cada conjunto, orden aleatorio.',
  threshold_select_base_beer: 'Seleccionar cerveza base',
  common_unity: 'Unidad',
  common_finish_session: 'Finalizar Sesión',
  review_submit_threshold: '¿Estás seguro de que quieres guardar?',
  threshold_coder_wrong:
    'Tu código está mal. ¡Por favor informe el código correcto!',
  threshold_didnt_enter_the_code: 'Por favor, introduzca su número de asiento',
  code: 'Código',
  threshold_samples_required: 'Se requiere cerveza base y sabor',
  threshold_information_helper_sample:
    'Para guardar la información de la muestra de umbral, haga clic en el botón Guardar para conservar los cambios.',
  code_digits: 'Ingresa el código de 3 dígitos que se te asignó',
  common_batch_code: 'Código de lote',
  threshold_sample_spiked_with:
    'En cada juego, elija la muestra que ha sido enriquecida con ',
  threshold_code_already_tasted:
    'Este código ya ha sido probado por otro usuario. Introduce otro código.',
  common_kpi_brands: 'Marcas KPI',
  common_year: 'Año',
  common_new_kpi_brand: 'Nueva marca KPI',
  common_action: 'Acción',
  common_comment_by_category: 'Comentarios por categoría',
  common_alphabetic_comment: 'Comentarios alfabéticos',
  common_name_pts_code: 'PTS code',
  common_name_pts_code_placeholder: 'Code',
  common_name_process_item: 'Item de proceso',
  common_name_process_item_placeholder: 'Item',

  kpi_only_numbers_warning: 'Aceptar solo números en valores objetivo',
  kpi_max_number_range_warning: 'Tu marca objetivo debe estar entre 1 y 9',
  kpi_already_selected_brand_warning:
    'Ha seleccionado esta marca, elija otra marca',
  kpi_no_kpi_informed_warning: 'No tienes ningún valor agregado',
  kpi_select_year_warning: 'Seleccione un año',
  kpi_select_site_warning: 'Seleccione un sitio',
  kpi_existing_configuration_warning: 'Tiene valores kpi del mismo sitio y año',
  kpi_target_empty_warning: 'Un objetivo de una de sus marcas está vacío',
  kpi_saved_successfully: '¡Tus valores se han guardado con éxito!',
  kpi_submitted_successfully: 'Tus valores han sido enviados con éxito!',
  kpi_max_decimals_warning: 'Su valor objetivo debe ser de hasta 3 decimales',
  kpi_count_as_kpi_sample: 'Contar como KPI',
  report_list_report_kpi: 'Informes KPI',
  report_list_report_taste: 'Informes Gusto',
  select_brand_to_filter: '¡Seleccione una marca mundial!',
  select_year_to_filter: '¡Seleccione un año!',
  samples_uncheck_kpi_sample_tooltip:
    'Desmarque esta casilla si no desea que esta muestra cuente en el KPI',
  reports_mandatory_zone_filter: 'Seleccione al menos una zona',
  submission_type: 'Tipo de compromiso',
  custom_scale_title: 'Escala personalizada',
  custom_scale_grid_header_score: 'Puntaje',
  custom_scale_grid_header_ageind_scale: 'Escala de Envejecimiento Forzado',
  the_value_cannot_be_null: 'El valor no puede estar vacío!',
  unique_id: 'Identifica de forma única',
  reports_mandatory_test_type: 'Seleccione el tipo de prueba.',
  report_filter_rounds_description:
    'Debe seleccionar los campos obligatorios para cargar opciones de rondas.',
  round_reports_month_warning:
    'El período entre la fecha de inicio y la fecha de finalización debe ser de un máximo de 6 meses.',
  zone_can_not_empty: 'La región no puede estar vacía',
  error_loading_data:
    'Error al cargar datos. Verifique su conexión y vuelva a cargar.',
  flavor_identification: 'Identificacion del Sabor ',
  beer_batch: 'Lote de cerveza',
  list_of_beers: 'Lista de cervezas',
  random: 'Aleatorio',
  preview: 'Vista Previa ',
  select_the_flavor: 'Selecciona el sabor',
  flavor_threshold: 'Threshold de sabor',
  input_digital_number: 'Ingrese número digital',
  concentration: 'concentración',
  flavor_order: 'Orden de sabor ',
  last: 'Ultimo',
  tasting: 'degustación',
  confirmation: 'confirmación',
  do_you_confirm_the_evaluation_of_this_sample:
    '¿Confirma la evaluación de esta muestra?',
  confirm: 'Confirmar',
  evaluate: 'Evaluar',
  correct_answer: 'Respuesta correcta',
  threshold: 'Límite',
  let_a_comment_about_the_tasting: 'Deja un comentario ,sobre la degustación ',
  send: 'Enviar',
  right_now: 'Inmediatamente',
  minute_ago: 'Hace un minuto',
  hour_ago: 'Hace una hora',
  day_before: 'Un día antes',
  year_ago: 'Hace un años',
  add_a_flavor: 'Añadir un sabor',
  soft_drinks: 'Bebidas sin alcohol',
  soft_drinks_brand: 'Marca objetivo de refresco',
  soft_drinks_brand_target: 'Marca objetivo de refrescos',
  column_options: 'Opciones de la columna',
  select_which_of_the_columns_do_you_want_to_be_displayed:
    'Seleccione cuál de las columnas desea que se muestre.',
  countdown: 'Cuenta atrás',
  less_than_five_minutes:
    'Esta sesión de degustación terminará en 5 minutos, si necesitas proceder, por favor contacta con el administrador para retrasar la fecha de finalización.',
  the_countdown_to_the_evaluation_has_ended:
    'La cuenta regresiva para comentarios ha finalizado. No se puede ingresar a los comentarios.',
  counting_how_much_time_does_you_have_to_finish_tasting_the_samples:
    'Calcula cuánto tiempo necesitas para terminar de probar las muestras',
  only_six_flavors_can_be_added_at_most:
    'Sólo se pueden añadir 6 sabores como máximo!',
  concentration_of_the_flavor: 'Concentración de Saville',
  input_value: 'Valor de entrada',
  Concentration_of_the_flavor_is_required:
    'Concentración necesaria de condimentos',
  previous_round: 'Ronda anterior',
  next_round: 'La siguiente ronda',
  the_end_time_is_greater_than_the_current_time:
    'La hora de finalización debe ser mayor que la hora actual, si selecciona el mismo día que el día actual, seleccione primero la hora y el minuto!',
  check_the_answer_at_the_end_of_the_countdown:
    'La cuenta regresiva no ha terminado. Puede ver las respuestas después de la cuenta regresiva',
  incremental_step: 'Paso incremental',
  set3_concentration: 'Establecer 3 concentraciones',
  concentration_increments_between_levels:
    'Incremento de concentración entre niveles',
  incremental_set3_concentration_required:
    'Concentración incremental requerida de set3',
  sprint: 'Sprint',
  sprint_number_greater_than_zero: 'El número de Sprint debe ser mayor que 0',
  sprint_notes_at_least_one_note:
    'Las notas de Sprint deben tener al menos una nota',
  sprint_patch_notes_success_update:
    'Notas del parche de Sprint actualizadas con éxito.',
  sprint_patch_notes_error_update: 'No fue posible guardar las notas.',
  update_sprint_patch_notes: 'Actualizar las notas del parche de Sprint',
  sprint_number: 'Número de carrera',
  sprint_patch_notes: 'Notas del parche de Sprint',
  note: 'Nota',
  concentration_increments_between_levels:
    'Incremento de concentración entre niveles',
  incremental_set3_concentration_required:
    'Concentración incremental requerida de set3',
  select_all: 'Selección completa',
  please_add_samples_first: 'Por favor agregue la muestra primero',
  concentration_increments_between_levels:
    'Incremento de concentración entre niveles',
  incremental_set3_concentration_required:
    'Concentración incremental requerida de set3',
  revser: 'Reversión',
  new_test: 'Pruebas añadidas',
  triangle_test_name: 'Nombre de la prueba de triangulación',
  triangle_test: 'Prueba de triangulación',
  you_can_not_set_three_identical_samples:
    'No puede establecer las mismas tres opciones',
  its_mandatory_to_input_test_name: 'Por favor, rellene el nombre de la prueba',
  its_mandatory_to_input_sample_information:
    'Por favor, rellene la información de la muestra',
  attention: 'pronto',
  test_number: 'Número de serie de la prueba de triangulación',
  you_selected_the: 'Tú eliges',
  next_sample: 'Siguiente ejemplo',
  remove_triangle_test: 'Eliminar esta prueba',
  you_can_only_select_up_to_five: 'Se permite un máximo de cinco selecciones',
  test: 'Prueba',
  confirm_remove: '¿Confirmar la eliminación de la prueba?',
  reports_mandatory_zone_country_site_filter:
    'Se requieren regiones, países, fábricas!',
  reports_mandatory_brand_filter: 'Seleccione al menos una marca',
  reports_by_producing_site: 'Informe de análisis completo',
  download_label: 'Botón Descargar',
  standard: 'Valor estándar',
  input_test: 'Entrada de resultados',
  detecting: 'Detectar...',
  connected: 'Conexo',
  current_taster: 'Revisores actuales',
  alcohol_concentration: 'Concentración de alcohol',
  your_alcohol_test_result_exceeds_standard_please_test_again_later:
    'Su concentración de alcohol en la sangre es excesiva. ¡Vuelva a realizar la prueba más tarde!',
  alcohol_test_qualified: 'Pasar la prueba de alcohol',
  configuration: 'Disposición',
  input_result: 'Entrada de resultados',
  maximum_alcohol_concentration: 'Concentración excesiva de alcohol',
  breathalyzer: 'Детектор алкоголю',
  please_input_a_positive_number_up_to_n_decimal_places:
    'Introduzca un número positivo de hasta {{field}} dígitos después del punto decimal',
  you_must_input: 'Debe ingresar',
  driving: 'Conducir',
  test_time: 'Tiempo de prueba',
  input_type: 'Tipo de entrada',
  list_breathalyzer: 'Analizador de respiración',
  Manual: 'Manual',
  Breathalyzer: 'Detector de alcohol',
  you_do_not_have_privilege_to_export_this_report:
    'No tiene derecho a exportar este informe',
  competitor_beer: 'Cerveza competidora',
  split_round: 'Círculo dividido',
  reset: 'Restablecer',
  how_many_rounds_splitting_do_you_want_to_make:
    'cuántas rondas quieres dividir',
  you_cannot_split_a_round_that_has_already_been_split_or_in_progress:
    'No se pueden dividir las rondas divididas o en curso',
  hide_sample_details: 'Ocultar detalles de la muestra',
  data_will_be_hidden_from_the_taster_brewery_production_date_brand_malt_variety_and_sample_id:
    'Los datos se ocultarán a los catadores: cervecería, fecha de producción, marca, variedad de Malta e Id de muestra',
  data_will_be_hidden_for_ok_nok:
    'Todos los datos relevantes se ocultarán a los catadores',
  you_can_not_add_duplicate_attributes:
    'No se pueden agregar atributos duplicados.',
  menu: 'Menú',
  link_breathalyzer: 'Vinculado',
  not_link_breathalyzer: 'No conectado',
  breathalyzer_to_link: 'Conectar el analizador de respiración',
  breathalyzer_can_not_to_link_for_this_browser:
    'La función del probador de alcohol fue desarrollada para usar dispositivos de computadora personal en Google chrome.',
  reopen: 'Reabrir',
  round_reopen_message: 'Are you sure you want to reopen the Round',
  common_production_line: 'Línea de producción',
  common_packaged_beer_data_code: 'Código de datos de cerveza de embalaje',
  common_masking_process_for_bottles:
    'Proceso de enmascaramiento de la botella',
  common_str_number_days_over_aged: 'Días por encima de la edad',
  common_over_aged_beer: 'Cerveza de más edad',
  sample_successfully_swapped: 'Muestra intercambiada con éxito.',
  scoring: 'Puntuación',
  configure_available_flavors: 'Configuración de los sabores disponibles',
  process_check: 'Inspección del proceso',
  new_process_check: 'Inspección del nuevo proceso',
  edit_process_check: 'Revisión del proceso de edición',
  remove_process_check: 'Inspección del proceso de eliminación',
  common_package: 'Paquete',
  common_department: 'Departamento',
  common_shift: 'Traslado',
  common_tank: 'Tanques',
  common_oknok: 'OKNOK',
  common_remark: 'Comentarios',
  show_all_process_check: 'Muestra todos los controles de proceso',
  show_all_process_check_help_circle:
    'Muestra los datos creados dentro de medio año a partir de hoy. Marque esta casilla si todavía necesita ver los datos creados dentro de un año a partir de hoy',
  the_sample_date_cannot_be_earlier_than_the_current_time:
    'La fecha de muestreo no puede ser anterior a la hora actual',
  common_job_position: 'Puesto de trabajo',
  decimal_sep: 'Separadores decimales',
  is_swapped_to: 'wird getauscht auf',
  review_warning_comment_title: '¡Tiene muestras no comentadas!',
  continuous_typing: 'Mecmecanocontinua continua',
  continuous_typing_tips:
    "Zählen Sie die Eingabe von 'Attribut' nach Auswahl/Eingabe von 'Intensität'.",
  BSSI_Reports: 'BSSI informes',
  data_analysis_sip: 'Análisis de datos - SIP',
  select_the_color: 'Seleccione el color',
  Color: 'Color Color',
  download_document: 'Descargar el documento',
  Month_of_Discontinued_Production: 'Meses fuera de producción',
  Suspension_List: 'Lista descontinuada',
  New_Suspension: 'Nueva aplicación descontinuada',
  new_suspension_page: 'Nueva información descontinuada',
  edit_suspension_page: 'Editar información descontinuada',
  Brand_name: 'Nombre de marca',
  List_Brewery_Production_Suspension: 'Lista de fábricas descontinuadas',
  Upload_successfully: 'Archivo subido con éxito',
  training_materials: 'Haga clic aquí para ver los materiales de capacitación.',
  Remove_Suspension: 'Retirar la suspensión: ',
  Add_Absence_Reason: 'Añadir el motivo de ausencia',
  Reason: 'razón',
  You_have_duplicate_absence_dates: 'Tiene fechas de ausencia duplic.',
  Remove_Absence: 'Eliminar ausencia: ',
  Absence_Title:
    'Por favor, seleccione la hora y la razón de su ausencia degust.',
  Shipping_Site: 'Sitio de envío',
  shipping_received: 'Envío recibido',
  receive_sample_alert: '¿Está seguro de haber recibido la muestra?',
  receive_uncheck_alert: '¿Estás seguro de que quieres desmarcarlo?',
  all_received: 'Todo recibido',
  not_all_received: 'No todos recibido',
  not_for_shipping: 'No para el envío',
  receive_hint: 'Seleccione todos los sitios que recibirán esta muestra',
  absence_reason: 'Ausencia motivo',
  Page1: 'Página {{field}}',
  Search: 'buscar',
  page_total: 'Total',
  start_date: 'Fecha de Inicio',
  end_date: 'Fecha de Finalización',
  ok_for_all: 'OK para todos',
  its_mandatory_to_select_sample_A_and_sample_B:
    'Es obligatorio seleccionar la muestra A y la muestra B.',
  its_mandatory_to_select_sample_A: 'Es obligatorio seleccionar la muestra A.',
  its_mandatory_to_select_sample_B: 'Es obligatorio seleccionar la muestra B.',
  triangle_comment: 'comentario',
  select_sample: 'Selección de la muestra',
  sample_date_filter_last_7_days_in_default_export_to_Excel_based_on_this_filtered_duration:
    'El filtro de fecha de muestra, predeterminado a los últimos 7 días, se exporta a Microsoft en función de la duración de este filtro.',
  select_all_flavor: 'Seleccionar todos',
  deselect_all: 'Eliminar la selección de todos',
  email_distribution: 'Distribución por correo electrónico',
  new_email_distribution: 'Nueva distribución de correo electrónico',
  edit_email_distribution: 'Editar la distribución por correo electrónico',
  distribution_type: 'Tipo de distribución',
  the_score_entered_is_invalid:
    'La puntuación introducida no es válida. Consulte la tabla de puntuación y vuelva a introducir su puntuación.',
  service_now:
    'Haga clic en para crear un ticket de incidente para reportar un problema y obtener ayuda.',
  service_now_tips:
    'Oye, si ves algún problema o quieres pedir apoyo, puedes hacer clic aquí e informar directamente en el servicio. ahora, nuestro equipo de apoyo se pondrá en contacto contigo lo antes posible.',
  done: 'Dorn',
  changes_you_made_may_not_be_saved:
    'Es posible que los cambios que ha hecho no se puedan guardar. ¿¿ quieres continuar y perder los cambios?',
  cannel_auto_save_tips:
    'Ahora que el rendimiento de la página de degustación ha mejorado, recuerde hacer clic en "completar la muestra" para guardar y enviar los resultados de su degustación.',
  list_email_page_tips:
    'Esta es la instrucción de cómo configurar la muestra correcta de KPI, con el fin de asegurarse de que el informe mensual de zona puede reflejar un análisis exacto para su zona MCS degust.',
  list_email_page_helps:
    'Instrucciones sobre cómo configurar un ejemplo de KPI para reflejar los datos precisos en el informe mensual regional.',
  kpi_instruction: 'Descripción del KPI',
  group_no_recipients_message: 'Se necesita al menos un destinatario.',
  the_current_distribution_type_already_exists:
    'El tipo de distribución actual ya existe, vuelva a seleccionar el tipo o regrese a la lista.',
  distribution_type_message_empty:
    'El tipo de distribución no permite estar vacío o vacío',
  add_samples: 'Añadir muestra(s) a la Ronda',
  copy_this_sample: 'Duplicar la muestra',
  remove_this_sample: 'Eliminar la muestra',
  multiple_selection_tips: `Puede seleccionar varias muestras a la vez para el mismo tipo de muestra. Haga clic en '+' y '-' para probarlo.`,
  all_add_once_tips: `Haga clic en el botón 'Seleccionar todo' para añadir automáticamente 1 muestra a cada tipo de muestra seleccionado.`,
  copy_confirm_tip: '¿Estás seguro de que quieres replicar la muestra?',
  user_non_abi_user: 'Nuevo usuario temporal',
  language_of_email_invitation:
    'Idioma de la invitación por correo electrónico',
  add_non_abi_users_to_round:
    'El usuario temporal recibirá una invitación por correo electrónico con un enlace para asistir a la cata en cuestión.',
  change_non_abi_users_to_round:
    'El catador temporal invitado recibirá una invitación por correo electrónico con un enlace para asistir a esta cata.',
  guest: 'Invitado',
  email_to_all: 'Enviar a todos',
  send_email: 'Enviar invitación por correo electrónico',
  temporary_non_abi_taster: 'Catador temporal',
  user_type: 'Tipo de usuario',
  email_invitation_delivery_request_sent:
    'Solicitud de invitación por correo electrónico enviada.',
  incorrect_e_mail_formatting: 'Formato incorrecto del correo electrónico',
  all_tasters: 'Todos los catadores',
  key_tasters: 'Catadores principales',
  non_key_tasters: 'Degustadores no principales',
  temporary: 'Usuario temporal',
  maximum_number_of_samples_is: 'El número máximo de muestras es',
  sample_added: 'Muestra añadida',
  click_to_view_scale_of_flavour_intensities:
    'Haga clic en el enlace para ver las escalas de intensidad detalladas',
  list_next_week_plan: 'Plan de la próxima semana',
  common_time_zone: 'Zona horaria',
  common_last_response_time: 'Última hora de respuesta',
  new_week_plan: 'Nuevo Plan Semanal',
  invited_week: 'Semana Invitada',
  email_language: 'Idioma del Email',
  save_and_send_email: 'Guardar y Enviar Email',
  common_start_time: 'Hora de inicio',
  common_end_time: 'Hora de finalización',
  session_name_email_subject:
    'Nombre de la sesión / Asunto del correo electrónico',
  location: 'Ubicación',
  edit_week_plan: 'Editar Plan Semanal',
  email_response: 'Email de respuesta',
  round_remove_plan: 'Está seguro de que desea eliminar el plan',
  view_week_plan: 'Ver Plan Semanal',
  are_you_confirming_to_send_the_confirmation_email:
    '¿Está confirmando el envío del correo electrónico de confirmación',
  every_10_minutes_system_will_update_the_email_response_from_next_week_plan_invitation:
    'Cada 10 minutos el sistema actualizará la respuesta por correo electrónico de la invitación al plan de la semana siguiente',
  documents_upload_tips: 'La carga de archivos sólo admite {{field}}',
  ignore_submit: 'Ignorar&Enviar',
  if_count_as_kpi:
    'Si cuenta como KPI, la muestra se guardará en el historial de muestra',
  confirmed_count: 'Recuento confirmado',
  accepted: 'Aceptado',
  declined: 'Rechazado',
  no_response: 'Sin respuesta',
  reminder_time: 'Hora del recordatorio',
  are_you_sure_you_want_to_change_the_week_of_invitation:
    '¿Está seguro de que desea eliminar la hora de inicio/fin de sesión que ha seleccionado y cambiar a otra semana invitada',
  apply_to_all_sessions_location_tasters: `Aplicar 'Ubicación' y 'Pruebas' a las siguientes sesiones`,
  round_samples_shuffle_confirm: '¿Seguro que quieres barajar los Sets?',
  all_count_as_kpi: 'Todos cuentan como KPI',
  msc_tasting_tour_mask:
    'El sistema identificará automáticamente el Código KPI relacionado basándose en la muestra contada con el Mes KPI correcto, por lo que NO tendrá que dividir diferentes marcas en una ronda.',
  competition: 'Concurso',
  copy_link: 'Copiar enlace',
  open_link: 'Abrir enlace',
  common_download_img: 'Descargar IMG',
  please_select_taster: 'Seleccione una degustación',
  user_principal_name: 'Benutzer Hauptverantwortlicher Name',
  user_status: 'Benutzer-Status',
  termination_date: 'Datum der Beendigung',
  function_level: 'Funktionsebene',
  last_update_time: 'Letzte Aktualisierungszeit',
  system_related: 'Systembezogen',
  workday_related: 'WorkDay-bezogen',
  employee_id: `Employee ID`,
};
