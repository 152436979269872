import React, { useState } from 'react';
import CustomColumnsStyle from './CustomColumns.module.scss';
import { useTranslation } from 'react-i18next';
import { Label, CardRow, CardColumn, Loading, Button } from '../';
import on from '../../assets/on.svg';
import off from '../../assets/off.svg';
import UserService from '../../modules/services/users/user.service';

export default function CustomColumns (props) {
  const { t } = useTranslation();
  const userService = new UserService();
  const [dataArr, setDataArr] = useState(props.values);
  const [loading, setLoading] = useState(false);

  const updatePermission = () => {
    let DOM = document.getElementsByName('headerSearch');
    setLoading(true);
    userService
      .SaveCustomColumns({ str_type: props.round, json_column_option: dataArr })
      .then(res => {
        if (res.type === 'success') {
          props.onChange(dataArr);
          props.setShow(false);
          setLoading(false);
          DOM.forEach(item => {
            if (item.value) {
              item.value = null;
            }
          });
          let sta = false;
          props.arrHeader.forEach(header => {
            if (localStorage.getItem(header.fieldCache)) {
              localStorage.removeItem(header.fieldCache);
              sta = true;
            }
          });
          sta && window.location.reload();
        }
      });
  };

  const switchState = (state, index) => {
    dataArr[index].bol_status = state === null || state === 1 ? 0 : 1;
    setDataArr([...dataArr]);
  };
  const offs = e => {
    if (e.target.className.indexOf('mask') > -1) {
      updatePermission();
      props.setShow(false);
      e.stopPropagation();
    }
  };

  return (
    <div className={CustomColumnsStyle.mask} onClick={offs}>
      <Loading
        loading={loading}
        style={{
          position: 'absolute',
          top: '5px',
          left: '50%',
          zIndex: 100,
          Transform: 'translateX(-50%)',
        }}
      />
      <div className={CustomColumnsStyle.item}>
        <Label
          label={props.title}
          bold
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '26px',
            marginBottom: '10px',
          }}
        />
        <Label
          label={props.explain}
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            marginBottom: '10px',
          }}
        />
        <CardRow
          style={{
            flexDirection: 'column',
            padding: '15px 0 0 0',
            height: '690px',
          }}
        >
          {dataArr.map((item, index) => {
            return (
              <CardColumn
                key={index}
                style={{ padding: '0', marginBottom: '12px' }}
              >
                <Button
                  label={t(item.str_name)}
                  onClick={() => switchState(item.bol_status, index)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 6px',
                    width: '100%',
                    textAlign: 'left',
                    height: '30px',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    background:
                      item.bol_status === null || item.bol_status === 1
                        ? '#FFFFFF'
                        : 'rgba(75, 101, 236, 0.15)',
                    border: '1px solid #EFEFEF',
                    borderRadius: '4px',
                    color: '#000',
                  }}
                >
                  <img
                    src={
                      item.bol_status === null || item.bol_status === 1
                        ? on
                        : off
                    }
                    style={{ marginRight: '10px' }}
                  />
                </Button>
              </CardColumn>
            );
          })}
        </CardRow>
        {/* <CardRow>
          <CardColumn style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              label={t('common_save')}
              onClick={updatePermission}
              style={{
                width: '73px',
                height: '44px',
                background: '#FF9700',
                borderRadius: '4px',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '16px',
                color: '#fff',
              }}
            ></Button>
            <Button
              label={t('common_cancel')}
              onClick={() => props.setShow(false)}
              style={{
                width: '73px',
                height: '44px',
                marginLeft: '12px',
                background: '#A4A6AC',
                borderRadius: '4px',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '16px',
                color: '#fff',
              }}
            ></Button>
          </CardColumn>
        </CardRow> */}
      </div>
    </div>
  );
}
