
import { useTranslation } from 'react-i18next';
import Style from './ListNextWeekPlan.module.scss';
import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

const UserConfirm = (props) => {
  const { t } = useTranslation();
  const [confirmTasters, setConfirmTasters] = useState([]);

  useEffect(() => {
    countConfirmUser(props.changeRowConfirm);
  }, [props.changeRowConfirm])


  const countConfirmUser = (arr) => {
    let allUsers = [];
    arr.forEach(session => {
      allUsers = [...allUsers, ...session.arr_users]
    })
    // const confirmArr = arr.filter(session => session.dat_send_confirm);
    const uniqueArr = allUsers.reduce((acc, current) => {
      if (!acc.find(item => item.uuid_user === current.uuid_user)) {
        current.confirm_num = countConfirmNum(arr, current.uuid_user)
        acc.push(current);
      }
      return acc;
    }, []);
    setConfirmTasters(uniqueArr);
  }
  const countConfirmNum = (confirmArr, uuid_user) => {
    let users = [];
    confirmArr.forEach(session => {
      users = [...users, ...session.arr_users]
    })
    const sum = users.filter(item => item.uuid_user === uuid_user && item.bol_confirm).length
    return sum;
  }

  const renderConfirmTaster = (taster) => {
    return <div className={Style.confirmUser}
      style={{
        background: taster.confirm_num === 0 ?
          '#e7e7e8' :
          taster.confirm_num === 1 ?
            '#ff9700' :
            taster.confirm_num === 2 ?
              '#3273f5' :
              '#87c700',
        color: taster.confirm_num === 0 ? '#000' : '#fff'
      }}
    >
      <p
        className={Style.userName}
        data-tip={taster.str_name}
      >
        {taster.str_name}
      </p>
      <ReactTooltip
        effect="solid"
        place="top"
        type="warning"
        className={Style.tooltip}
      />
      <p className={Style.upUserConfirm}>
        {taster.confirm_num}
      </p>
    </div>
  }

  return <div className={Style.editUsersContentConfirm}>
    <p className={Style.tipsTitle}>
      {t('confirmed_count')}:
      <span>
        <i className={Style.tags}></i>
        0 time
      </span>
      <span>
        <i
          className={Style.tags}
          style={{
            background: '#ff9700'
          }}
        ></i>
        1 time
      </span>
      <span>
        <i
          className={Style.tags}
          style={{
            background: '#3273f5'
          }}
        ></i>
        2 times
      </span>
      <span>
        <i className={Style.tags}
          style={{
            background: '#87c700'
          }}></i>
        3+ times
      </span>
    </p>
    {
      confirmTasters.filter(item => item.bol_key_taster).length > 0 ?
        <>
          <b className={Style.title}>{t('key_tasters')}</b>
          <div className={Style.confirmUserBox}>
            {
              confirmTasters.filter(item => item.bol_key_taster).map(taster =>
                renderConfirmTaster(taster)
              )
            }
          </div>
        </> : null
    }
    {
      confirmTasters.filter(item => !item.bol_key_taster).length > 0 ?
        <>
          <b className={Style.title}>{t('non_key_tasters')}</b>
          <div className={Style.confirmUserBox}>
            {
              confirmTasters.filter(item => !item.bol_key_taster).map(taster =>
                renderConfirmTaster(taster)
              )
            }
          </div>
        </> : null
    }
  </div>
}

export default UserConfirm;