import React, { useState, useEffect, useRef, Fragment } from 'react';
import listDiscussionOkNokStyle from './listDiscussionOkNok.module.scss';
import listDiscussion from '../listDiscussion/listDiscussion.module.scss';
import {
  CardRow,
  Text,
  CardColumn,
  Button,
  Loading,
  ButtonDownload,
  Modal,
  ImageZoom,
  PdfViewerModal,
  RoundUpAndDown,
  NotificationBar,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { useTasting } from '../../../contexts/Tasting';
import { Key, ArrowLeft, X, Eye } from 'react-feather';
import { useHistory } from 'react-router-dom';
import configs from '../../../configs';
import ReportService from '../../services/reports/report.service';
import RoundAppService from '../../services/rounds/roundApp.service';
import _ from 'lodash';
import { useUser } from '../../../contexts/User';
import ReactTooltip from 'react-tooltip';
import Moment from 'moment';
import AppService from '../../../app.service';
import SampleAppService from '../../services/samples/sampleApp.service';
import edit from '../../../assets/edit.svg';
import editUnlock from '../../../assets/edit_unlock.svg';
import editBlock from '../../../assets/edit_block.svg';
import { GetSocket } from '../../../utils/socketIo';

const ListDiscussionOkNok = ({ match, location }) => {
  const history = useHistory();
  const elementRef = useRef(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [tasters, setTasters] = useState([]);
  const [templateTasters, setTemplateTasters] = useState([])
  const [optionsFilter, setFilters] = useState([]);
  const [order, setOrder] = useState(0);
  const reportService = new ReportService();
  const appService = new AppService();
  const sampleAppService = new SampleAppService();
  const { taste, loadTasteDiscussion } = useTasting();
  const { user, handleUser } = useUser();
  const [showModalDownload, setShowModalDownload] = useState(false);
  const [showModalDownloadSample, setShowModalDownloadSample] = useState(null);
  const [open, setOpenPdf] = useState(false);
  const [tasteValues, setTasteValues] = useState();
  const [privilege, setPrivilege] = useState([]);
  const roundServiceApp = new RoundAppService();
  const bolScore = location.state.bol_score;
  const [notificationArr, setNotificationArr] = useState([]);
  const bubbleArr = useRef([]);
  const nottificationRef = useRef({});
  const optionsByTastersFilter = [
    {
      value: 0,
      label: t('all_tasters'),
    },
    {
      value: 1,
      label: t('key_tasters'),
    },
    {
      value: 2,
      label: t('non_key_tasters'),
    },
  ]

  useEffect(() => {
    bubbleArr.current = notificationArr;
  }, [notificationArr]);

  useEffect(() => {
    let timer = setTimeout(() => {
      GetSocket().on('autoUpdateDiscussion', function (res) {
        if (window.location.pathname === '/tasting_discussion_ok_nok') {
          const uuid_round =
            location.state?.uuid_round !== undefined
              ? location.state.uuid_round
              : location.state.round.uuid_round;
          const resUuidRound = res.uuid_round ? res.uuid_round : '';
          if (
            resUuidRound === uuid_round &&
            res.user_name &&
            res.user_name !== user.str_name
          ) {
            bubbleArr.current.push({
              oldName: res.user_name ? res.user_name : '',
              title: 'common_finish_session',
            });
            fetchDataFromContext();
            setNotificationArr([...bubbleArr.current]);
            if (
              nottificationRef?.current &&
              bubbleArr.current &&
              bubbleArr.current.length > 4
            ) {
              nottificationRef.current?.scrollBottom &&
                nottificationRef.current.scrollBottom();
            }
          }
        }
      });
      GetSocket().on('addUpdateSample', async function (res) {
        if (window.location.pathname === '/tasting_discussion_ok_nok') {
          const resUuidRound = res.uuid_round ? res.uuid_round : '';
          if (resUuidRound === location.state.round.uuid_round) {
            let sampleNameStr = '';
            for (let i = 0; i < res.updateSampleArr.length; i++) {
              const lastDom = i + 1 !== res.updateSampleArr.length ? ', ' : '';
              sampleNameStr +=
                res.updateSampleArr[i].str_name_category_sample + lastDom;
            }
            if (!!sampleNameStr) {
              fetchDataFromContext();
            }

            if (
              nottificationRef?.current &&
              bubbleArr.current &&
              bubbleArr.current.length > 4
            ) {
              nottificationRef.current?.scrollBottom &&
                nottificationRef.current.scrollBottom();
            }
          }
        }
      });
    });
    return () => {
      clearTimeout(timer);
    }
  }, []);

  const fetchDataFromContext = async () => {
    if (location.state && !_.isEmpty(location.state.uuid_round)) {
      user.currentMenu = 'menu_tasting_session';
      user.currentTab = 'menu_tasting_session';
      handleUser(user);
      const loadTaste = await loadTasteDiscussion({
        uuid_round: location.state.uuid_round,
        uuid_round_type: location.state.uuid_round_type,
      });

      if (loadTaste.type === 'success') {
        const tasteAux =
          loadTaste.results.length > 1
            ? loadTaste.results[0]
            : loadTaste.results;

        fetchRows(tasteAux);
        setLoading(false);
        setTasteValues(tasteAux);
      }
    } else {
      setTasteValues(taste);
      loadTasteDiscussion(taste).then(res => {
        fetchRows(res.results);
        setLoading(false);
      });
    }
  };

  const fetchRows = async tasteTemp => {
    let roundTemp = tasteTemp;
    let tastersTemp = [];
    let filterTemp = [{ label: t('common_show_all'), value: 0 }];
    const res = await roundServiceApp.listScoresDiscussion(
      roundTemp?.uuid_round,
    );
    const sampleNowArr = res.results;
    const categoriesArr = getCategories(roundTemp).map(categorie => {
      const categorieSamples = categorie.samples.map(sample => {
        let sampleObj = sampleNowArr.find(item => {
          return sample.uuid_sample === item.uuid_sample;
        });
        return { ...sample, ...sampleObj };
      });
      return {
        ...categorie,
        samples: categorieSamples,
      };
    });
    setRows(categoriesArr);
    getCategories(roundTemp).map(category => {
      if (!filterTemp.find(filter => filter.value === category.uuid_category)) {
        filterTemp.push({
          label: category.str_name,
          value: category.uuid_category,
        });
      }

      category.samples.map(sample => {
        sample.tasters.map(taster => {
          if (
            !tastersTemp?.find(
              tasterTemp => tasterTemp.uuid_user === taster.uuid_user,
            )
          ) {
            tastersTemp.push(taster);
          }
        });
      });
    });
    let tastersTempNew = [];
    roundTemp.tasters?.forEach(item => {
      const tasterObj = tastersTemp?.find(
        taster => item.uuid_user === taster.uuid_user,
      );
      if (tasterObj) {
        tastersTempNew.push({ ...item, ...tasterObj });
      }
    });
    setFilters(filterTemp);
    setTasters(tastersTempNew);
    setTemplateTasters(tastersTempNew);
  };

  useEffect(() => {
    setLoading(true);
    fetchDataFromContext();

    appService.listPrivileges('SAMPLE_DISCUSSION_EDIT').then(
      res => {
        if (res.type === 'success' && res.results) {
          setPrivilege(res.results[0]);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
    return () => {
      sessionStorage.removeItem('roundjump');
    };
  }, []);

  const onScroll = e => {
    elementRef.current.scrollLeft = e.target.scrollLeft;
  };

  const handleChange = value => {
    setOrder(value.target.value);
  };

  const handleDownload = () => {
    setLoading(true);
    reportService
      .generateOkNok({
        arr_rounds: [taste.uuid_round],
        bol_closed: true,
      })
      .then(
        res => {
          if (
            res.status === 'success' &&
            res.file &&
            res.file === 'report.xlsx'
          ) {
            setLoading(false);
            window.alert(t('common_report_not_have_data'));
          } else if (
            res.status === 'success' &&
            res.file &&
            res.file !== 'report.xlsx'
          ) {
            setLoading(false);
            let a = document.createElement('a');
            a.href = configs.file_api_url + '/v1/' + res.file;
            a.download = res.file;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            setLoading(false);
            window.alert(res.message);
          }
        },
        error => {
          setLoading(false);
          alert(t('you_do_not_have_privilege_to_export_this_report'));
        },
      );
  };

  const handleFilter = () => {
    let rowsTemp = rows;
    return rowsTemp?.filter(row => {
      if (order == 0) {
        return true;
      } else {
        return order === row.uuid_category;
      }
    });
    // return rowsTemp
  };

  const handleFormatBrewNumbers = brewNumbers => {
    if (!brewNumbers) {
      return '';
    }

    let formatedBrewNumbers = '';
    brewNumbers.map((b, i) => {
      formatedBrewNumbers +=
        i < brewNumbers.length - 1
          ? b.str_descripition + ', '
          : b.str_descripition;
    });
    return formatedBrewNumbers;
  };

  const handleFormatDate = date => {
    return date ? Moment(date).utc().format('DD MMM YYYY') : '';
  };

  const handleBack = () => {
    if (sessionStorage.getItem('roundjump')) {
      history.goBack();
    } else {
      history.replace({ pathname: '/' });
    }
  };

  const handleInteractionLog = () => {
    let a = document.createElement('a');
    a.href = `https://interactionlog.ab-inbev.com/`;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleShowDownload = show => {
    setShowModalDownload(!show);
  };

  const handleShowDownloadSample = (show, i) => {
    if (show === null) {
      setShowModalDownloadSample(i);
    } else {
      setShowModalDownloadSample(null);
    }
  };

  const handleClosePdf = () => {
    setOpenPdf(!open);
  };

  const handlePDF = () => {
    setOpenPdf(!open);
  };

  const displayImage = (sample, index) => {
    if (sample.str_zip_document.split('.').pop() !== 'pdf') {
      return (
        <>
          <Modal show={open === index}>
            <ImageZoom
              showClose={true}
              srcImage={sample.str_zip_document}
              onClick={handleClosePdf}
            />
          </Modal>
          <Button
            label={''}
            onClick={() => setOpenPdf(index)}
            style={{
              borderRadius: '40px',
              backgroundColor: '#F49B00',
              color: '#fff',
              fontWeight: 'bold',
              width: '40px',
              height: '40px',
              padding: '4px 0px 0px 0px',
            }}
          >
            <Eye />
          </Button>
        </>
      );
    } else {
      return (
        <>
          <PdfViewerModal
            label={`${t('common_brand')} PDF`}
            data={sample.str_zip_document}
            closeForm={handleClosePdf}
            open={open === index}
            loading={false}
            downloadFileName={sample.str_zip_document}
            name={sample.str_zip_document}
          />
          <Button
            label={''}
            onClick={() => setOpenPdf(index)}
            style={{
              borderRadius: '40px',
              backgroundColor: '#F49B00',
              color: '#fff',
              fontWeight: 'bold',
              width: '40px',
              height: '40px',
              padding: '4px 0px 0px 0px',
            }}
          >
            <Eye />
          </Button>
        </>
      );
    }
  };

  const handleDiscussionDetail = (sample, evt) => {
    if (!evt || !evt.target || evt.target.tagName !== 'BUTTON') {
      history.push({
        pathname: '/detail_discussion',
        state: {
          ...sample,
        },
      });
    }
  };

  const handleChangeByTasters = e => {
    const value = e.target.value;
    let nowTasters = templateTasters
    if (value == 1) {
      nowTasters = templateTasters.filter(taster => taster.bol_key_taster);
    } else if (value == 2) {
      nowTasters = templateTasters.filter(taster => !taster.bol_key_taster);
    }
    setTasters([...nowTasters])
  };

  const handleEdit = (sample, uuid_user) => {
    sessionStorage.setItem('editOknok', 1);
    history.push({
      pathname: `/tasting_ok_nok`,
      state: {
        uuid_user: uuid_user,
        sample: sample,
        edit: 'edit',
        round: location.state.round,
        bol_score: bolScore,
      },
    });
  };

  const allowOrBlockUserToEditAnswer = (
    uuid_round,
    uuid_sample,
    bol_user_can_edit,
    uuid_user,
  ) => {
    const score = {
      uuid_round,
      uuid_sample,
      bol_user_can_edit,
      uuid_user,
    };
    sampleAppService
      .editoknokEditingPermission(score)
      .then(() => {
        const newData = rows.map(row => {
          row.samples.find(sample => {
            if (sample.uuid_sample !== uuid_sample) {
              return false;
            }

            const sampleIsTastedByUser = sample.tasters.find(taster => {
              if (
                taster.uuid_user !== score.uuid_user ||
                taster.uuid_sample !== score.uuid_sample
              ) {
                return false;
              }

              taster.bol_user_can_edit = score.bol_user_can_edit;
              return true;
            });

            if (!sampleIsTastedByUser) {
              sample.tasters.push({
                bol_user_can_edit: bol_user_can_edit,
                uuid_round: uuid_round,
                uuid_sample: uuid_sample,
                uuid_user: uuid_user,
              });
            }

            return true;
          });

          return row;
        });
        setRows(newData);
      })
      .catch(err => {
        alert(err);
      });
  };
  const getCategories = (row) => {
    if (row.categoriesSplit && row.categoriesSplit.length > 0) {
      return row.categoriesSplit
    } else {
      return row.categories ?? []
    }
  }

  const renderEditButton = (tasterCommentCheck, row, taster) => {
    if (
      privilege?.arr_sites?.some(
        x =>
          x.uuid_site === getCategories(tasteValues)[0].samples[0].uuid_round_site,
      )
    ) {
      return user.uuid_user === tasterCommentCheck?.uuid_user &&
        tasterCommentCheck?.uuid_round === tasteValues?.uuid_round ? (
        <div>
          <img
            src={edit}
            alt={'edit'}
            className={listDiscussion.penIcon}
            height={24}
            width={24}
            onClick={e => {
              handleEdit(row, user.uuid_user);
              e.stopPropagation();
            }}
          />
        </div>
      ) : tasterCommentCheck?.bol_user_can_edit ? (
        <img
          src={editUnlock}
          alt={'unlocked'}
          className={listDiscussion.penIcon}
          height={24}
          width={24}
          onClick={e => {
            allowOrBlockUserToEditAnswer(
              tasteValues.uuid_round,
              row.uuid_sample,
              tasterCommentCheck?.bol_user_can_edit ? 0 : 1,
              tasterCommentCheck?.uuid_user,
            );
            e.stopPropagation();
          }}
        />
      ) : (
        <img
          src={editBlock}
          alt={'locked'}
          className={listDiscussion.penIcon}
          height={24}
          width={24}
          onClick={e => {
            allowOrBlockUserToEditAnswer(
              tasteValues.uuid_round,
              row.uuid_sample,
              tasterCommentCheck?.bol_user_can_edit ? 0 : 1,
              tasterCommentCheck?.uuid_user,
            );
            e.stopPropagation();
          }}
        />
      );
    } else {
      return user.uuid_user === tasterCommentCheck?.uuid_user &&
        tasterCommentCheck?.bol_user_can_edit ? (
        <img
          src={edit}
          alt={'edit'}
          className={listDiscussion.penIcon}
          height={24}
          width={24}
          onClick={e => {
            handleEdit(row, taster.uuid_user);
            e.stopPropagation();
          }}
        />
      ) : (
        <Fragment />
      );
    }
  };

  return (
    <Fragment>
      {loading ? (
        <CardRow transparent justifyContent="center">
          <Loading loading={loading} />
        </CardRow>
      ) : (
        <Fragment>
          <CardRow transparent justifyContent="center">
            <CardColumn
              className={listDiscussionOkNokStyle.header}
              style={{ justifyContent: 'flex-start' }}
            >
              <Button
                onClick={() => handleBack()}
                style={{
                  borderRadius: '40px',
                  backgroundColor: '#F49B00',
                  color: '#fff',
                  fontWeight: 'bold',
                  width: '40px',
                  height: '40px',
                  padding: '4px 0px 0px 0px',
                }}
              >
                <ArrowLeft />
              </Button>
              {!user.nonABI && <RoundUpAndDown round={location.state.round ?? location.state} />}
            </CardColumn>
            <CardColumn
              className={listDiscussionOkNokStyle.header}
              style={{ justifyContent: 'flex-end' }}
            >
              {taste?.str_zip_documents ? (
                <Button
                  label={t('common_download_files')}
                  onClick={() => handleShowDownload(showModalDownload)}
                  style={{
                    borderRadius: '5px',
                    backgroundColor: '#F49B00',
                    color: '#fff',
                    fontWeight: 'bold',
                    marginRight: '5px',
                  }}
                />
              ) : null}
              <Modal show={showModalDownload}>
                <React.Fragment>
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      padding: '10px 15px',
                      justifyContent: 'flex-end',
                      display: 'flex',
                    }}
                  >
                    <button
                      style={{
                        cursor: 'pointer',
                        height: '35px',
                        width: '35px',
                        color: '#fff',
                        border: '1px solid',
                        borderRadius: '4px',
                        fontSize: '18px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#666666',
                      }}
                      onClick={() => handleShowDownload(showModalDownload)}
                    >
                      <X />
                    </button>
                  </div>
                  <CardRow>
                    <div
                      style={{
                        position: 'relative',
                        width: '100%',
                        padding: '10px 15px',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      <h1>{`${t('common_download_files')} Round`}</h1>
                    </div>
                  </CardRow>
                  <CardColumn>
                    {taste?.str_zip_documents
                      ? taste?.str_zip_documents.map((doc, i) => (
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          <div
                            style={{
                              margin: '8px 8px',
                            }}
                          >
                            {displayImage(doc, i)}
                          </div>
                          <ButtonDownload
                            name="str_zip_documents"
                            src={doc.str_zip_document}
                            margin="5px 5px"
                            size={40}
                            width="60px"
                          />
                          <span
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              marginRight: '10px',
                            }}
                          >
                            {doc.str_zip_document}
                          </span>
                        </div>
                      ))
                      : null}
                  </CardColumn>
                </React.Fragment>
              </Modal>

              <Button
                label={t('interaction_log')}
                onClick={() => handleInteractionLog()}
                style={{
                  borderRadius: '5px',
                  backgroundColor: '#F49B00',
                  color: '#fff',
                  fontWeight: 'bold',
                  marginRight: '5px',
                }}
              />
              <Button
                label={t('round_report')}
                onClick={() => handleDownload()}
                style={{
                  borderRadius: '5px',
                  backgroundColor: '#F49B00',
                  color: '#fff',
                  fontWeight: 'bold',
                }}
              />
              <Button
                label={t('group_close_modal')}
                onClick={() => history.replace('/')}
                style={{
                  color: '#fff',
                  background: '#05A463',
                  borderRadius: '5px',
                  fontWeight: 'bold',
                  margin: '5px',
                }}
              ></Button>
            </CardColumn>
          </CardRow>
          <CardRow transparent>
            <h1>{`${taste.int_round} ${taste.str_name_round !== null &&
              taste.str_name_round !== undefined
              ? t('common_name') + ':' + taste.str_name_round
              : ''
              }`}</h1>
          </CardRow>
          <div
            ref={elementRef}
            className={listDiscussionOkNokStyle.containerFixed}
          >
            <CardRow transparent padding="0" itemAlign="flex-end">
              <div
                className={listDiscussionOkNokStyle.filterBox}
                style={{ height: bolScore ? '114px' : '' }}
              >
                <p
                  className={listDiscussionOkNokStyle.tasters}
                >
                  Tasters: {
                    (
                      tasteValues?.tasters &&
                      tasteValues.tasters?.length > 0 &&
                      tasters &&
                      tasters.length > 0
                    ) &&
                    <span> {tasters ? tasters.length : 0} / {tasteValues?.tasters ? tasteValues.tasters?.length : 0}</span>
                  }
                </p>
                <CardColumn
                  padding="0"
                  margin='5px'
                  className={listDiscussionOkNokStyle.cardFilter}
                >
                  <select className={listDiscussionOkNokStyle.filter} onChange={handleChangeByTasters}>
                    {optionsByTastersFilter?.map((option, i) => (
                      <option key={option.label + i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </CardColumn>
                <CardColumn
                  padding="0"
                  margin='5px'
                  className={listDiscussionOkNokStyle.cardFilter}
                >

                  <select
                    className={listDiscussionOkNokStyle.filter}
                    onChange={handleChange}
                  >
                    {optionsFilter?.map((option, i) => {
                      return (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </CardColumn>
              </div>
              {_.sortBy(tasters, 'str_name').map((taster, i) => {
                let tasterVotes = [];
                rows.forEach(row => {
                  row.samples.forEach(item => {
                    const votes = item.tasters.find(
                      x => x.uuid_user === taster.uuid_user,
                    );
                    tasterVotes.push(votes ? votes.flt_score : 0);
                  });
                });
                const minVote =
                  tasterVotes.length > 0
                    ? tasterVotes?.reduce((prev, curr) => {
                      return prev < curr ? prev : curr;
                    })
                    : 0;
                const maxVote =
                  tasterVotes.length > 0
                    ? tasterVotes?.reduce((prev, curr) => {
                      return prev > curr ? prev : curr;
                    })
                    : 0;
                const sum =
                  tasterVotes.length > 0
                    ? tasterVotes?.reduce((a, b) => a + b, 0)
                    : 0;
                const average = sum / tasterVotes.length || 0;
                return (
                  <>
                    {bolScore ? (
                      <>
                        <CardColumn
                          className={listDiscussion.cardNameTasterParent}
                        >
                          <CardColumn
                            key={i}
                            className={
                              listDiscussionOkNokStyle.cardNameTasterScore
                            }
                          >
                            <p
                              className={
                                taster.bol_key_taster
                                  ? listDiscussionOkNokStyle.cardNameTasterText
                                  : listDiscussionOkNokStyle.cardNameTasterTextW100
                              }
                              data-tip={taster.str_name}
                              data-for="tasterName"
                            >
                              {taster.str_name}
                            </p>
                            <ReactTooltip
                              id="tasterName"
                              effect="solid"
                              place="top"
                              type="warning"
                              className={listDiscussionOkNokStyle.tooltip}
                            />
                            {taster.bol_key_taster ? (
                              <Key
                                className={listDiscussionOkNokStyle.keyIcon}
                              />
                            ) : (
                              <Fragment />
                            )}
                          </CardColumn>
                          <CardColumn
                            style={{
                              backgroundColor: '#FFFFFF',
                              display: 'flex',
                              flexDirection: 'column',
                              color: 'black',
                              padding: '5px',
                            }}
                          >
                            <div className={listDiscussion.simpleAlign}>
                              <Text
                                bold={true}
                                className={listDiscussion.textTitle}
                              >
                                {t('common_minimum_score')}:
                              </Text>
                              <Text className={listDiscussion.textSubTitleT}>
                                &nbsp;
                                {minVote?.toFixed(2)
                                  ? minVote?.toFixed(2)
                                  : '0.00'}
                              </Text>
                            </div>
                            <div className={listDiscussion.simpleAlign}>
                              <Text
                                bold={true}
                                className={listDiscussion.textTitle}
                              >
                                {t('common_maximum_score')}:
                              </Text>
                              <Text className={listDiscussion.textSubTitleT}>
                                &nbsp;
                                {maxVote?.toFixed(2)
                                  ? maxVote?.toFixed(2)
                                  : '0.00'}
                              </Text>
                            </div>
                            <div className={listDiscussion.simpleAlign}>
                              <Text
                                bold={true}
                                className={listDiscussion.textTitle}
                              >
                                {t('common_average_score')}:
                              </Text>
                              <Text className={listDiscussion.textSubTitleT}>
                                &nbsp;
                                {average?.toFixed(2)
                                  ? average?.toFixed(2)
                                  : '0.00'}
                              </Text>
                            </div>
                          </CardColumn>
                        </CardColumn>
                      </>
                    ) : (
                      <CardColumn
                        key={i}
                        className={listDiscussionOkNokStyle.cardNameTaster}
                      >
                        <p
                          className={
                            taster.bol_key_taster
                              ? listDiscussionOkNokStyle.cardNameTasterText
                              : listDiscussionOkNokStyle.cardNameTasterTextW100
                          }
                          data-tip={taster.str_name}
                          data-for="tasterName"
                        >
                          {taster.str_name}
                        </p>
                        <ReactTooltip
                          id="tasterName"
                          effect="solid"
                          place="top"
                          type="warning"
                          className={listDiscussionOkNokStyle.tooltip}
                        />
                        {taster.bol_key_taster ? (
                          <Key className={listDiscussionOkNokStyle.keyIcon} />
                        ) : (
                          <Fragment />
                        )}
                      </CardColumn>
                    )}
                  </>
                );
              })}
            </CardRow>
          </div>

          <CardRow
            onScroll={onScroll}
            transparent
            padding="0"
            className={listDiscussionOkNokStyle.container}
          >
            {handleFilter().map((row, j) => {
              return row.samples.map(sample => {
                return (
                  <Fragment key={row.uuid_category + sample.uuid_sample}>
                    <CardRow key={j} transparent padding="0">
                      <CardColumn
                        padding="0px"
                        className={listDiscussionOkNokStyle.cardInfo}
                        onClick={evt => handleDiscussionDetail(sample, evt)}
                      >
                        <CardRow justifyContent="center">
                          <CardColumn>
                            <Text bold={true} className={listDiscussionOkNokStyle.textTitle}>
                              {t('common_position')}
                            </Text>
                            <Text className={listDiscussionOkNokStyle.textSubTitle}>
                              {console.log(row)}
                              {row.str_split_round_order ? (<b>{row.str_split_round_order} - {sample.int_order_app + 1} </b>) : sample.int_order_app + 1}
                            </Text>
                          </CardColumn>
                          <CardColumn>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textTitle}
                            >
                              {t('common_sample')}
                            </Text>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textSubTitle}
                            >
                              {sample.str_name_category_sample}
                            </Text>
                          </CardColumn>
                        </CardRow>

                        <CardRow justifyContent="center">
                          <CardColumn>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textTitle}
                            >
                              {t('common_brand')}
                            </Text>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textSubTitle}
                            >
                              {sample.str_name_brand || 'N/A'}
                            </Text>
                          </CardColumn>
                          <CardColumn>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textTitle}
                            >
                              {t('common_category_sample')}
                            </Text>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textSubTitle}
                            >
                              {sample.str_name_category}
                            </Text>
                          </CardColumn>
                        </CardRow>

                        <CardRow justifyContent="center">
                          <CardColumn>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textTitle}
                            >
                              {t('common_sample_id')}
                            </Text>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textSubTitle}
                            >
                              {sample.str_sample_id || 'N/A'}
                            </Text>
                          </CardColumn>
                          <CardColumn>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textTitle}
                            >
                              {t('common_percent_ok')}
                            </Text>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textSubTitle}
                            >
                              {Number(sample.pct_ok * 100).toFixed(2)}%
                            </Text>
                          </CardColumn>
                        </CardRow>
                        {bolScore ? (
                          <CardRow justifyContent="center">
                            <CardColumn>
                              <Text
                                bold={true}
                                className={listDiscussionOkNokStyle.textTitle}
                              >
                                {t('common_key_score')}
                              </Text>
                              <Text
                                bold={true}
                                className={
                                  listDiscussionOkNokStyle.textSubTitle
                                }
                              >
                                {sample.flt_average_score_key
                                  ? sample.flt_average_score_key.toFixed(2)
                                  : 'N/A'}
                              </Text>
                            </CardColumn>
                            <CardColumn>
                              <Text
                                bold={true}
                                className={listDiscussionOkNokStyle.textTitle}
                              >
                                {t('common_score')}
                              </Text>
                              <p
                                data-for="brewNumber"
                                bold={true}
                                className={
                                  listDiscussionOkNokStyle.textSubTitleLimited
                                }
                              >
                                {sample.flt_average_score
                                  ? sample.flt_average_score.toFixed(2)
                                  : 'N/A'}
                              </p>
                            </CardColumn>
                          </CardRow>
                        ) : (
                          ''
                        )}

                        <CardRow justifyContent="center">
                          <CardColumn>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textTitle}
                            >
                              {t('common_brew_number')}
                            </Text>
                            <p
                              data-for="brewNumber"
                              bold={true}
                              className={
                                listDiscussionOkNokStyle.textSubTitleLimited
                              }
                              data-tip={handleFormatBrewNumbers(
                                sample.arr_brew_numbers,
                              )}
                            >
                              {handleFormatBrewNumbers(
                                sample.arr_brew_numbers,
                              ) || 'N/A'}
                            </p>
                            <ReactTooltip
                              id="brewNumber"
                              effect="solid"
                              place="bottom"
                              type="warning"
                              className={listDiscussionOkNokStyle.tooltip}
                            />
                          </CardColumn>
                          <CardColumn>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textTitle}
                            >
                              {t('common_collection_point')}
                            </Text>
                            <p
                              data-for="collectionPoint"
                              bold={true}
                              className={
                                listDiscussionOkNokStyle.textSubTitleLimited
                              }
                              data-tip={sample.str_collection_point}
                            >
                              {sample.str_collection_point || 'N/A'}
                            </p>
                            <ReactTooltip
                              id="collectionPoint"
                              effect="solid"
                              place="bottom"
                              type="warning"
                              className={listDiscussionOkNokStyle.tooltip}
                            />
                          </CardColumn>
                        </CardRow>

                        <CardRow justifyContent="center">
                          <CardColumn>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textTitle}
                            >
                              {t('common_collection_date')}
                            </Text>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textSubTitle}
                            >
                              {handleFormatDate(sample.dt_collection) || 'N/A'}
                            </Text>
                          </CardColumn>
                          <CardColumn>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textTitle}
                            >
                              {t('common_name_pts_code')}
                            </Text>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textSubTitle}
                            >
                              {sample.str_pts_code || 'N/A'}
                            </Text>
                          </CardColumn>
                        </CardRow>
                        <CardRow>
                          <CardColumn>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textTitle}
                            >
                              {t('common_name_process_item')}
                            </Text>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textSubTitle}
                            >
                              {sample.str_process_item || 'N/A'}
                            </Text>
                          </CardColumn>
                          <CardColumn>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textTitle}
                            >
                              {t('common_supplier')}
                            </Text>
                            <Text
                              bold={true}
                              className={listDiscussionOkNokStyle.textSubTitle}
                            >
                              {sample.str_supplier || 'N/A'}
                            </Text>
                          </CardColumn>

                        </CardRow>
                        <CardColumn>
                          {sample.bol_routine && (
                            <>
                              <Text
                                bold={true}
                                className={listDiscussionOkNokStyle.textTitle}
                              >
                                {t('common_round_type')}
                              </Text>
                              <Text
                                bold={true}
                                className={
                                  listDiscussionOkNokStyle.textSubTitle
                                }
                              >
                                {sample.str_name_routine_warehouse || 'N/A'}
                              </Text>
                            </>
                          )}
                          {(sample.uuid_category_option ===
                            '32892408-ee44-11ed-ba07-0017fa02c1e1' ||
                            sample.uuid_category_option ===
                            '36907a60-ee44-11ed-ba08-0017fa02c1e1' ||
                            sample.uuid_category_option ===
                            '3c2f932a-ee44-11ed-ba09-0017fa02c1e1') && (
                              <>
                                <Text
                                  bold={true}
                                  className={listDiscussionOkNokStyle.textTitle}
                                >
                                  {t('common_over_aged_beer')}
                                </Text>
                                <Text
                                  bold={true}
                                  className={
                                    listDiscussionOkNokStyle.textSubTitle
                                  }
                                >
                                  {sample.bol_over_aged_beer === '1'
                                    ? 'Yes'
                                    : 'No'}
                                </Text>
                              </>
                            )}
                          {sample.uuid_category_option ===
                            '5a4b7e18-0230-4f33-9f8e-2bdb44888539' && (
                              <>
                                <Text
                                  bold={true}
                                  className={listDiscussionOkNokStyle.textTitle}
                                >
                                  {t('common_production_line')}
                                </Text>
                                <Text
                                  bold={true}
                                  className={
                                    listDiscussionOkNokStyle.textSubTitle
                                  }
                                >
                                  {sample.str_production_line || 'N/A'}
                                </Text>
                              </>
                            )}
                        </CardColumn>
                        {(sample.uuid_category_option ===
                          '5a4b7e18-0230-4f33-9f8e-2bdb44888539' ||
                          sample.uuid_category_option ===
                          '32892408-ee44-11ed-ba07-0017fa02c1e1' ||
                          sample.uuid_category_option ===
                          '36907a60-ee44-11ed-ba08-0017fa02c1e1' ||
                          sample.uuid_category_option ===
                          '3c2f932a-ee44-11ed-ba09-0017fa02c1e1') && (
                            <CardRow>
                              <CardColumn>
                                {(sample.uuid_category_option ===
                                  '32892408-ee44-11ed-ba07-0017fa02c1e1' ||
                                  sample.uuid_category_option ===
                                  '36907a60-ee44-11ed-ba08-0017fa02c1e1' ||
                                  sample.uuid_category_option ===
                                  '3c2f932a-ee44-11ed-ba09-0017fa02c1e1') &&
                                  sample.bol_over_aged_beer === '1' && (
                                    <>
                                      <Text
                                        bold={true}
                                        className={
                                          listDiscussionOkNokStyle.textTitle
                                        }
                                      >
                                        {t('common_str_number_days_over_aged')}
                                      </Text>
                                      <Text
                                        bold={true}
                                        className={
                                          listDiscussionOkNokStyle.textSubTitle
                                        }
                                      >
                                        {sample.str_number_days_over_aged ||
                                          'N/A'}
                                      </Text>
                                    </>
                                  )}
                                {sample.uuid_category_option ===
                                  '5a4b7e18-0230-4f33-9f8e-2bdb44888539' && (
                                    <>
                                      <Text
                                        bold={true}
                                        className={
                                          listDiscussionOkNokStyle.textTitle
                                        }
                                      >
                                        {t('common_packaged_beer_data_code')}
                                      </Text>
                                      <Text
                                        bold={true}
                                        className={
                                          listDiscussionOkNokStyle.textSubTitle
                                        }
                                      >
                                        {sample.str_packaged_beer_data_code ||
                                          'N/A'}
                                      </Text>
                                    </>
                                  )}
                              </CardColumn>
                              <CardColumn>
                                {sample.uuid_category_option ===
                                  '5a4b7e18-0230-4f33-9f8e-2bdb44888539' && (
                                    <>
                                      <Text
                                        bold={true}
                                        className={
                                          listDiscussionOkNokStyle.textTitle
                                        }
                                      >
                                        {t('common_masking_process_for_bottles')}
                                      </Text>
                                      <Text
                                        bold={true}
                                        className={
                                          listDiscussionOkNokStyle.textSubTitle
                                        }
                                      >
                                        {sample.bol_masking_process_for_bottles ===
                                          '1'
                                          ? 'Yes'
                                          : 'No'}
                                      </Text>
                                    </>
                                  )}
                              </CardColumn>
                            </CardRow>
                          )}
                      </CardColumn>

                      {_.sortBy(tasters, 'str_name').map((taster, i) => {
                        let tasterCommentCheck = sample.tasters.find(
                          tasterRow => tasterRow.uuid_user === taster.uuid_user,
                        );
                        return (
                          <div
                            key={i}
                            className={listDiscussionOkNokStyle.contentCard}
                            onClick={evt => handleDiscussionDetail(sample, evt)}
                          >
                            <CardColumn
                              className={listDiscussionOkNokStyle.cardTaster}
                              style={{
                                backgroundColor: !_.isEmpty(tasterCommentCheck)
                                  ? tasterCommentCheck.bol_ok === 1
                                    ? 'green'
                                    : tasterCommentCheck.bol_ok === 0
                                      ? 'red'
                                      : '#DEDEDE'
                                  : '#DEDEDE',
                              }}
                            >
                              <Fragment>
                                <Text
                                  className={listDiscussionOkNokStyle.textScore}
                                  style={{
                                    marginTop: '10px',
                                  }}
                                >
                                  {!_.isEmpty(tasterCommentCheck)
                                    ? tasterCommentCheck.bol_ok === 1
                                      ? 'OK'
                                      : tasterCommentCheck.bol_ok === 0
                                        ? 'NOK'
                                        : 'N/A'
                                    : 'N/A'}
                                </Text>
                                {bolScore ? (
                                  <Text
                                    className={
                                      listDiscussionOkNokStyle.textScore
                                    }
                                    style={{
                                      marginTop: '10px',
                                    }}
                                  >
                                    {tasterCommentCheck?.flt_score || 'N/A'}
                                  </Text>
                                ) : (
                                  ''
                                )}
                                {tasterCommentCheck?.arr_comments?.length > 0 &&
                                  tasterCommentCheck.arr_comments?.length !==
                                  0 ? (
                                  tasterCommentCheck.arr_comments?.map(
                                    (comment, j) => {
                                      return (
                                        <CardRow
                                          key={comment.uuid_comment}
                                          transparent
                                          justifyContent="center"
                                        >
                                          <Text
                                            className={
                                              listDiscussionOkNokStyle.textComments
                                            }
                                          >
                                            {comment.str_degree
                                              ? comment.str_degree + ' - '
                                              : ''}
                                            {comment.str_comment}
                                          </Text>
                                        </CardRow>
                                      );
                                    },
                                  )
                                ) : (
                                  <CardRow transparent justifyContent="center">
                                    <Text
                                      className={
                                        listDiscussionOkNokStyle.textComments
                                      }
                                    >
                                      {t('common_no_comments')}
                                    </Text>
                                  </CardRow>
                                )}

                                {
                                  <Text
                                    className={
                                      listDiscussionOkNokStyle.textComments
                                    }
                                  >
                                    {!_.isEmpty(tasterCommentCheck) &&
                                      tasterCommentCheck.str_comment
                                      ? tasterCommentCheck.str_comment
                                      : ''}
                                  </Text>
                                }
                              </Fragment>
                              {renderEditButton(
                                tasterCommentCheck
                                  ? tasterCommentCheck
                                  : {
                                    uuid_user: taster.uuid_user,
                                  },
                                sample,
                                taster,
                              )}
                            </CardColumn>
                          </div>
                        );
                      })}
                    </CardRow>
                  </Fragment>
                );
              });
            })}
          </CardRow>
        </Fragment >
      )}
      <NotificationBar
        ref={nottificationRef}
        right="5px"
        notificationArr={notificationArr}
        setNotificationArr={row => {
          setNotificationArr([...row]);
        }}
      />
    </Fragment >
  );
};

export default ListDiscussionOkNok;
