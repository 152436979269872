import React, { useEffect, useState } from "react";
import { useUser } from "../contexts/User";
import Login from '../modules/Login/Login';
import LoginSSO from '../modules/LoginSSO/LoginSSO';
import LoginAuth from '../modules/LoginAuth/LoginAuth'
import Template from "../modules/Template/Template";
import _ from "lodash";
import { TourMaskProvider } from "../components/tourMask/tourMaskHooks";
import TourMask from "../components/tourMask/tourMask";

const Routes = () => {
  const { isLogged, user } = useUser();
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (
        !isLogged &&
        window.location.pathname !== "/" &&
        window.location.pathname.indexOf('/login_auth') === -1 &&
        window.location.pathname.indexOf('/competition') === -1
      ) {
        window.location.href = '/';
      }
      const role =
        user.arr_privileges.length === 1 &&
          user.arr_privileges[0] === 'TASTER' ?
          'taster' :
          user.arr_privileges.length === 1 &&
            user.arr_privileges[0] === 'PROCESS_CHECK'
            ? 'process_check' :
            'admin';
      const userGroup = 'sensoryone.' + role + ' - ' + user.str_name_site;

      localStorage.setItem('npsGroup', userGroup);
      const linkUrl = process.env.REACT_APP_STAGE === 'prod' ? 'https://nps-new.ambevdevs.com.br' : 'https://nps-new-stg.ambevdevs.com.br'
      const linkId = process.env.REACT_APP_STAGE === 'prod' ? 'ab98c90c-8ebf-4cbd-0942-08dca554381b' : 'f7c0ba84-e8a7-42e2-0358-08dca5543c34'
      const existingScript = document.querySelector(`script[src="${linkUrl}"]`);
      if (isLogged && !existingScript) {
        let scriptDom = document.createElement('script');
        scriptDom.src = linkUrl;
        scriptDom.setAttribute('host', linkUrl + '/nps');
        scriptDom.setAttribute('system', linkId);
        scriptDom.setAttribute('auth', 'idtoken');
        scriptDom.setAttribute('property', 'secret');
        scriptDom.setAttribute('storage', 'local');
        scriptDom.setAttribute('group', 'npsGroup');
        document.body.appendChild(scriptDom);
      }
      console.log(new Date())
      loadCSS('./font/iconfont.css');
      loadCSS('./fontSize/font.css');
      localStorage.removeItem('idtoken');
    }, 500)
  }, [isLogged])

  useEffect(() => {
    setIsTourOpen(true)
  }, [])

  const loadCSS = (url) => {
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    document.head.appendChild(link);
  }

  const { pathname } = window.location;

  return (
    <TourMaskProvider>
      {
        (
          pathname.indexOf('/login_auth') > -1 ||
          pathname.indexOf('/competition') > -1
        ) ?
          <LoginAuth /> :
          !isLogged ? ((
            process.env.REACT_APP_STAGE === "local" ||
              process.env.REACT_APP_STAGE === "dev" ? (
              <Login />
            ) : (
              <LoginSSO />
            )
          )) : (
            <Template />
          )
      }
      {isTourOpen &&
        <TourMask
          key={pathname + '/'}
        />
      }
    </TourMaskProvider>
  )
};

export default Routes;
