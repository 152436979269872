import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  CardRow,
  CardColumn,
  Text,
  ButtonList,
  Label,
  Button,
  AutoComplete,
  TextField,
  PdfViewerModal,
  Modal,
  ImageZoom,
} from '../';
import listAccordionStyles from './listAccordion.module.scss';
import { X, ArrowDown, ArrowUp } from 'react-feather';
import { useTasting } from '../../contexts/Tasting';
import { useUser } from '../../contexts/User';
import SampleAppService from '../../modules/services/samples/sampleApp.service';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import Moment from 'moment';
import { SortableContainer, SortableElement } from 'react-18-sortable-hoc';
import Beer from '../../assets/beer_threshold.svg';
import shadeGray from '../../assets/shade_666.png';

const ListAccordion = React.memo((props) => {
  const { t } = useTranslation();
  const [userVoting, setUserVoting] = useState('');
  const { user } = useUser();
  const sampleAppService = new SampleAppService();
  const [sample, setSample] = useState();
  const { updateTastingOkNok, byOneKeyOk } = useTasting();
  const [imgPdf, setImgPdf] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [fullButton, setFullButton] = useState('');
  const [fullHeight, setHeight] = useState('');
  const [showImg, setShowImg] = useState(false);
  const CPT_EOC_Zero_Hour =
    props.sample.uuid_category_option ===
    '32892408-ee44-11ed-ba07-0017fa02c1e1' ||
    props.sample.uuid_category_option ===
    '36907a60-ee44-11ed-ba08-0017fa02c1e1' ||
    props.sample.uuid_category_option ===
    '3c2f932a-ee44-11ed-ba09-0017fa02c1e1';

  const [score, setScore] = useState('');
  useEffect(() => {
    props.isOkForAll === 1 &&
      handleClickResultTasting({ target: { value: 'OK' } }, 9, true);
  }, [props.isOkForAll]);

  useEffect(() => {
    let userVotingTemp = props.sample.tasters.find(
      taster => taster.uuid_user === user.uuid_user,
    );
    setScore(userVotingTemp?.flt_score ? userVotingTemp?.flt_score : '');
    setUserVoting(userVotingTemp);
    setSample(props.sample);
    setImgPdf(
      props.sample &&
        props.sample.str_img_profile &&
        props.sample.str_img_profile.indexOf('.') > 1
        ? props.sample.str_img_profile.split('.').pop()
        : null,
    );
  }, [props.isOpenDropdown, props.sample, user.uuid_user]);

  const handleScore = async (objScore, evt) => {
    let value =
      objScore !== undefined
        ? objScore.score
          .toString()
          .replace(/!^(1(\.0|\.5)?|[2-8](\.0|\.5)?|9(\.0)?)$/, '')
          .replace(/[,]+/g, '.')
        : '';

    if (value) {
      if (objScore.step % 1 !== 0) {
        if (value.trim() !== '.') {
          value = value.match(/^-?\d+(?:\.\d{0,1})?/)[0];
        }
        let decimal = value.split('.');

        if (decimal.length > 1) {
          if (
            Number(decimal[1]) %
            Number(objScore.step.toString().split('.')[1]) !==
            0
          ) {
            value = `${decimal[0]}.`;
          }
        }
      } else {
        value = value.match(/^-?\d+(?:.\\d{0,0})?/)[0];
      }
    }
    let bol_ok = '';
    try {
      bol_ok =
        userVoting?.bol_ok === 1 || userVoting?.bol_ok === 0
          ? userVoting?.bol_ok
          : 2;
    } catch (error) {
      bol_ok = 2;
    }
    if (Number(value) >= objScore?.min && Number(value) <= objScore?.max) {
      handleChangeScore(bol_ok, value);
    } else {
      evt.target.value = '';
      setScore('');
      handleChangeScore(bol_ok, '');

      if (objScore?.score) {
        window.alert(
          `"${objScore.score}" ${t('common_message_out_score_range')}`,
        );
      }
    }
  };
  const handleChangeScore = async (bol_ok, value) => {
    setScore(value);
    let objUserVoting = {
      ...userVoting,
      bol_ok,
      flt_score: value === '' ? null : value,
      dat_sample: new Date(),
    };
    setUserVoting(objUserVoting);
    sampleAppService.saveTastingOkNok(sample, objUserVoting);
    if (props.handleValidateWarning) {
      const res = await updateTastingOkNok(
        sample?.uuid_sample,
        objUserVoting,
        user,
      );
      if (res) {
        props.handleValidateWarning(res.data);
      }
    }
  };
  const handleClickResultTasting = (evt, voteNow, oneKeyOk) => {
    if ((oneKeyOk && userVoting?.bol_ok !== 1) || !oneKeyOk) {
      props.handleShowComments(null);
      let voting =
        evt.target.value === 'OK' && voteNow !== 1
          ? 1
          : evt.target.value === 'NOK' && voteNow !== 0
            ? 0
            : 2;

      props.setIsOKForAll(0);

      let objUserVoting = {
        ...userVoting,
        bol_ok: voting,
        dat_sample: new Date(),
      };

      setUserVoting(objUserVoting);

      sampleAppService.saveTastingOkNok(sample, objUserVoting).then(res => {
        if (res.type === 'success') {
          if (oneKeyOk) {
            byOneKeyOk(user);
          } else {
            updateTastingOkNok(sample?.uuid_sample, objUserVoting, user).then(
              res => {
                if (props.handleValidateWarning) {
                  props.handleValidateWarning(res.data);
                }
              },
            );
          }
        }
      });
    }
  };

  const handleFormatDate = date => {
    return date ? Moment(date).utc().format('DD MMM YYYY') : '';
  };

  const handleFormatBrewNumbers = brewNumbers => {
    if (!brewNumbers) {
      return '';
    }
    let formatedBrewNumbers = '';
    brewNumbers.map((b, i) => {
      formatedBrewNumbers +=
        i < brewNumbers.length - 1
          ? b.str_descripition + ', '
          : b.str_descripition;
    });
    return formatedBrewNumbers;
  };

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div
        style={{
          flex: '2',
        }}
      >
        {items.map((comment, index) => {
          return (
            <SortableItem
              disabled
              key={comment.uuid_comment + comment.uuid_degree + `${index}`}
              comment={comment}
              index={index}
            />
          );
        })}
      </div>
    );
  });

  const SortableItem = SortableElement(({ comment }) => (
    <div
      key={comment.uuid_comment + comment.uuid_degree}
      style={{
        float: 'left',
        padding: '0px 0px 0px 8px',
        display: 'flex',
        alignItems: 'center',
        userSelect: 'none',
        color: '#FFF',
        fontWeight: '400',
        backgroundColor: comment.uuid_comment ? '#F49C2D' : '#95c511',
        borderRadius: '60px',
        margin: '0px 5px 6px 0px',
        width: '100%',
      }}
    >
      <div
        style={{
          color: '#fff',
          padding: '5px',
          lineHeight: 1,
          wordWrap: 'break-word',
          width: '90%',
        }}
      >
        {comment.uuid_degree
          ? comment.str_degree + ' - ' + comment.str_comment
          : comment.str_comment}
      </div>
      <Button
        onClick={() => props.handleRemoveComment(comment, sample.uuid_sample)}
        style={{
          textAlign: 'center',
          width: '25px',
          zIndex: '4',
          cursor: 'pointer',
          backgroundColor: 'trasparent',
          background: 'transparent',
          color: '#fff',
          display: 'flex',
          justifyContent: 'center',
          fontSize: '15px',
          fontWeight: 'bold',
          alignItems: 'center',
          paddingRight: '5px',
          height: '20px',
        }}
      >
        X
      </Button>
    </div>
  ));

  const handleShowImage = show => {
    setShowModal(!show);
  };

  const handleClosePdf = () => {
    setOpen(false);
  };

  const handlePDF = () => {
    setOpen(true);
  };

  const handleSaveEmptyVote = async (vote, uuid_sample) => {
    props.handleShowComments(uuid_sample);
    if (vote !== 0 && vote !== 1 && vote !== 2) {
      let objUserVoting = {
        ...userVoting,
        bol_ok: 2,
        dat_sample: new Date(),
      };
      setUserVoting(objUserVoting);
      const res = await sampleAppService.saveTastingOkNok(sample, objUserVoting)
      if (res.type === 'success') {
        const resp = await updateTastingOkNok(
          sample.uuid_sample,
          objUserVoting,
          user,
          true,
        )
        if (props.handleValidateWarning) {
          props.handleValidateWarning(resp.data);
        }
      }
    }
  };

  return (
    <div key={props.sample}>
      <div
        style={{
          display: 'flex',
          backgroundColor:
            userVoting?.bol_ok === 0 && !userVoting?.arr_comments?.length
              ? 'rgba(255, 69, 56, .8)'
              : null,
        }}
        className={listAccordionStyles.card}
      >
        <div className={listAccordionStyles.sampleCard}>
          <div className={listAccordionStyles.sampleCardRow}>
            <CardRow
              justifyContent="center"
              transparent
              flexWrap="nowrap"
              itemAlign="center"
              className={listAccordionStyles.orderBox}
            >
              <div
                className={listAccordionStyles.beerIconBox}
              >
                <img src={Beer} className={listAccordionStyles.beerIcon} />
                <p className={listAccordionStyles.orderText}>{props.int_order}</p>
              </div>
              <p className={listAccordionStyles.title} >
                {props.sample.str_name_category_sample}
              </p>
            </CardRow>
          </div>
          <CardRow transparent justifyContent="space-between">
            <div className={listAccordionStyles.sampleCardRow}>
              <CardRow transparent style={{ fontWeight: 'bold' }}>
                {t('common_brand')}
              </CardRow>
              <CardRow transparent style={{ height: 15 }}>
                {props.showSampleDetails ? (
                  <Fragment>
                    <p
                      data-tip={props.sample.str_name_brand}
                      className={listAccordionStyles.textInColumn}
                    >
                      {props.sample.str_name_brand || 'N/A'}
                    </p>
                    <ReactTooltip
                      effect="solid"
                      place="bottom"
                      type="warning"
                      className={listAccordionStyles.tooltip}
                    />
                  </Fragment>
                ) : (
                  <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                )}
              </CardRow>
            </div>
            <div className={listAccordionStyles.sampleCardRow}>
              <CardRow transparent style={{ fontWeight: 'bold' }}>
                {t('common_collection_point')}
              </CardRow>
              <CardRow transparent>
                {props.showSampleDetails ? (
                  <Fragment>
                    <p
                      data-tip={props.sample.str_collection_point}
                      className={listAccordionStyles.textInColumn}
                    >
                      {props.sample.str_collection_point || 'N/A'}
                    </p>
                    <ReactTooltip
                      effect="solid"
                      place="bottom"
                      type="warning"
                      className={listAccordionStyles.tooltip}
                    />
                  </Fragment>
                ) : (
                  <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                )}
              </CardRow>
            </div>
          </CardRow>
          <CardRow justifyContent="space-between" transparent>
            <div className={listAccordionStyles.sampleCardRow}>
              <CardRow transparent style={{ fontWeight: 'bold' }}>
                {t('common_sample_id')}
              </CardRow>
              <CardRow transparent style={{ height: 15 }}>
                {props.showSampleDetails ? (
                  <Fragment>
                    <p
                      data-tip={props.sample.str_sample_id}
                      className={listAccordionStyles.textInColumn}
                    >
                      {props.sample.str_sample_id || 'N/A'}
                    </p>
                    <ReactTooltip
                      effect="solid"
                      place="bottom"
                      type="warning"
                      className={listAccordionStyles.tooltip}
                    />
                  </Fragment>
                ) : (
                  <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                )}
              </CardRow>
            </div>
            <div className={listAccordionStyles.sampleCardRow}>
              <CardRow transparent style={{ fontWeight: 'bold' }}>
                {t('common_collection_date')}
              </CardRow>
              <CardRow transparent>
                {props.showSampleDetails ? (
                  handleFormatDate(props.sample.dt_collection) || 'N/A'
                ) : (
                  <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                )}
              </CardRow>
            </div>
          </CardRow>

          <CardRow justifyContent="space-between" transparent>
            <div className={listAccordionStyles.sampleCardRow}>
              <CardRow transparent style={{ fontWeight: 'bold' }}>
                {t('common_brew_number')}
              </CardRow>
              <CardRow transparent style={{ height: 20 }}>
                {props.showSampleDetails ?
                  (props.sample.arr_brew_numbers && (
                    <Fragment>
                      <p
                        data-tip={handleFormatBrewNumbers(
                          props.sample.arr_brew_numbers,
                        )}
                        className={listAccordionStyles.textInColumn}
                      >
                        {handleFormatBrewNumbers(props.sample.arr_brew_numbers)}
                      </p>
                      <ReactTooltip
                        effect="solid"
                        place="bottom"
                        type="warning"
                        className={listAccordionStyles.tooltip}
                      />
                    </Fragment>
                  )) ||
                  'N/A'
                  : (
                    <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                  )}
              </CardRow>
            </div>
            <div
              className={listAccordionStyles.sampleCardRow}
              style={{ minWidth: '92px' }}
            >
              <CardRow transparent style={{ fontWeight: 'bold' }}>
                {t('common_name_pts_code')}
              </CardRow>
              <CardRow transparent>
                {props.showSampleDetails ? (
                  <Fragment>
                    <p
                      data-tip={props.sample.str_pts_code}
                      className={listAccordionStyles.textInColumn}
                    >
                      {props.sample.str_pts_code || 'N/A'}
                    </p>
                    <ReactTooltip
                      effect="solid"
                      place="bottom"
                      type="warning"
                      className={listAccordionStyles.tooltip}
                    />
                  </Fragment>
                ) : (
                  <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                )}
              </CardRow>
            </div>
          </CardRow>

          <CardRow justifyContent="space-between" transparent>
            <div className={listAccordionStyles.sampleCardRow}>
              <CardRow transparent style={{ fontWeight: 'bold' }}>
                {t('common_tasters')}
              </CardRow>
              <CardRow transparent style={{ height: 20 }}>
                {props.showSampleDetails ? (
                  <Fragment>
                    <ReactTooltip effect="solid" place="bottom" type="warning" />
                    <p
                      data-tip={props.sample?.tasters
                        ?.filter(x => x.uuid_user !== user.uuid_user)
                        .map(o => o.str_name)
                        .join(', ')}
                      style={{
                        width: '100%',
                      }}
                    >
                      {
                        props.sample?.tasters?.filter(
                          x => x.uuid_user !== user.uuid_user,
                        ).length
                      }
                    </p>
                  </Fragment>
                ) : (
                  <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                )}
              </CardRow>
            </div>
            <div
              className={listAccordionStyles.sampleCardRow}
              style={{ minWidth: '92px' }}
            >
              <CardRow transparent style={{ fontWeight: 'bold' }}>
                {t('common_name_process_item')}
              </CardRow>
              <CardRow transparent>
                {props.showSampleDetails ? (
                  <Fragment>
                    <p
                      data-tip={props.sample.str_process_item}
                      className={listAccordionStyles.textInColumn}
                    >
                      {props.sample.str_process_item || 'N/A'}
                    </p>
                    <ReactTooltip
                      effect="solid"
                      place="bottom"
                      type="warning"
                      className={listAccordionStyles.tooltip}
                    />
                  </Fragment>
                ) : (
                  <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                )}

              </CardRow>
            </div>
          </CardRow>

          <CardRow justifyContent="space-between" transparent>
            <div
              className={listAccordionStyles.sampleCardRow}
              style={{ minWidth: '92px' }}
            >
              <CardRow transparent style={{ fontWeight: 'bold' }}>
                {t('common_supplier')}
              </CardRow>
              <CardRow transparent>
                {props.showSampleDetails ? (
                  <Fragment>
                    <p
                      data-tip={props.sample.str_supplier}
                      className={listAccordionStyles.textInColumn}
                    >
                      {props.sample.str_supplier || 'N/A'}
                    </p>
                    <ReactTooltip
                      effect="solid"
                      place="bottom"
                      type="warning"
                      className={listAccordionStyles.tooltip}
                    />
                  </Fragment>
                ) : (
                  <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                )}
              </CardRow>
            </div>
            <div
              className={listAccordionStyles.sampleCardRow}
              style={{ minWidth: '92px' }}
            >
              {props.sample.bol_routine && (
                <>
                  <CardRow transparent style={{ fontWeight: 'bold' }}>
                    {t('common_round_type')}
                  </CardRow>
                  <CardRow transparent>
                    {props.showSampleDetails ?
                      props.sample.str_name_routine_warehouse || 'N/A'
                      : (
                        <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                      )
                    }
                  </CardRow>
                </>
              )}
              {CPT_EOC_Zero_Hour && (
                <>
                  <CardRow transparent style={{ fontWeight: 'bold' }}>
                    {t('common_over_aged_beer')}
                  </CardRow>
                  <CardRow transparent>
                    {props.showSampleDetails ?
                      props.sample.bol_over_aged_beer === '1' ? 'Yes' : 'No'
                      : (
                        <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                      )
                    }
                  </CardRow>
                </>
              )}
              {props.sample.uuid_category_option ===
                '5a4b7e18-0230-4f33-9f8e-2bdb44888539' && (
                  <>
                    <CardRow transparent style={{ fontWeight: 'bold' }}>
                      {t('common_production_line')}
                    </CardRow>
                    <CardRow transparent>
                      {props.showSampleDetails ?
                        <Fragment>
                          <p
                            data-tip={props.sample.str_production_line}
                            className={listAccordionStyles.textInColumn}
                          >
                            {props.sample.str_production_line || 'N/A'}
                          </p>
                          <ReactTooltip
                            effect="solid"
                            place="bottom"
                            type="warning"
                            className={listAccordionStyles.tooltip}
                          />
                        </Fragment>
                        : (
                          <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                        )
                      }
                    </CardRow>
                  </>
                )}
            </div>
          </CardRow>
          <CardRow justifyContent="space-between" transparent>
            <div
              className={listAccordionStyles.sampleCardRow}
              style={{ minWidth: '92px' }}
            >
              {CPT_EOC_Zero_Hour && props.sample.bol_over_aged_beer === '1' && (
                <>
                  <CardRow transparent style={{ fontWeight: 'bold' }}>
                    {t('common_str_number_days_over_aged')}
                  </CardRow>
                  <CardRow transparent>
                    {props.showSampleDetails ?
                      <Fragment>
                        <p
                          data-tip={props.sample.str_number_days_over_aged}
                          className={listAccordionStyles.textInColumn}
                        >
                          {props.sample.str_number_days_over_aged || 'N/A'}
                        </p>
                        <ReactTooltip
                          effect="solid"
                          place="bottom"
                          type="warning"
                          className={listAccordionStyles.tooltip}
                        />
                      </Fragment>
                      : (
                        <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                      )
                    }
                  </CardRow>
                </>
              )}
              {props.sample.uuid_category_option ===
                '5a4b7e18-0230-4f33-9f8e-2bdb44888539' && (
                  <>
                    <CardRow transparent style={{ fontWeight: 'bold' }}>
                      {t('common_packaged_beer_data_code')}
                    </CardRow>
                    <CardRow transparent>
                      {props.showSampleDetails ?
                        <Fragment>
                          <p
                            data-tip={props.sample.str_packaged_beer_data_code}
                            className={listAccordionStyles.textInColumn}
                          >
                            {props.sample.str_packaged_beer_data_code || 'N/A'}
                          </p>
                          <ReactTooltip
                            effect="solid"
                            place="bottom"
                            type="warning"
                            className={listAccordionStyles.tooltip}
                          />
                        </Fragment>
                        : (
                          <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                        )
                      }
                    </CardRow>
                  </>
                )}
            </div>
            <div
              className={listAccordionStyles.sampleCardRow}
              style={{ minWidth: '92px' }}
            >
              {props.sample.uuid_category_option ===
                '5a4b7e18-0230-4f33-9f8e-2bdb44888539' && (
                  <>
                    <CardRow transparent style={{ fontWeight: 'bold' }}>
                      {t('common_masking_process_for_bottles')}
                    </CardRow>
                    <CardRow transparent>
                      {props.showSampleDetails ?
                        props.sample.bol_masking_process_for_bottles === '1'
                          ? 'Yes'
                          : 'No'
                        : (
                          <img src={shadeGray} className={listAccordionStyles.shadeGray} />
                        )
                      }
                    </CardRow>
                  </>
                )}
            </div>
          </CardRow>
          <div>
            <CardRow transparent>
              <ButtonList
                zIndex={0}
                list={[
                  {
                    label: 'OK',
                    value: 'OK',
                    activeColor: '#95c511',
                    funcData: props.sample,
                  },
                  {
                    label: 'NOK',
                    value: 'NOK',
                    activeColor: '#da1600',
                    funcData: props.sample,
                  },
                ]}
                onClick={evt =>
                  handleClickResultTasting(evt, userVoting?.bol_ok)
                }
                defaultSelected={
                  userVoting &&
                    userVoting.hasOwnProperty('bol_ok') &&
                    userVoting.bol_ok !== null
                    ? userVoting.bol_ok === 1
                      ? 'OK'
                      : userVoting.bol_ok === 0
                        ? 'NOK'
                        : null
                    : null
                }
              />
            </CardRow>
            {props.bolScore ? (
              <CardRow transparent>
                <CardColumn
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: '0.52',
                    paddingLeft: '5px',
                  }}
                >
                  <input
                    type="checkbox"
                    checked={!score}
                    onChange={e => {
                      handleScore(undefined, e);
                      if (score) {
                        setScore('');
                      }
                    }}
                  />
                  &ensp;
                  <Label label={t('N/A')} style={{ fontWeight: 'bold' }} />
                </CardColumn>
                <CardColumn
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: '1',
                    paddingLeft: '5px',
                    paddingRight: '6px',
                  }}
                >
                  <Label
                    label={t('common_score')}
                    style={{
                      fontWeight: 'bold',
                    }}
                  />
                  <TextField
                    name="score"
                    value={
                      userVoting &&
                        userVoting.hasOwnProperty('flt_score') &&
                        score
                        ? score
                        : ''
                    }
                    key={props.sample}
                    onChange={e => {
                      handleScore(
                        {
                          score: e.target.value,
                          step: 0.5,
                          min: 1,
                          max: 9,
                        },
                        e,
                      );
                    }}
                    labelStyle={{
                      color: '#000',
                    }}
                    style={{
                      margin: '0',
                      flex: '1',
                      marginLeft: '12px',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      padding: '8.5px 5px',
                      width: '140px',
                      borderRadius: '5px',
                      color: '#000',
                    }}
                  />
                </CardColumn>
              </CardRow>
            ) : (
              ''
            )}
            {props.sample?.str_img_profile && (
              <CardRow transparent>
                <CardColumn padding="4px">
                  {imgPdf === 'pdf' ? (
                    <PdfViewerModal
                      label={t('tasting_beer_profile')}
                      data={props.sample?.str_img_profile}
                      onClick={() => { handlePDF() }}
                      closeForm={() => { handleClosePdf() }}
                      open={open}
                      loading={false}
                      downloadFileName={props.sample?.str_img_profile}
                    ></PdfViewerModal>
                  ) : (
                    <>
                      <Button
                        label={t('tasting_beer_profile')}
                        onClick={() => handleShowImage(showModal)}
                        className={listAccordionStyles.buttonProfile}
                      />
                      <Modal
                        fullButton={fullButton}
                        fullHeight={fullHeight}
                        show={showModal}
                        className={
                          showImg ? listAccordionStyles.buttonBackGround : ''
                        }
                        style={{ position: showImg ? 'fixed' : '' }}
                      >
                        <ImageZoom
                          className={
                            showImg ? listAccordionStyles.showFull : ''
                          }
                          srcImage={sample?.str_img_profile}
                          onClick={() => {
                            handleShowImage(showModal);
                          }}
                          setFullButton={setFullButton}
                          setHeight={setHeight}
                          onShow={() => {
                            setShowImg(!showImg);
                          }}
                          onClose={() => {
                            setShowImg(false);
                          }}
                        />
                      </Modal>
                    </>
                  )}
                </CardColumn>
              </CardRow>
            )}
          </div>

          <CardRow style={{ marginTop: '10px' }}>
            <CardColumn>
              <Label
                label={t('tasting_comments')}
                bold
                style={{
                  fontWeight: 'bold',
                }}
              ></Label>
              <div
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '5px',
                  display: 'flex',
                  width: '280px',
                  height: '165px',
                  overflow: props.sample.tasters.find(
                    taster => taster.uuid_user === user.uuid_user,
                  )?.arr_comments
                    ? 'auto'
                    : 'none',
                  flexDirection: 'column-reverse',
                }}
                onClick={() => {
                  handleSaveEmptyVote(userVoting?.bol_ok);
                  props.handleShowComments(sample.uuid_sample);
                }}
              >
                <SortableList
                  items={
                    props.sample.tasters.find(
                      taster => taster.uuid_user === user.uuid_user,
                    )?.arr_comments || []
                  }
                  axis="yx"
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0px 0px',
                    height: '42px',
                    zIndex: '9',
                    flex: '1',
                    width: '260px',
                    marginTop: props.sample.tasters.find(
                      taster => taster.uuid_user === user.uuid_user,
                    )?.arr_comments
                      ? '-20px'
                      : '-12px',
                  }}
                >
                  {props.comment &&
                    props.showComments === sample?.uuid_sample ? (
                    <div
                      style={{
                        display: 'flex',
                        width: '280px',
                        alignItems: 'center',
                      }}
                    >
                      <p style={{ maxWidth: '150px' }}>
                        {props.comment?.str_degree + ' - '}
                      </p>
                      <AutoComplete
                        autoFocus={props.autoFocus}
                        nonSearch
                        name="comments"
                        margin="0px"
                        padding="0px"
                        style={{
                          width: '90px',
                        }}
                        styleList={{
                          marginTop: '0px',
                          width: '90px',
                          zIndex: '10',
                        }}
                        mainDivStyle={{
                          maxWidth: '90px',
                          marginLeft: '5px',
                        }}
                        withouBorder
                        placeholder={t('common_attributes')}
                        optionKeys={['str_name']}
                        onSearch={e =>
                          props.handleSearchComments(e, sample.uuid_sample)
                        }
                        onSelect={(e) => {
                          props.handleSelectComments(e)
                        }}
                        minimalToSearch={3}
                        freeComment
                      />
                      <Button
                        style={{
                          width: '35px',
                        }}
                        transparent
                        onClick={() => false}
                      >
                        <X
                          style={{
                            marginLeft: '5px',
                          }}
                          width="15px"
                          height="15px"
                          strokeWidth="5px"
                          onClick={() => props.handleRemoveDegree()}
                        ></X>
                      </Button>
                    </div>
                  ) : (
                    <AutoComplete
                      autoFocus={props.autoFocus}
                      style={{ display: 'flex' }}
                      nonSearch
                      name="comments"
                      margin="0px"
                      padding="15px 0px"
                      withouBorder
                      placeholder={t('common_attributes')}
                      optionKeys={['str_name']}
                      onSearch={e =>
                        props.handleSearchComments(e, sample.uuid_sample)
                      }
                      onSelect={e => {
                        props.handleSelectComments(e)
                      }}
                      minimalToSearch={3}
                      freeComment
                      mainDivStyle={{
                        marginTop: '2px',
                      }}
                    />
                  )}
                </div>
              </div>
            </CardColumn>
          </CardRow>
          <div
            style={{
              color: '#000',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleSaveEmptyVote(
                userVoting?.bol_ok,
                props.showComments === sample?.uuid_sample
                  ? null
                  : sample.uuid_sample,
              );
            }}
          >
            <Text transparent bold>
              {t('comment_list_comments')}
            </Text>
            {props.showComments === sample?.uuid_sample ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ListAccordion;
