import React, { Fragment, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { SortableContainer, SortableElement } from 'react-18-sortable-hoc';
import {
  Button,
} from "./../../components";

const SortableListComments = (props) => {
  const SortableItem = SortableElement(({ comment }) => (
    <div
      data-testid="sortaleItem"
      key={comment.uuid_comment + comment.uuid_degree}
      style={{
        float: "left",
        padding: "8px",
        background: "transparent",
        border: "0",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        userSelect: "none",
        color: "#FFF",
        fontWeight: "400",
        cursor: "move",
        backgroundColor: comment.uuid_comment ? "#F49C2D" : "#95c511",
        margin: "5px",
        borderRadius: "60px",
        height: "32px",
      }}
    >
      <span style={{ color: "#fff", marginRight: "5px" }}>
        {comment.uuid_degree
          ? comment.str_degree + " - " + comment.str_comment
          : comment.str_comment}
      </span>
      <Button
        dataTestid="sortaleItemButton"
        onClick={() => props.handleRemoveComment(comment)}
        style={{
          textAlign: "center",
          width: "25px",
          marginLeft: 5,
          zIndex: "99999",
          cursor: "pointer",
          backgroundColor: "trasparent",
          background: "transparent",
          color: "#fff",
          display: "flex",
          justifyContent: "center",
          fontSize: "15px",
          fontWeight: "bold",
        }}
      >
        X
      </Button>
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div
        data-testid="sortaleList"
        style={{
          display: "block",
          whiteSpace: "nowrap",
          alignItems: "center",
          border: "0",
          backgroundColor: "transparent",
          position: "relative",
          zIndex: "0",
          borderRadius: "3px",
          outline: "none",
        }}
      >
        {items.map((comment, index) => (
          <SortableItem
            //disabled
            key={comment.uuid_comment + comment.uuid_degree}
            comment={comment}
            index={index}
          />
        ))}
      </div>
    );
  });

  return (
    <SortableList items={props.items} onSortEnd={props.onSortEnd} axis={props.axis} />
  );
};

SortableListComments.propTypes = {
  items: PropTypes.array,
  onSortEnd: PropTypes.func.isRequired,
  handleRemoveComment: PropTypes.func.isRequired,
  axis: PropTypes.string
};

export default SortableListComments;
