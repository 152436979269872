import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import editRoundSamplesStyle from './editRoundCategories.module.scss';
import { SortableContainer, SortableElement } from 'react-18-sortable-hoc';
import {
  GenericTable,
  Modal,
  UploadButton,
  Button,
  Spinner,
  Checkbox,
  DropdownMultiField,
  TextField,
  CardRow,
  CardColumn,
  Loading,
  DropdownField,
} from '../../../components';
import PropTypes from 'prop-types';
import RoundService from '../../services/rounds/round.service';
import SampleService from '../../services/samples/sample.service';
import ReportService from '../../services/reports/report.service';
import BrandService from '../../services/brands/brand.service';
import AppService from '../../../app.service';
import { useTranslation } from 'react-i18next';
import EditRoundCategoriesSample from '../editRoundCategoriesSample/EditRoundCategoriesSample';
import uuid from 'react-uuid';
import { Edit, HelpCircle, X } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import configs from '../../../configs';

const EditRoundCategories = forwardRef((props, ref) => {
  const [samples, setSamples] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState({ arr_categories_samples: [] });
  const [brands, setBrands] = useState([]);
  const [samplesRound, setSamplesRound] = useState([]);
  const [filteredSamplesRound, setFilteredSamplesRound] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRoundSample, setLoadingRoundSample] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const roundService = new RoundService();
  const sampleService = new SampleService();
  const reportService = new ReportService();
  const brandService = new BrandService();
  const appService = new AppService();

  const [templateName, setTemplateName] = useState('');
  const { t } = useTranslation();
  const [downloadFlag, setDownloadFlag] = useState(false);
  const [testTypeOpts, setTestTypeOpts] = useState([]);
  const [productionLineOpts, setProductionLineOpts] = useState([]);
  useImperativeHandle(ref, () => ({
    splitRounds,
    samples,
    bol_splitRound
  }));
  useEffect(() => {
    setLoading(true);
    refreshState();
  }, []);

  const [bol_splitRound, setBol_splitRound] = useState(JSON.parse(sessionStorage.getItem('bol_splitOkNokRound')) ?? false);
  const [checkModeShow, setCheckModeShow] = useState(false);
  const [roundSum, setRoundSum] = useState('2');
  const [roundSumOpts, setRoundSumOpts] = useState([]);
  const [splitRounds, setSplitRounds] = useState([]);
  var splitItemIndex = 0;
  useEffect(() => {
    sessionStorage.setItem('bol_splitOkNokRound', bol_splitRound)
  }, [bol_splitRound])
  useEffect(() => {
    let newArr = [];
    for (let i = 2; i <= props.round.arr_samples?.length; i++) {
      newArr.push({
        value: `${i}`,
        label: `${i}`,
      });
    }
    setRoundSumOpts([...newArr]);
  }, [props.round.arr_samples]);
  const refreshState = async () => {
    //GET SAMPLES
    brandService.listBrands().then(res => {
      setBrands(res.results);
    });

    sampleService.listCategoriesSamples().then(
      res => {
        if (res.type === 'success' && res.results) {
          const arrSamples = res.results.map(data => {
            data.bol_check = 0;
            return data;
          });
          roundService.listRoundCategoriesSamples(props.round.uuid_round).then(
            res => {
              if (res.type === 'success' && res.results) {
                props.setRound({
                  ...props.round,
                  arr_samples: res.results,
                });
                let arrSamplesRound = res.results.filter(data => {
                  return data.dat_del == null;
                });
                setCategories(reduceSamplesToCategories(arrSamples));
                setSamples(arrSamples);
                setSamplesRound(
                  arrSamplesRound.map((sample, index) => {
                    return { ...sample, int_order_app: index };
                  }),
                );
                setTemplateName(props.round.str_template_name);
                setLoading(false);
              } else {
                window.alert(res.message);
                setLoading(false);
              }
            },
            error => {
              //console.log(error);
              setLoading(false);
            },
          );
        } else {
          window.alert(res.message);
          setLoading(false);
        }
      },
      error => {
        //console.log(error);
        setLoading(false);
      },
    );
    if (bol_splitRound) {
      const res = await roundService.getOKNOKSamplesSplit(props.round.uuid_round);
      if (res.results.length > 0) {
        setSplitRounds(res.results);
      }
    }
    const { results } = await sampleService.listRoutineWarehouse();
    if (results) {
      setTestTypeOpts(results);
    }
    const res = await sampleService.listProductionLine();
    if (res) {
      setProductionLineOpts(res.results);
    }
  };
  const reduceSamplesToCategories = samples => {
    const arrCategories = [];
    const map = new Map();
    for (const item of samples) {
      if (!map.has(item.uuid_category)) {
        map.set(item.uuid_category, true); // set any value to Map
        arrCategories.push({
          uuid_category: item.uuid_category,
          str_name_category: item.str_name_category,
        });
      }
    }
    return arrCategories;
  };

  const handleSampleOrder = (oldIndex, newIndex) => {
    //CHANGE ORDER OF SAMPLES AND UPDATE APP INDEX
    let samplesTemp = samplesRound;
    const sampleTemp = samplesTemp[oldIndex];

    samplesTemp.splice(oldIndex, 1);
    samplesTemp.splice(newIndex, 0, sampleTemp);

    return samplesTemp.map((sample, index) => {
      return { ...sample, int_order_app: index };
    });
  };

  const handleDropSample = ({ oldIndex, newIndex }) => {
    let arrSamples = samplesRound ? handleSampleOrder(oldIndex, newIndex) : [];
    props.setRound({
      ...props.round,
      arr_samples: arrSamples,
    });
    setSamplesRound(arrSamples);
  };

  const handleAddSample = () => {
    let samplesTemp = samples;
    const dt_collection = new Date().toISOString();
    let samplesToSave = [];
    samplesTemp
      .filter(sample => {
        return sample.bol_check;
      }).map(sample => {
        for (let i = 0; i < sample.sample_sum; i++) {
          const sampleNew = { ...sample, uuid_sample: uuid(), dt_collection };
          samplesToSave.push(sampleNew);
        }
      })
    let arrSamples = samplesRound ? samplesRound : [];
    arrSamples = arrSamples
      .concat(samplesToSave)
      .map((e, i) => ({ ...e, int_order_app: i }));
    if (arrSamples.length > 100) {
      window.alert(t('maximum_number_of_samples_is') + ' 100');
      return;
    }
    props.setRound({
      ...props.round,
      arr_samples: arrSamples,
    });
    props.setAddUpdateSample && props.setAddUpdateSample([...samplesToSave]);
    setSamplesRound(arrSamples);
    setSamples(
      samplesTemp.map(sample => {
        if (sample.bol_check) {
          sample.bol_check = 0;
          sample.sample_sum = 0;
        }
        return sample;
      }),
    );
  };

  const handleRemoveSample = sample => {
    const samplesTemp = samplesRound.filter(sampleTemp => {
      return sample.uuid_sample !== sampleTemp.uuid_sample;
    });
    const arrSamples = samplesTemp
      ? samplesTemp.map((e, i) => ({ ...e, int_order_app: i }))
      : [];
    props.setRound({
      ...props.round,
      arr_samples: arrSamples,
    });
    let samps = samples;
    setSamples(
      samps.map(sample => {
        sample.bol_check = false;
        return sample;
      }),
    );
    setSamplesRound(arrSamples);
  };

  const handleRemoveSplitSample = async (sample, indexX) => {
    splitRounds[indexX].arr_samples = splitRounds[indexX].arr_samples
      .filter(sampleTemp => {
        return sample.int_order_app !== sampleTemp.int_order_app;
      })
      .map((e, i) => ({ ...e, int_order_app: i }));

    setSplitRounds([...splitRounds]);
  };

  const handleCopySample = (sample, index) => {
    const sampleNew = { ...sample, uuid_sample: uuid() };
    samplesRound.splice(index, 0, sampleNew);
    const samplesTemp = [...samplesRound];
    const arrSamples = samplesTemp
      ? samplesTemp.map((e, i) => ({ ...e, int_order_app: i }))
      : [];
    props.setRound({
      ...props.round,
      arr_samples: arrSamples,
    });
    let samps = samples;
    setSamples(
      samps.map(sample => {
        sample.bol_check = false;
        return sample;
      }),
    );
    setSamplesRound(arrSamples);
  }
  const handleCopySplitSample = (itemIndx, sample, index) => {
    const sampleNew = { ...sample, uuid_sample: uuid() };
    splitRounds[itemIndx].arr_samples.splice(index, 0, sampleNew);
    splitRounds[itemIndx].arr_samples = splitRounds[itemIndx].arr_samples
      .map((e, i) => ({ ...e, int_order_app: i }))
    setSplitRounds([...splitRounds]);
  }
  const handleCheckSample = (sample, evt) => {
    setSamples(
      samples.map(sampleTemp => {
        if (sampleTemp.uuid_category_option === sample.uuid_category_option) {
          sampleTemp[evt.target.name] = evt.target.value;
        }
        return sampleTemp;
      }),
    );
  };

  const handleCheckTemplate = sample => {
    if (sample.target) {
      props.round.bol_template = sample.target.value;
      props.setRound({
        ...props.round,
        arr_samples: props.round.arr_samples,
      });
    }
  };

  const handleTemplateName = e => {
    props.round.str_template_name = e.target.value;
    props.setRound({
      ...props.round,
      arr_samples: props.round.arr_samples,
    });
  };

  const filterObjsInArr = (arr, selection) => {
    const filteredArray = [];
    arr.map(obj => {
      selection.map(filter => {
        if (obj.uuid_category === filter.uuid_category) {
          filteredArray.push(obj);
          return;
        }
      });
    });
    return filteredArray;
  };

  const handleChangeFilter = evt => {
    const value = evt.target.value;
    setFilter({
      [evt.target.name]: value,
    });
  };

  const handleSetSamples = arr_samples => {
    setSamplesRound([...arr_samples]);
  };

  const handleSetSplitSamples = (arr_samples, indexX) => {
    splitRounds[indexX].arr_samples = arr_samples;
    setSplitRounds([...splitRounds]);
  };

  useEffect(() => {
    if (filter.arr_categories_samples.length) {
      setFilteredSamplesRound(
        filterObjsInArr(samplesRound, filter.arr_categories_samples),
      );
    }
  }, [filter, samplesRound]);

  const handleFilterSamples = useCallback((row) => {
    return filterObjsInArr(row, filter.arr_categories_samples);
  }, [filter.arr_categories_samples]);

  const headers = [
    { description: t('common_sample'), field: 'str_name_category_sample' },
    { description: t('common_category_sample'), field: 'str_name_category' },
  ];

  const SortableItem = SortableElement(({ itemIndex, value, indexNum }) => (
    <EditRoundCategoriesSample
      state={props.state}
      sample={value}
      round={!bol_splitRound ? props.round : splitRounds[itemIndex]}
      brands={brands}
      testTypes={testTypeOpts}
      setRound={props.setRound}
      onRemove={(sample) => {
        if (!bol_splitRound) {
          handleRemoveSample(sample);
        } else {
          handleRemoveSplitSample(sample, itemIndex)
        }
      }}
      splitRound={!props.round.uuid_split_round && bol_splitRound}
      onCopy={(sample) => { !bol_splitRound ? handleCopySample(sample, indexNum) : handleCopySplitSample(itemIndex, sample, indexNum) }}
      productionLineOpts={productionLineOpts}
      onChangeSplitRow={(sample) => {
        handelSplitRow(itemIndex, sample);
      }}
    />
  ));

  const SortableList = SortableContainer(({ items, itemIndex }) => {
    return (
      <div className={editRoundSamplesStyle.sortableListEditRoundCategories}>
        {items.map((value, index) => (
          <SortableItem key={`sample-${index}`} itemIndex={itemIndex} index={index} indexNum={index} value={value} />
        ))}
      </div>
    );
  });
  const handelSplitRow = async (indexX, sample,) => {
    splitRounds[indexX].arr_samples = sample;
    // setSplitRounds([...splitRounds]);
  };

  const handleDownloadTemplate = () => {
    setDownloadFlag(true);
    reportService
      .generateTemplateSamplesOkNok({ privileges: props.privilege })
      .then(
        res => {
          if (res.status === 'success' && res.file) {
            setDownloadFlag(false);
            let a = document.createElement('a');
            a.href = configs.file_api_url + '/v1/' + res.file;
            a.download = res.file;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            window.alert(res.message);
          }
        },
        error => {
          //console.log(error);
        },
      );
  };
  const saveSample = () => {
    return new Promise(async (resolve, reject) => {
      const round = props.round;
      const roundRes = await roundService.updateRound(round);
      if (roundRes) {
        const sampleRes = await roundService
          .updateRoundSamples({
            uuid_round: round.uuid_round,
            arr_samples: round.arr_samples ?? [],
          })
        if (sampleRes && round.arr_samples?.length > 0) {
          round.arr_samples?.map((item) => {
            item.arr_brew_numbers && item.arr_brew_numbers?.map(BrewNumber => {
              sampleService.updateBrewNumber(BrewNumber)
            })
          })
        }
        resolve(true);
      }
    })
  }

  const handleUpload = async (name, file) => {
    setLoading(true);
    const saveRes = await saveSample();
    if (saveRes) {
      appService.uploadSampleOkNok(props.round.uuid_round, file).then(
        res => {
          window.alert(res.message);
          setLoading(false);
          refreshState();
          setShowModal(false);
        },
        e => {
          //console.log(e);
          window.alert(e.message);
          setLoading(false);
        },
      );
    }
  };

  const checkNowSplit = () => {
    if (
      window.location.pathname === '/edit_round' &&
      !props.round.uuid_split_round &&
      (props.round.arr_status[props.round.arr_status?.length - 1] ===
        'SAMPLES' ||
        props.round.arr_status[props.round.arr_status?.length - 1] ===
        'TASTERS' ||
        props.round.arr_status[props.round.arr_status?.length - 1] ===
        'SCHEDULED') &&
      !bol_splitRound
    ) {
      return true;
    } else if (
      props.round.arr_samples?.length >= 2 &&
      !bol_splitRound &&
      (window.location.pathname === '/new_round' || props.isCopyRound)
    ) {
      return true;
    } else if (bol_splitRound) {
    }
    return false;
  };
  const handleSubmit = () => {
    const samples = [...props.round.arr_samples];

    const samplesSum = samples.length;
    let hasRemainder = samplesSum % roundSum;
    const groupSize = Math.floor(samplesSum / roundSum);
    const splitRounds = [];
    const uuid_split_round = uuid();
    // const round = props.round
    // delete round.arr_samples
    for (let i = 0; i < roundSum; i++) {
      let splitRound = {
        'str_split_round_order': i + 1,
        'str_name_round': props.round.str_name_round ?? null,
        'uuid_round': uuid(),
        'uuid_split_round': uuid_split_round,
        'bol_disable': true,
        // ...round
      }
      const groupSizeNow = groupSize + (hasRemainder > 0 ? 1 : 0);
      hasRemainder--;
      const arr_samples = [...samples.splice(0, groupSizeNow)];
      splitRound = {
        ...splitRound,
        arr_samples: arr_samples.map((item, index) => {
          item.uuid_round = props.round.uuid_split_round;
          item.int_order_app = index;
          item.uuid_sample = uuid();
          return item;
        })
      }
      splitRounds.push(splitRound);
    }
    setSplitRounds(splitRounds);
    setBol_splitRound(true);
    setCheckModeShow(false);
  }
  const handleSplitRoundName = index => {
    splitRounds[index].bol_disable = !splitRounds[index].bol_disable;
    setSplitRounds([...splitRounds]);
  };
  const handleDropSplitSample = async ({ oldIndex, newIndex }) => {
    let samplesTemp = splitRounds[splitItemIndex].arr_samples;
    const sampleTemp = samplesTemp[oldIndex];
    samplesTemp.splice(oldIndex, 1);
    samplesTemp.splice(newIndex, 0, sampleTemp);

    splitRounds[splitItemIndex].arr_samples = samplesTemp.map(
      (sample, index) => {
        return { ...sample, int_order_app: index };
      },
    );
    setSplitRounds([...splitRounds]);
  };

  return (
    <div className={editRoundSamplesStyle.droppableCard}>
      {props.round && samplesRound ? (
        <div>
          <GenericTable
            arrHeader={headers}
            arrRow={samples}
            searchable={true}
            onOKNOkCheck={handleCheckSample}
            loading={loading}
            rowsPage={6}
            renderFilterDom={() =>
              <span
                className={editRoundSamplesStyle.addedSamples}
              >
                {t('sample_added')}:&ensp;
                <b
                  className={props.round.arr_samples?.length >= 100
                    ? editRoundSamplesStyle.colorRed
                    : editRoundSamplesStyle.colorGreen
                  }
                >
                  {props.round.arr_samples?.length ?? 0}
                </b> /
                <span> 100</span>
              </span>
            }
            buttonList={[
              {
                onClick: () => {
                  setShowModal(!showModal);
                },
                label: t('common_import'),
                dataTip: t('import_samples'),
                dataFor: 'common_import'
              },
            ]}
            handleAddSample={handleAddSample}
          />
          <CardRow itemAlign={'center'}>
            <CardColumn maxWidth={350}>
              <DropdownMultiField
                name="arr_categories_samples"
                options={categories ? categories : []}
                valueField="uuid_category"
                margin="0px"
                labelField="str_name_category"
                label={t('categories_list_categories')}
                value={filter.arr_categories_samples}
                onChange={value => {
                  handleChangeFilter(value);
                }}
              />
            </CardColumn>
            <CardColumn maxWidth={350}>
              <div className={editRoundSamplesStyle.box}>
                <Checkbox
                  name="bol_template"
                  value={
                    props.round.bol_template === 'Template' ||
                      props.round.bol_template === 'Yes' ||
                      props.round.bol_template === 1
                      ? true
                      : false
                  }
                  onChange={evt => {
                    handleCheckTemplate(evt);
                  }}
                />
                <TextField
                  disabled={
                    !props.round.bol_template ||
                    props.round.bol_template === 'No'
                  }
                  placeholder={t('common_name_template')}
                  style={{ minHeight: '40px' }}
                  label={t('common_template')}
                  value={templateName}
                  onBlur={e => {
                    handleTemplateName(e);
                  }}
                  onChange={e => {
                    setTemplateName(e.target.value);
                  }}
                ></TextField>
              </div>
            </CardColumn>
            <CardColumn maxWidth={150}>
              {
                (props.round.arr_samples?.length >= 2 ||
                  bol_splitRound) && (
                  <>
                    <Button
                      blue={checkNowSplit()}
                      onClick={() => {
                        setCheckModeShow(true);
                      }}
                      readOnly={!checkNowSplit()}
                      margin="0 0 0 15px"
                      dataTip={
                        !checkNowSplit() &&
                        t(
                          'you_cannot_split_a_round_that_has_already_been_split_or_in_progress',
                        )
                      }
                    >
                      {t('split_round')}
                    </Button>
                    {!checkNowSplit() && (
                      <ReactTooltip effect="solid" place="top" />
                    )}
                  </>
                )}
            </CardColumn>
            <CardColumn maxWidth={150}>
              {bol_splitRound && !loadingRoundSample && (
                <Button
                  blue
                  confirm
                  onClick={async () => {
                    setBol_splitRound(false);
                    const arr_samples = splitRounds.map(item => { return item.arr_samples })
                      .reduce((acc, curr) => acc.concat(curr), [])
                      .map((item, index) => {
                        item.int_order_app = index;
                        item.uuid_sample = uuid();
                        item.uuid_round = props.round.uuid_round;
                        return item
                      })
                    props.round.arr_samples = arr_samples
                    setSamplesRound([...arr_samples])
                  }}
                  style={{
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '10px',
                  }}
                >
                  {t('reset')}
                </Button>
              )}
            </CardColumn>
          </CardRow>
          <Modal
            className={editRoundSamplesStyle.attention}
            show={checkModeShow}
            style={{ height: '100%' }}
          >
            <h2 className={editRoundSamplesStyle.attentionTitle}>
              {t('attention')}
            </h2>
            <div className={editRoundSamplesStyle.attentionLittleTitle}>
              {t('how_many_rounds_splitting_do_you_want_to_make')}？
            </div>
            <DropdownField
              name="round_sum"
              options={roundSumOpts}
              valueField="value"
              labelField="label"
              bolOrder={false}
              arrowTop="10px"
              margin="10px 50px"
              value={roundSum}
              onChange={evt => {
                setRoundSum(evt.target.value);
              }}
            />
            <div className={editRoundSamplesStyle.buttonContainer}>
              <Button
                className={editRoundSamplesStyle.cancelButton}
                label={t('common_cancel')}
                remove
                margin="0px 10px 0px 0px"
                onClick={() => {
                  setCheckModeShow(false);
                }}
              ></Button>

              <Button
                className={editRoundSamplesStyle.confirmButton}
                label={t('confirm')}
                confirm
                disabled={!roundSum}
                onClick={handleSubmit}
              ></Button>
            </div>
          </Modal>

          <Modal className={editRoundSamplesStyle.Modal} show={showModal}>
            <CardRow justifyContent="flex-end">
              <Button
                onClick={() => {
                  setShowModal(!showModal);
                }}
                className={editRoundSamplesStyle.btnClose}
              >
                <X />
              </Button>
            </CardRow>

            <CardRow itemAlign="center" justifyContent="center" padding="10px">
              <UploadButton
                name="imp_template"
                label={t('sample_upload_document')}
                uploadFunc={handleUpload}
                allowExtension={['.xlsx']}
              />
              <HelpCircle
                data-tip={t('sample_import_message')}
                className={editRoundSamplesStyle.helpCircle}
              />
              <ReactTooltip
                effect="solid"
                place="top"
                type="warning"
                className={editRoundSamplesStyle.tooltip}
              />
            </CardRow>

            <CardRow justifyContent="flex-end">
              {!downloadFlag ? (
                <Button
                  className={editRoundSamplesStyle.buttonLink}
                  label={t('common_download_template')}
                  transparent
                  onClick={() => {
                    handleDownloadTemplate();
                  }}
                ></Button>
              ) : (
                <Loading
                  loading={true}
                  style={{ margin: '0px 5px 5px 0px' }}
                ></Loading>
              )}
            </CardRow>
          </Modal>
          {
            bol_splitRound ? <div>
              {
                splitRounds.map((item, index) =>
                  <div className={editRoundSamplesStyle.splitItem} key={item}>
                    <div
                      className={editRoundSamplesStyle.splitItemTitleName}
                    >
                      <div className={editRoundSamplesStyle.nameNum}>
                        {`Round ${item.str_split_round_order}`}
                      </div>
                      <div className={editRoundSamplesStyle.nameOpts}>
                        <p className={editRoundSamplesStyle.nameOptsText}>
                          {t('common_name')}
                        </p>
                        <div
                          className={editRoundSamplesStyle.nameOptstitle}
                        >
                          <TextField
                            value={item.str_name_round}
                            onChange={evt => {
                              splitRounds[index].str_name_round =
                                evt.target.value;
                              setSplitRounds([...splitRounds]);
                            }}
                            disabled={item.bol_disable}
                            maxLength="40"
                            padding="11px 5px"
                            className={editRoundSamplesStyle.nameOptsInput}
                          />
                          <Edit
                            className={editRoundSamplesStyle.editBtn}
                            onClick={() => {
                              handleSplitRoundName(index);
                            }}
                          ></Edit>
                        </div>
                      </div>
                    </div>
                    <SortableList
                      distance={5}
                      items={
                        filter.arr_categories_samples.length > 0 ?
                          handleFilterSamples(item.arr_samples ?? []) :
                          item.arr_samples ?? []
                      }
                      itemIndex={index}
                      axis={'xy'}
                      onSortEnd={async (sort) => {
                        splitItemIndex = index;
                        handleDropSplitSample(sort)
                      }}
                    />
                  </div>
                )
              }

            </div>
              : <SortableList
                distance={5}
                items={
                  filter.arr_categories_samples.length > 0
                    ? filteredSamplesRound
                    : samplesRound
                }
                axis={'xy'}
                onSortEnd={handleDropSample}
              />
          }

        </div>
      ) : null}
    </div>
  );
});


export default EditRoundCategories;

