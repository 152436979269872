import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import uuid from 'react-uuid';
import listUsersUsersStyle from './listusersusers.module.scss';
import { CustomColumns, GenericTable } from '../../../components';
import UserService from '../../services/users/user.service';
import AppService from '../../../app.service';
import ReportService from '../../services/reports/report.service';

import { useTranslation } from 'react-i18next';

const ListUsersUsers = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [privilege, setPrivilege] = useState([]);
  const [canExportUsers, setCanExportUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const reportService = new ReportService();
  const userService = new UserService();
  const appService = new AppService();
  const [showCustomColumns, SetShowCustomColumns] = useState(false);
  const [editPermission, setEditPermission] = useState([
    { str_name: 'employee_id', bol_status: null },
    { str_name: 'common_name', bol_status: null },
    { str_name: 'common_email', bol_status: null },
    { str_name: 'user_principal_name', bol_status: null },
    { str_name: 'common_profile', bol_status: null },
    { str_name: 'common_zone', bol_status: null },
    { str_name: 'common_country', bol_status: null },
    { str_name: 'common_site', bol_status: null },
    { str_name: 'user_status', bol_status: null },
    { str_name: 'common_job_position', bol_status: false },
    // { str_name: 'common_language', bol_status: false },
    { str_name: 'user_type', bol_status: null },
  ]);

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES
    appService.listPrivileges('USER_MANAGEMENT_EDIT').then(
      res => {
        if (res.type === 'success' && res.results) {
          setPrivilege(res.results[0]);
          setLoading(false);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
    appService.listPrivileges('USER_MANAGEMENT_EXPORT').then(
      res => {
        if (res.type === 'success' && res.results) {
          //console.log(res.results[0]);
          setCanExportUsers(res.results[0]);
          setLoading(false);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
    userService.listCustomColumns('listUser').then(res => {
      if (
        res.type === 'success' &&
        res.results[0].user_sp_se_column_options &&
        res.results[0].user_sp_se_column_options.length ===
        editPermission.length
      ) {
        setEditPermission(res.results[0].user_sp_se_column_options);
      }
    });
  };

  const handleNewUser = useCallback((isAbiUser) => {
    history.push({
      pathname: '/edit_user',
      state: {
        values: {
          uuid_user: uuid(),
          // bol_abi_account: isAbiUser === 'abi' ? true : false,
          str_user_type: isAbiUser
        },
        privilege: privilege,
        show: false,
      },
    });
  }, [privilege]);

  const handleEditUser = useCallback(
    values => () => {
      history.push({
        pathname: '/edit_user',
        state: {
          values: values,
          privilege: privilege,
          show: true,
        },
      });
    },
    [privilege],
  );

  const handleRemoveUser = values => {
    return new Promise((resolve, reject) => {
      if (window.confirm(`${t('user_remove_user')}: ${values.str_name}`)) {
        userService.deleteUser(values.uuid_user).then(
          res => {
            if (res.type === 'success' && res.results) {
              resolve(true);
            } else {
              window.alert(res.message);
              resolve(true);
            }
          },
          error => {
            //console.log(error);
            reject(error);
          },
        );
      }
    });
  };

  const filterHeaders = [
    {
      description: t('employee_id'),
      field: 'str_abi_id',
      cache: true,
      fieldCache: 'user.str_abi_id',
    },
    {
      description: t('common_name'),
      field: 'str_name',
      cache: true,
      fieldCache: 'user.str_name',
    },
    {
      description: t('common_email'),
      field: 'str_email',
      cache: true,
      fieldCache: 'user.str_email',
    },
    {
      description: t('user_principal_name'),
      field: 'str_user_principal_name',
      cache: true,
      fieldCache: 'user.str_user_principal_name',
      width: '160px',
    },
    {
      description: t('common_profile'),
      field: 'str_profile_name',
      cache: true,
      fieldCache: 'user.str_profile_name',
    },
    {
      description: t('common_zone'),
      field: 'str_zone_name',
      cache: true,
      fieldCache: 'user.str_zone_name',
    },
    {
      description: t('common_country'),
      field: 'str_country_name',
      cache: true,
      fieldCache: 'user.str_country_name',
    },
    {
      description: t('common_site'),
      field: 'str_site_name',
      cache: true,
      fieldCache: 'user.str_site_name',
    },
    {
      description: t('user_status'),
      field: 'str_employee_status',
      fieldCache: 'user.str_employee_status',
      type: 'select',
      notSearchable: true,
      selectSearch: true,
      changeOpts: [
        {
          value: "Active",
          label: t('Active'),
        },
        {
          value: "OnLeave",
          label: t('OnLeave'),
        },
      ],
    },
    {
      description: t('common_job_position'),
      field: 'str_job_position_name',
      cache: true,
      fieldCache: 'user.str_job_position_name',
    },
    // {
    //   description: t('common_language'),
    //   field: 'str_language',
    //   cache: true,
    //   fieldCache: 'user.str_language',
    // },
    {
      description: `${t('user_type')}`,
      field: 'str_user_type',
      type: 'select',
      fieldCache: 'str_user_type',
      notSearchable: true,
      selectSearch: true,
      changeOpts: [
        {
          value: "ABI",
          label: t('ABI'),
        },
        {
          value: "Contractor",
          label: t('Contractor'),
        },
        {
          value: "Temporary",
          label: t('Temporary'),
        },
      ],
    },
  ];
  const headers = filterHeaders.filter((item, index) => {
    return (
      editPermission[index].bol_status === null ||
      editPermission[index].bol_status === 1
    );
  });

  return (
    <div className={listUsersUsersStyle.pageContainer}>
      {showCustomColumns && (
        <CustomColumns
          title={t('column_options')}
          explain={t('select_which_of_the_columns_do_you_want_to_be_displayed')}
          round={'listUser'}
          arrHeader={headers}
          setShow={SetShowCustomColumns}
          onChange={setEditPermission}
          values={editPermission}
        />
      )}
      <GenericTable
        // setShowCustomColumns={SetShowCustomColumns}
        originHeader={filterHeaders}
        arrHeader={headers}
        dataServer={userService.listUsersByServer}
        searchable={true}
        onEdit={privilege.bol_access ? handleEditUser : null}
        onRemove={privilege.bol_access ? handleRemoveUser : null}
        headerStyle={{
          minWidth: '100px'
        }}
        leftButtonList={[
          privilege.bol_access
            ? {
              onClick: () => { handleNewUser('Contractor') },
              label: t('user_new_user'),
            }
            : null,
          privilege.bol_access
            ? {
              onClick: () => { handleNewUser('Temporary') },
              label: t('user_non_abi_user'),
            }
            : null,
        ]}
        buttonList={
          [
            {
              onClick: () => { SetShowCustomColumns(true) },
              type: 'CustomColumns'
            }
          ]
        }
        exportService={
          canExportUsers.bol_access ? reportService.generateUsersReport : null
        }
        leftExportLabel={canExportUsers.bol_access ? t('user_export_users') : null}
        loading={loading}
      />
    </div>
  );
};

export default ListUsersUsers;
