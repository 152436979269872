import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import uuid from 'react-uuid';
import listSamplesStyle from './listsamples.module.scss';
import {
  GenericTable,
  Label,
  Modal,
  Button,
  CardRow,
  UploadButton,
  CustomColumns,
  Loading,
} from '../../../components';
import AppService from '../../../app.service';
import SampleService from '../../services/samples/sample.service';
import ReportService from '../../services/reports/report.service';
import { useTranslation } from 'react-i18next';
import { HelpCircle, X } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import configs from '../../../configs';
import UserService from '../../services/users/user.service';

const ListSamples = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [privilege, setPrivilege] = useState([]);
  const [loading, setLoading] = useState(false);
  const appService = new AppService();
  const sampleService = new SampleService();
  const reportService = new ReportService();
  const userService = new UserService();
  const [showModal, setShowModal] = useState(false);
  const [showCustomColumns, setShowCustomColumns] = useState(false);
  const [editPermission, setEditPermission] = useState([
    { str_name: 'common_zone', bol_status: null },
    { str_name: 'common_country', bol_status: null },
    { str_name: 'common_site', bol_status: null },
    { str_name: 'common_brand', bol_status: null },
    { str_name: 'sample_production_date', bol_status: null },
    { str_name: 'common_sku', bol_status: null },
    { str_name: 'unique_id', bol_status: null },
    { str_name: 'common_batch', bol_status: null },
    { str_name: 'sample_project_name', bol_status: null },
    { str_name: 'sample_trial_identifier', bol_status: null },
    { str_name: 'common_kpi_month', bol_status: null },
    { str_name: 'submission_type', bol_status: null },
    { str_name: 'sample_tasted_dates', bol_status: null },
    { str_name: 'shipping_received', bol_status: null },
    { str_name: 'common_notes', bol_status: null },
  ]);
  const [downloadFlag, setDownloadFlag] = useState(false);

  useEffect(() => {
    refreshState();
  }, []);

  const refreshState = () => {
    setLoading(true);
    //GET PRIVILEGES
    appService.listPrivileges('SAMPLE_MANAGEMENT_EDIT').then(
      res => {
        if (res.type === 'success' && res.results) {
          setPrivilege(res.results[0]);
          setLoading(false);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
    userService.listCustomColumns('sample').then(res => {
      if (
        res.type === 'success' &&
        res.results[0].user_sp_se_column_options?.length ===
        editPermission.length
      ) {
        setEditPermission(res.results[0].user_sp_se_column_options);
      }
    });
  };

  const handleUpload = (name, file) => {
    setLoading(true);
    appService.uploadSample(name, file).then(
      res => {
        if (res.errors?.length) {
          let message =
            !res.success?.length || res.errors.length === res.samples
              ? t('file_upload_fail')
              : t('file_upload_success_with_error');
          res.errors.forEach(o => {
            const userMessage =
              typeof o.message === 'string'
                ? t(o.message)
                : o.message?.map(x => t(x)).join();
            if (
              message === t('file_upload_fail') ||
              message === t('file_upload_success_with_error')
            ) {
              message += `\n ${t('common_line')} ${o.line}: ${t(
                o.field,
              )} ${userMessage}`;
            } else {
              message += `,\n ${t('common_line')} ${o.line}: ${t(
                o.field,
              )} ${userMessage}`;
            }
          });
          alert(message);
        } else {
          window.alert(t(res.message));
        }
        setLoading(false);
        window.location.reload();
      },
      e => {
        window.alert(e.message);
        setLoading(false);
      },
    );
  };

  const handleEnableSampleReport = useCallback(item => item.closed);
  const handleSampleReport = useCallback(
    (row, index, value) => () => {
      const uuid_round_type = row.arr_tasted.filter((item, index) => {
        return item.uuid_round === value;
      })[0].uuid_round_type;

      reportService
        .generate({
          arr_rounds: [value],
          bol_closed: true,
          arr_round_types: [uuid_round_type],
        })
        .then(
          res => {
            if (
              res.status === 'success' &&
              res.file &&
              res.file === 'report.xlsx'
            ) {
              setLoading(false);
              window.alert(t('common_report_not_have_data'));
            } else if (
              res.status === 'success' &&
              res.file &&
              res.file !== 'report.xlsx'
            ) {
              setLoading(false);
              let a = document.createElement('a');
              a.href = configs.file_api_url + '/v1/' + res.file;
              a.download = res.file;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            } else {
              setLoading(false);
              window.alert(res.message);
            }
          },
          error => {
            setLoading(false);
            alert(t('you_do_not_have_privilege_to_export_this_report'));
          },
        );
    },
    [],
  );

  const handleNewSample = useCallback(() => {
    sessionStorage.removeItem('subType');
    history.push({
      pathname: '/new_sample',
      state: {
        values: { uuid_sample: uuid() },
        privilege: privilege,
        isNewSample: true,
      },
    });
  }, [privilege]);

  const handleDownloadTemplate = () => {
    setDownloadFlag(true);
    reportService.generateTemplateSamples({ privileges: privilege }).then(
      res => {
        if (res.status === 'success' && res.file) {
          setDownloadFlag(false);
          let a = document.createElement('a');
          a.href = configs.file_api_url + '/v1/' + res.file;
          a.download = res.file;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  const handleEditSample = useCallback(
    values => () => {
      history.push({
        pathname: '/edit_sample',
        state: { values: values, privilege: privilege, isNewSample: false },
      });
    },
    [privilege],
  );

  const handleRemoveSample = values => {
    return new Promise((resolve, reject) => {
      if (
        window.confirm(
          `${t('sample_remove_sample')}: ${values.str_site_name} - ${values.str_brand_name
          } - ${values.str_batch ? values.str_batch : 'N/A'}`,
        )
      ) {
        sampleService.deleteSample(values.uuid_sample).then(
          res => {
            if (res.type === 'success' && res.results) {
              resolve(true);
            } else {
              window.alert(res.message);
              resolve(true);
            }
          },
          error => {
            //console.log(error);
            reject(error);
          },
        );
      }
    });
  };

  const handleReceived = async (item, row) => {
    if (item.bol_receive === false) {
      var r = window.confirm(t('receive_sample_alert'));
      if (r === true) {
        item.bol_receive = !item.bol_receive;
        await sampleService.shippingSitesReceived({
          uuid_sample: row.uuid_sample,
          uuid_sample_send_site: item.uuid_sample_send_site,
          bol_receive: item.bol_receive,
        });
        await refreshState();
      }
    } else {
      var r1 = window.confirm(t('receive_uncheck_alert'));
      if (r1 === true) {
        item.bol_receive = !item.bol_receive;
        await sampleService.shippingSitesReceived({
          uuid_sample: row.uuid_sample,
          uuid_sample_send_site: item.uuid_sample_send_site,
          bol_receive: item.bol_receive,
        });
        await refreshState();
      }
    }
  };

  const filterHeaders = [
    {
      description: t('common_zone'),
      field: 'str_zone_name',
      cache: true,
      fieldCache: 'sample.str_zone_name',
    },
    {
      description: t('common_country'),
      field: 'str_country_name',
      cache: true,
      fieldCache: 'sample.str_country_name',
    },
    {
      description: t('common_site'),
      field: 'str_site_name',
      cache: true,
      fieldCache: 'sample.str_site_name',
    },
    {
      description: t('common_brand'),
      field: 'str_brand_name',
      cache: true,
      fieldCache: 'sample.str_brand_name',
    },
    {
      description: t('sample_production_date'),
      field: 'dat_production',
      type: 'date',
      cache: true,
      fieldCache: 'sample.dat_production',
    },
    {
      description: t('common_sku'),
      field: 'str_sku_name',
      cache: true,
      fieldCache: 'sample.str_sku_name',
    },
    {
      description: t('unique_id'),
      field: 'unique_id',
      cache: true,
      fieldCache: 'sample.unique_id',
    },
    {
      description: t('common_batch'),
      field: 'str_batch',
      cache: true,
      fieldCache: 'sample.str_batch',
    },
    {
      description: t('sample_project_name'),
      field: 'str_name_project',
      cache: true,
      fieldCache: 'sample.str_name_project',
    },
    {
      description: t('sample_trial_identifier'),
      field: 'str_trial_identifier',
      cache: true,
      fieldCache: 'sample.str_trial_identifier',
    },
    {
      description: t('common_kpi_month'),
      field: 'dat_kpi',
      type: 'datemonth',
      cache: true,
      fieldCache: 'sample.dat_kpi',
      notSearchable: true,
      selectKpiMonth: true,
    },
    {
      description: t('submission_type'),
      field: 'str_submission_type_name',
      cache: true,
      fieldCache: 'sample.str_submission_type_name',
    },
    {
      description: t('sample_tasted_dates'),
      field: 'str_type_name',
      type: 'multi-action',
      data: 'arr_tasted',
      width: '160px',
      action: handleSampleReport,
      activation: handleEnableSampleReport,
    },
    {
      description: t('shipping_received'),
      field: 'str_name',
      type: 'ShippingReceived',
      data: 'arr_sample_send_site',
      action: handleReceived,
      width: '100px',
      notSearchable: true,
      selectReceived: true,
      fieldCache: 'str_receive_status',
    },
    {
      description: t('common_notes'),
      field: 'str_comment',
      cache: true,
      width: '200px',
      fieldCache: 'sample.str_comment',
    },
  ];

  const headers = filterHeaders.filter((item, index) => {
    return (
      editPermission[index].bol_status === null ||
      editPermission[index].bol_status === 1
    );
  });
  return (
    <div className={listSamplesStyle.pageContainer}>
      <Label label={t('sample_list_samples')} bold neutral />
      {showCustomColumns && (
        <CustomColumns
          title={t('column_options')}
          round={'sample'}
          setShow={setShowCustomColumns}
          onChange={setEditPermission}
          explain={t('select_which_of_the_columns_do_you_want_to_be_displayed')}
          values={editPermission}
          arrHeader={headers}
        />
      )}
      <GenericTable
        setShowCustomColumns={setShowCustomColumns}
        arrHeader={headers}
        originHeader={filterHeaders}
        dataServer={sampleService.listSamplesByServer}
        searchable={true}
        onEdit={privilege.bol_access ? handleEditSample : null}
        onRemove={privilege.bol_access ? handleRemoveSample : null}
        newRegister={
          privilege.bol_access
            ? {
              onClick: handleNewSample,
              label: t('sample_new_sample'),
            }
            : null
        }
        loading={loading}
        buttonList={[
          {
            onClick: () => {
              setShowModal(!showModal);
            },
            label: t('common_import'),
          },
        ]}
        checkboxFilter={{
          fieldName: 'show_all_samples',
          label: t('sample_show_all_samples_label'),
          helpCircle: t('sample_show_all_samples_hint'),
        }}
        handleReceived={handleReceived}
        needMinHeight
      />
      <Modal className={listSamplesStyle.Modal} show={showModal}>
        <CardRow justifyContent="flex-end">
          <Button
            onClick={() => {
              setShowModal(!showModal);
            }}
            className={listSamplesStyle.btnClose}
          >
            <X />
          </Button>
        </CardRow>

        <CardRow itemAlign="center" justifyContent="center" padding="10px">
          <UploadButton
            name="imp_template"
            label={t('sample_upload_document')}
            uploadFunc={handleUpload}
            allowExtension={['.xlsx']}
          />
          <HelpCircle
            data-tip={t('sample_import_message')}
            className={listSamplesStyle.helpCircle}
          />
          <ReactTooltip
            effect="solid"
            place="top"
            type="warning"
            className={listSamplesStyle.tooltip}
          />
        </CardRow>

        <CardRow justifyContent="flex-end">
          {!downloadFlag ? (
            <Button
              className={listSamplesStyle.buttonLink}
              label={t('common_download_template')}
              transparent
              onClick={() => {
                handleDownloadTemplate();
              }}
            ></Button>
          ) : (
            <Loading
              loading={true}
              style={{ margin: '0px 5px 5px 0px' }}
            ></Loading>
          )}
        </CardRow>
      </Modal>
    </div>
  );
};

export default ListSamples;
