import React, { useEffect, useState, useCallback, useRef } from 'react';
import editRoundStyle from './editround.module.scss';
import { Label, Tab, Modal, Button } from '../../../components';
import RoundService from '../../services/rounds/round.service';
import EditRoundSchedule from '../editRoundSchedule/EditRoundSchedule';
import EditRoundTasters from '../editRoundTasters/EditRoundTasters';
import EditRoundSamples from '../editRoundSamples/EditRoundSamples';
import EditRoundSamplesThreshold from '../editRoundSamplesThreshold/EditRoundSamplesThreshold';
import EditRoundFlavor from '../EditRoundFlavor/EditRoundFlavor';
import { useTranslation } from 'react-i18next';
import EditRoundCategories from '../editRoundCategories/EditRoundCategories';
import { useHistory } from 'react-router-dom';
import ProfileService from '../../services/users/profile.service';
import { useProfile } from '../../../contexts/Profile';
import EditRoundTriangle from '../editRoundTriangle/EditRoundTriangle';
import { IoEmit } from '../../../utils/socketIo';
import { useUser } from '../../../contexts/User';
import SampleService from '../../services/samples/sample.service';

const EditRound = props => {
  const { t } = useTranslation();
  const [round, setRound] = useState({});
  const [tabs, setTabs] = useState([]);
  const [roundId, setRoundId] = useState('');
  const [privilege, setPrivilege] = useState({});
  const [sampleEditPrivilege, setSampleEditPrivilege] = useState([]);
  const [allow, setAllow] = useState(false);
  const roundService = new RoundService();
  const profileService = new ProfileService();
  const sampleService = new SampleService();
  const tabRef = useRef(null);

  const editSamplesRef = useRef(null);
  const history = useHistory();

  const { handleProfileLevels } = useProfile();

  const setRoundCallBack = useCallback(
    obj => {
      setRound({ ...obj });
    },
    [round],
  );

  const [addUpdateSample, setAddUpdateSample] = useState([]);
  const [isKPIBrandNotChange, setIsKPIBrandNotChange] = useState([]);

  const { user } = useUser();
  useEffect(() => {
    return () => {
      sessionStorage.removeItem("bol_splitRound");
      sessionStorage.removeItem("bol_splitOkNokRound");
    }
  }, [])
  useEffect(() => {
    let dateFinishAux = null;
    let dateStartAux = null;
    let hourFinish = null;
    let hourStart = null;

    if (
      props.location.state.values.dat_finish !== null &&
      props.location.state.values.dat_finish !== undefined
    ) {
      let dateFinish = props.location.state.values.dat_finish.toString();

      hourFinish = dateFinish.substring(11, 19).split(':');

      dateFinish = dateFinish.substring(0, 10).split('-');

      dateFinishAux = `${dateFinish[0]}-${dateFinish[1]}-${dateFinish[2]}`;
    }

    if (
      props.location.state.values.dat_start !== null &&
      props.location.state.values.dat_start !== undefined
    ) {
      let dateStart = props.location.state.values.dat_start.toString();

      hourStart = dateStart.substring(11, 19).split(':');

      dateStart = dateStart.substring(0, 10).split('-');

      dateStartAux = `${dateStart[0]}-${dateStart[1]}-${dateStart[2]}`;
    }

    (async () => {
      await profileService
        .getUserPreviligesByZone(user.uuid_profile, user.uuid_user)
        .then(res => {
          if (res.type === 'success' && res.results) {
            handleProfileLevels(
              res.results[0].privileges_sp_se_get_user_profiles,
            );
          } else {
            window.alert(res.message);
          }
        });
    })();

    setRound({
      ...props.location.state.values,
      bol_layout_horizontal: props.location.state.values.bol_layout_horizontal
        ? 1
        : 0,
      dat_finish: props.location.state.values.dat_finish
        ? `${dateFinishAux}T${hourFinish[0]}:${hourFinish[1]}:${hourFinish[2]}`
        : null,
      dat_start: props.location.state.values.dat_start
        ? `${dateStartAux}T${hourStart[0]}:${hourStart[1]}:${hourStart[2]}`
        : null,
    });
    setPrivilege(props.location.state.privilege);
    setSampleEditPrivilege(props.location.state.sampleEditPrivilege);
    setRoundId(
      props.location.state.values.int_round
        ? props.location.state.values.int_round
        : '',
    );
  }, [props.location.state]);

  useEffect(() => {
    handleSave();
  }, [round, allow]);

  const handleSave = () => {
    let disabledTasters = true;
    let disabledSamples = true;
    if (round.uuid_round) {
      const checkDate = round.dat_start ? round.dat_start : false;
      const checkSite = round.uuid_site ? round.uuid_site : false;
      const checkFinishDate = round.dat_finish ? round.dat_finish : false;
      const checkRoundType = round.uuid_round_type
        ? round.uuid_round_type
        : false;
      if (checkDate && checkSite && checkFinishDate && checkRoundType) {
        disabledTasters = false;
        if (checkRoundType) {
          disabledSamples = false;
        }
      }
    }

    if (round.uuid_round && round.dat_start && round.dat_finish) {
      const roundTemp = {
        ...round,
        arr_samples:
          round.uuid_round_type === 'be229b18-2eb4-11eb-ba1d-0229531a6f14'
            ? []
            : round.arr_samples
      }
      roundService.updateRound(roundTemp).then(
        res => {
          if (res.type === 'success' && res.results) {
            if (res.results[0].round_sp_in_up_admin_round) {
              setRoundId(res.results[0].round_sp_in_up_admin_round);

              if (res.results[0].round_sp_in_up_admin_round !== roundId)
                setTabs([
                  {
                    index: 1,
                    description: `1. ${t('round_schedule')}`,
                    component: () => (
                      <EditRoundSchedule
                        privilege={privilege}
                        round={round}
                        setRound={setRound}
                        isNewRound={props.location.state?.isNewRound}
                        roundId={res.results[0].round_sp_in_up_admin_round}
                      />
                    ),
                  },
                  {
                    index: 2,
                    description: `2. ${t('common_tasters')}`,
                    disabled: disabledTasters,
                    back: true,
                    next: true,
                    component: () => (
                      <EditRoundTasters
                        privilege={privilege}
                        round={round}
                        setRound={setRoundCallBack}
                        roundId={res.results[0].round_sp_in_up_admin_round}
                      />
                    ),
                  },
                  {
                    index: 3,
                    description: `3. ${t('common_samples')}`,
                    disabled: disabledSamples,
                    component: componentEditSample,
                    back: true,
                    allow: allow,
                    save:
                      round.uuid_round_type === '02095a80-6cc4-11ec-8d68-000000000000' ||
                        round.uuid_round_type === 'e4454b00-6950-11ed-bb18-000000000000'
                        ? false
                        : true,
                    tipsShow: round.uuid_round_type === '4ed984d9-021d-4d06-a992-991a24b3d7ce' ? 'ipTips' :
                      round.uuid_round_type === 'b8082a00-e1d1-11ec-97a3-000000000000' ? 'f5Tips' :
                        round.uuid_round_type === '43b11c76-043c-11eb-b18d-026fdc1cc8f2' ? 'f10Tips' : '',
                    onClickSave: async () => {
                      if (
                        round.uuid_round_type === '4ed984d9-021d-4d06-a992-991a24b3d7ce' &&
                        round.bol_mcs_tasting
                      ) {
                        const nowSamples = editSamplesRef.current.round.arr_samples ?? [];
                        const notChangeArr = nowSamples.filter(item => !item.bol_count_as_kpi && !item.isCountKPIControl && item.dat_kpi && round.bol_mcs_tasting);
                        if (notChangeArr.length > 0) {
                          setIsKPIBrandNotChange(notChangeArr)
                          return;
                        }
                      }
                      handleSubmit();
                    },
                  },
                ]);
            }
          } else {
            window.alert(res.message);
          }
        },
        error => {
          //console.log(error);
        },
      );
    }

    if (round.uuid_round) {
      setTabs([
        {
          index: 1,
          description: `1. ${t('round_schedule')}`,
          component: () => (
            <EditRoundSchedule
              privilege={privilege}
              round={round}
              isNewRound={props.location.state?.isNewRound}
              setRound={setRound}
              roundId={roundId}
            />
          ),
        },
        {
          index: 2,
          description: `2. ${t('common_tasters')}`,
          disabled: disabledTasters,
          back: true,
          next: true,
          component: () => (
            <EditRoundTasters
              privilege={privilege}
              round={round}
              setRound={setRound}
              roundId={roundId}
            />
          ),
        },
        {
          index: 3,
          description: `3. ${t('common_samples')}`,
          disabled: disabledSamples,
          component: componentEditSample,
          back: true,
          allow: allow,
          save:
            round.uuid_round_type === '02095a80-6cc4-11ec-8d68-000000000000' ||
              round.uuid_round_type === 'e4454b00-6950-11ed-bb18-000000000000'
              ? false
              : true,
          tipsShow: round.uuid_round_type === '4ed984d9-021d-4d06-a992-991a24b3d7ce' ? 'ipTips' :
            round.uuid_round_type === 'b8082a00-e1d1-11ec-97a3-000000000000' ? 'f5Tips' :
              round.uuid_round_type === '43b11c76-043c-11eb-b18d-026fdc1cc8f2' ? 'f10Tips' : '',
          onClickSave: async () => {
            if (
              round.uuid_round_type === '4ed984d9-021d-4d06-a992-991a24b3d7ce' &&
              round.bol_mcs_tasting
            ) {
              const nowSamples = editSamplesRef.current.round.arr_samples ?? [];
              const notChangeArr = nowSamples.filter(item => !item.bol_count_as_kpi && !item.isCountKPIControl && item.dat_kpi && round.bol_mcs_tasting);
              if (notChangeArr.length > 0) {
                setIsKPIBrandNotChange(notChangeArr)
                return;
              }
            }
            handleSubmit();
          },
        },
      ]);
    }

    if (round.uuid_round && round.arr_users) {
      let users = round.arr_users.filter(user => {
        return user.bol_taster;
      });
      users = users.map(user => {
        return {
          uuid_user: user.uuid_user,
          int_key_taster: user.bol_key_taster,
          bol_guest: user.bol_guest,
        };
      });
      roundService
        .updateRoundUsers({
          uuid_round: round.uuid_round,
          arr_users: users,
        })
        .then(
          res => {
            if (res.type === 'success' && res.results) {
              //history.goBack();
            } else {
              window.alert(res.message);
            }
          },
          error => {
            //console.log(error);
          },
        );
    }

    if (round.uuid_round && round.arr_samples) {
      let samplesTemp = [];
      if (round.uuid_round_type === 'be229b18-2eb4-11eb-ba1d-0229531a6f14') {
        // SET SAMPLES OK/NOK
        for (let i = 0; i < round.arr_samples.length; i++) {
          const sample = round.arr_samples[i];
          if (
            (sample.dat_del === null ||
              typeof sample.dat_del === 'undefined') &&
            sample.uuid_category_option !== null &&
            sample.uuid_category_option !== '' &&
            sample.uuid_category_option !== undefined
          ) {
            samplesTemp.push(sample);
          }
        }
      } else {
        samplesTemp = round.arr_samples;
      }

      samplesTemp = samplesTemp.map(sample => {
        sample.uuid_kpi_codes = round.uuid_kpi_codes;
        return sample;
      });
      if (
        round.uuid_round_type !== 'e4454b00-6950-11ed-bb18-000000000000'
        && round.uuid_round_type !== 'be229b18-2eb4-11eb-ba1d-0229531a6f14'
      ) {
        roundService
          .updateRoundSamples({
            uuid_round: round.uuid_round,
            arr_samples: samplesTemp,
          })
          .then(
            res => {
              if (res.type === 'success' && res.results) {
                IoEmit('addSample', {
                  updateSampleArr: [...addUpdateSample],
                  uuid_round: round.uuid_round,
                });
                setAddUpdateSample([]);
                //history.goBack();
              } else {
                window.alert(res.message);
              }
            },
            error => {
              //console.log(error);
            },
          );
      }
    }
  };
  const checkKPIMonth = (sample) => {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const { dat_kpi } = sample;
    const sampleMonth = new Date(dat_kpi?.slice(0, 10)).getMonth() + 1;
    const sampleYear = new Date(dat_kpi?.slice(0, 10)).getFullYear();
    return year === sampleYear && month === sampleMonth && round.bol_mcs_tasting;
  };
  const handleSubmit = async () => {
    await saveSamples();
    history.push({ pathname: '/list_rounds' });
  }

  const componentEditSample = () => {
    return round.uuid_round_type === 'be229b18-2eb4-11eb-ba1d-0229531a6f14' ? (
      //ok-nok
      <EditRoundCategories
        privilege={privilege}
        round={round}
        ref={editSamplesRef}
        setRound={setRound}
        state={props.location.state.values.arr_status}
        setAddUpdateSample={setAddUpdateSample}
      />
    ) : round.uuid_round_type === '02095a80-6cc4-11ec-8d68-000000000000' ? (
      //threshold
      <EditRoundSamplesThreshold
        privilege={privilege}
        round={round}
        setRound={setRound}
      />
    ) : round.uuid_round_type === '0d55fca0-e7a2-11ec-a8bc-000000000000' ? (
      // Flavor ID
      <EditRoundFlavor
        privilege={privilege}
        round={round}
        setAllow={setAllow}
        setRound={setRound}
        isNewRound={props.location.state.isNewRound}
      />
    ) : round.uuid_round_type === 'e4454b00-6950-11ed-bb18-000000000000' ? (
      <EditRoundTriangle
        privilege={privilege}
        round={round}
        setRound={setRound}
      ></EditRoundTriangle>
    ) : (
      //freshness or in-profile
      <EditRoundSamples
        privilege={privilege}
        round={round}
        ref={editSamplesRef}
        setRound={setRound}
        state={props.location.state.values.arr_status}
        isCopyRound={props.location.state.isCopyRound}
        setAddUpdateSample={setAddUpdateSample}
        sampleEditPrivilege={sampleEditPrivilege}
      />
    );
  };

  const saveSamples = () => {
    return new Promise(async (resolve) => {
      if (round.uuid_round_type === 'be229b18-2eb4-11eb-ba1d-0229531a6f14') {
        tabRef.current.setSaveLoading(true);
        const { bol_splitRound, splitRounds } = editSamplesRef.current
        const res = await roundService.listRoundCategoriesSamples(
          round.uuid_round,
        );
        if (res) {
          const NowArr = res.results;
          for (let i = 0; i < NowArr.length; i++) {
            if (
              (NowArr[i].uuid_category_option ===
                '32892408-ee44-11ed-ba07-0017fa02c1e1' ||
                NowArr[i].uuid_category_option ===
                '36907a60-ee44-11ed-ba08-0017fa02c1e1' ||
                NowArr[i].uuid_category_option ===
                '3c2f932a-ee44-11ed-ba09-0017fa02c1e1') &&
              NowArr[i].bol_over_aged_beer === '1' &&
              !NowArr[i].str_number_days_over_aged
            ) {
              window.alert(
                t('common_cannot_be_empty', {
                  field: `${t('common_str_number_days_over_aged')}`,
                }),
              );
              return;
            }
          }
          if (bol_splitRound) {
            const roundSaveRes = await roundService.updateRound(round);
            if (roundSaveRes) {
              if (splitRounds.length > 0) {
                splitRounds.map((round) => {
                  round.str_template_name = round.str_template_name
                })
                const res = await roundService.saveSplitRoundSamples({
                  arr_round_samples: splitRounds,
                  uuid_round: round.uuid_round,
                })
                if (res) {
                  splitRounds.map((round) => {
                    round.arr_samples.map((item) => {
                      item.arr_brew_numbers && item.arr_brew_numbers?.map(BrewNumber => {
                        sampleService.updateBrewNumber(BrewNumber)
                      })
                    })
                  })
                  tabRef.current.setSaveLoading(false);
                  resolve(true);
                }
              }
            }
          } else {
            await roundService.resetSplitRound({
              uuid_round: round.uuid_round,
              uuid_split_round: splitRounds.length > 0 ? splitRounds[0].uuid_split_round : ''
            })
            const roundRes = await roundService.updateRound(round);
            if (roundRes) {
              if (round.arr_samples?.length > 0) {
                const sampleRes = await roundService
                  .updateRoundSamples({
                    uuid_round: round.uuid_round,
                    arr_samples: round.arr_samples ?? [],
                  })
                if (sampleRes) {
                  round.arr_samples?.map((item) => {
                    item.arr_brew_numbers && item.arr_brew_numbers?.map(BrewNumber => {
                      sampleService.updateBrewNumber(BrewNumber)
                    })
                  })
                }
              }
              tabRef.current.setSaveLoading(false);
              resolve(true);
            }
          }
        }
      }
      if (round.uuid_round_type === '4ed984d9-021d-4d06-a992-991a24b3d7ce'
        || round.uuid_round_type === 'b8082a00-e1d1-11ec-97a3-000000000000'
        || round.uuid_round_type === '43b11c76-043c-11eb-b18d-026fdc1cc8f2') {
        tabRef.current.setSaveLoading(true);
        const roundNew = await editSamplesRef.current.saveSamples()
        if (roundNew) {
          setRound(roundNew);
        }
        tabRef.current.setSaveLoading(false);
      }
      resolve(true);
    });
  }
  const handleSampleName = (sample) => {
    const yearDatProd = sample.dat_production?.substring(0, 4);
    const monthDatProd = t(
      `month_${sample.dat_production?.substring(5, 7) - 1}`,
    );
    const dayDatProd = sample.dat_production?.substring(8, 10);

    const yearDatExp = sample.dat_expire?.substring(0, 4);
    const monthDatExp = t(
      `month_${sample.dat_expire?.substring(5, 7) - 1}`,
    );
    const datDatExp = sample.dat_expire?.substring(8, 10);

    return `${sample.str_site_name} - Pr. ${sample.dat_production
      ? dayDatProd + '/' + monthDatProd + '/' + yearDatProd
      : 'N/A'
      } - Ex. ${sample.dat_expire
        ? datDatExp + '/' + monthDatExp + '/' + yearDatExp
        : 'N/A'
      } - ${sample.str_batch ? sample.str_batch : 'N/A'}`;
  }
  return (
    <div className={editRoundStyle.pageContainer}>
      <Label
        label={
          props.location?.state?.isNewRound
            ? t('round_new_round')
            : t('round_edit_round')
        }
        bold
        neutral
      />

      <Tab
        tabs={tabs}
        selectedTabIndex={1}
        ref={tabRef}
        onClick={(selectIndex) => {
          if (selectIndex === 3) {
            saveSamples(false);
          }
        }}
      />
      <Modal
        className={editRoundStyle.attention}
        show={isKPIBrandNotChange.length > 0}
        style={{ height: '100%' }}
      >
        <h3>{t('if_count_as_kpi')}:</h3>
        <div className={editRoundStyle.isKPIBrandNotChangeContent}>
          {

            isKPIBrandNotChange.map(item => {
              return (
                <p className={editRoundStyle.isKPIBrandNotChangeItem}>
                  No.{item.int_order_app + 1}: {item.str_brand_name}, {handleSampleName(item)}
                </p>
              )
            })
          }
        </div>

        <div className={editRoundStyle.buttonContainer}>
          <Button
            className={editRoundStyle.confirmButton}
            label={t('ignore_submit')}
            margin="0px 10px 0px 0px"
            confirm
            onClick={handleSubmit}
          ></Button>

          <Button
            className={editRoundStyle.cancelButton}
            label={t('common_back')}
            remove
            onClick={() => {
              setIsKPIBrandNotChange([])
            }}
          ></Button>
        </div>
      </Modal>
    </div>
  );
};

export default EditRound;

