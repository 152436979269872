import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useRef,
} from 'react';
import tastingOkNokStyle from './tastingOkNok.module.scss';
import { useHistory } from 'react-router-dom';
import {
  Modal,
  CardRow,
  CardColumn,
  Button,
  ListAccordion,
  Text,
  ButtonList,
  AttributesCategory,
  Label,
  NotificationBar,
  IntensityScale,
} from '../../../components';
import { useTranslation } from 'react-i18next';
import { useTasting } from '../../../contexts/Tasting';
import { useUser } from '../../../contexts/User';
import { X, ArrowLeft, HelpCircle } from 'react-feather';
import _ from 'lodash';
import RoundAppService from '../../services/rounds/roundApp.service';
import SampleAppService from '../../services/samples/sampleApp.service';
import uuid from 'react-uuid';
import configs from '../../../configs';
import { IoEmit, GetSocket } from '../../../utils/socketIo';
import UserService from '../../services/users/user.service';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import checkMark from '../../../assets/checkMark.png';

const fuzzysort = require('fuzzysort');

const TastingOkNok = props => {
  const { t } = useTranslation();
  const [round, setRound] = useState();
  const [attrs, setAttrs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [comment, setComment] = useState(null);
  const { user } = useUser();
  const [currentCategory, setCurrentCategory] = useState();
  const [currentCategoryPosition, setCurrentCategoryPosition] = useState(0);
  const roundAppService = new RoundAppService();
  const sampleAppService = new SampleAppService();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showWarningEmptySamples, setShowWarningEmptySamples] = useState(false);
  const bolScore = props.location.state.bol_score;
  const [notificationArr, setNotificationArr] = useState([]);
  const bubbleArr = useRef([]);
  const nottificationRef = useRef({});
  const userService = new UserService();
  const [isContinuous, setIsContinuous] = useState(false);
  const timeNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  const [isOkForAll, setIsOKForAll] = useState(0);
  const [showSampleDetails, setShowSampleDetails] = useState(props.location.state.round?.bol_hide_sample_details ? false : true);

  useEffect(() => {
    userService
      .getUserContinuousTyping()
      .then(res => {
        if (res.results.length == 0) {
          setIsContinuous(false);
        } else {
          setIsContinuous(res.results[0].bol_continuous_type);
        }
      })
      .catch(error => { });
  }, []);

  useEffect(() => {
    bubbleArr.current = notificationArr;
  }, [notificationArr]);

  const {
    taste,
    updateFinishRound,
    handleSample,
    sample,
    addCommentsCategory,
    removeCommentsCategory,
    loadTaste,
  } = useTasting();

  useEffect(() => {
    let timer = setTimeout(() => {
      GetSocket().on('addUpdateSample', async function (res) {
        if (window.location.pathname === '/tasting_ok_nok') {
          const resUuidRound = res.uuid_round ? res.uuid_round : '';
          if (resUuidRound === props.location.state.round.uuid_round) {
            let sampleNameStr = '';
            for (let i = 0; i < res.updateSampleArr.length; i++) {
              const lastDom = i + 1 !== res.updateSampleArr.length ? ', ' : '';
              sampleNameStr +=
                res.updateSampleArr[i].str_name_category_sample + lastDom;
            }
            if (!!sampleNameStr) {
              setNotificationArr([...bubbleArr.current]);
              loadTaste(props.location.state.round);
            }

            if (
              nottificationRef?.current &&
              bubbleArr.current &&
              bubbleArr.current.length > 4
            ) {
              nottificationRef.current?.scrollBottom &&
                nottificationRef.current.scrollBottom();
            }
          }
        }
      });
    });
    return () => {
      clearTimeout(timer);
    }
  }, [GetSocket]);

  useEffect(() => {
    getShowSampleDetails().then(() => {
      roundAppService
        .listDegrees()
        .then(res => {
          setDegrees(res);
        })
        .catch(error => {
          //console.log(error);
        });

      roundAppService
        .listComments()
        .then(res => {
          const reduceArr = res.reduce((accumulator, currentValue) => {
            const existingObject = accumulator.find(
              obj => obj.uuid_comment === currentValue.uuid_comment,
            );
            if (!existingObject) {
              accumulator.push(currentValue);
            }
            return accumulator;
          }, []);
          setAttrs(reduceArr);
        })
        .catch(error => {
          //console.log(error);
        });

      roundAppService
        .listCommentsCategory()
        .then(res => {
          setCategories(res);
        })
        .catch(error => {
          //console.log(error);
        });
    });

    return () => {
      if (sessionStorage.getItem('editOknok'))
        sessionStorage.removeItem('editOknok');
    };
  }, []);

  useEffect(() => {
    setRound(taste);
    if (props.location.state?.edit) {
      const obj = taste?.categories?.filter(item => {
        return props.location.state.sample.uuid_category === item.uuid_category;
      })[0];
      setCurrentCategory(obj);
      handleSample(obj);
    } else {
      setCurrentCategory(
        taste?.categories[currentCategoryPosition].samples
          ? taste?.categories[currentCategoryPosition] :
          {
            ...taste?.categories[currentCategoryPosition],
            samples: currentCategory?.samples
          });
      handleSample(
        taste?.categories[currentCategoryPosition]
          ? taste?.categories[currentCategoryPosition]
          : null,
      );
    }
  }, [taste, sample, currentCategoryPosition, handleSample]);

  const [sampleFirstOder, setSampleFirstOder] = useState(1);

  useEffect(() => {
    const filterIndex = round?.categories.findIndex(
      item => item.uuid_category === currentCategory?.uuid_category,
    );
    let firstIndex = 1;
    if (filterIndex !== 0) {
      for (let i = 0; i < filterIndex; i++) {
        firstIndex += round?.categories[i]?.samples.length;
      }
    }
    setSampleFirstOder(firstIndex);
  }, [currentCategory]);

  const handleTypingChange = () => {
    setIsContinuous(!isContinuous);
    userService.updateUserContinuousTyping({
      bol_continuous_type: !isContinuous,
    });
  };

  const handleSelectCategory = (uuid_category, action) => {
    setIsOKForAll(0);
    let position = round.categories.map((category, key) => {
      if (category.uuid_category === uuid_category) {
        return key;
      }
    });

    if (checkVoteNokHasNoCommentsCurrentCategory()) {
      window.alert(t('common_need_explain_nok'));
      return false;
    }

    position = position.find(position => position !== undefined);
    let lenghPositions = round.categories.length;

    switch (action) {
      case 'next':
        position++;
        break;
      case 'back':
        position--;
        break;
      default:
        break;
    }
    if (position < lenghPositions && position > -1) {
      handleSample(round.categories[position]).then(res => {
        setCurrentCategoryPosition(position);
        setCurrentCategory(round.categories[position]);
      });
    }
    setShowComments(null);
  };

  const removeNeedAddComment = uuid_sample => {
    let currentCategoryTemp = currentCategory;

    currentCategoryTemp.samples = currentCategoryTemp.samples.map(
      sampleTemp => {
        if (sampleTemp.uuid_sample === uuid_sample) {
          delete sampleTemp.needAddComment;
        }

        return sampleTemp;
      },
    );

    setCurrentCategory({ ...currentCategoryTemp });
  };

  const checkVoteNokHasNoCommentsCurrentCategory = () => {
    const currentCategoryTemp = currentCategory;

    const hasNoCommentNok = currentCategoryTemp.samples.find(sample => {
      const taster = sample.tasters.find(
        taster => taster.uuid_user === user.uuid_user,
      );
      return taster && taster.bol_ok === 0 && !taster.arr_comments?.length;
    });

    return hasNoCommentNok ? hasNoCommentNok : false;
  };

  const checkVoteNokHasNoCommentsCurrentCategoryVoting = useCallback(
    res => {
      return new Promise((resolve, reject) => {
        let currentCategoryTemp = res ?? [];
        let needAddCommentReturn = false;

        currentCategoryTemp.samples = currentCategoryTemp.samples?.map(
          sample => {
            let taster = sample.tasters.find(
              taster => taster.uuid_user === user.uuid_user,
            );
            if (taster?.bol_ok === 0 && _.isEmpty(taster.str_comment)) {
              needAddCommentReturn = true;
              let sampleTemp = {
                ...sample,
                needAddComment: true,
              };

              return sampleTemp;
            }

            if (sample.hasOwnProperty('needAddComment')) {
              delete sample.needAddComment;
            }

            return sample;
          },
        );

        resolve({
          result: needAddCommentReturn,
          data: currentCategoryTemp,
        });
      });
    },
    [user.uuid_user],
  );

  const handleSubmit = () => {
    roundAppService.updateFinish(round, timeNow).then(
      res => {
        updateFinishRound();
        if (res.type === 'success') {
          IoEmit('finishRound', {
            ...round,
            user_name: user.str_name,
          });
          history.replace({
            pathname: `/tasting_discussion_ok_nok`,
            state: {
              from: 'discussion',
              round: props.location.state.round,
              bol_score: bolScore,
            },
          });
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
  };

  const handleValidate = () => {
    roundAppService.getRound({ uuid_round: round.uuid_round }).then(res => {
      if (res.results && res.results[0]?.bol_finished) {
        if (checkVoteNokHasNoCommentsCurrentCategory()) {
          window.alert(t('common_need_explain_nok'));
          return false;
        } else {
          history.replace({
            pathname: `/tasting_discussion_ok_nok`,
            state: {
              from: 'discussion',
              round: props.location.state.round,
              bol_score: bolScore,
            },
          });
        }
      } else {
        if (checkVoteNokHasNoCommentsCurrentCategory()) {
          window.alert(t('common_need_explain_nok'));
          return false;
        }
        checkHasEmptyVoting().then(res => {
          if (res) {
            setShowWarningEmptySamples(res);
          } else {
            handleSubmit();
          }
        });
      }
    });
  };

  const handleValidateWarning = useCallback(
    resp => {
      checkVoteNokHasNoCommentsCurrentCategoryVoting(resp).then(res => {
        if (res.data) {
          setCurrentCategory({ ...res.data });
        }
      });
    },
    [checkVoteNokHasNoCommentsCurrentCategoryVoting],
  );

  const checkHasEmptyVoting = () => {
    return new Promise((resolve, reject) => {
      taste.categories.map(category => {
        category.samples.map(sample => {
          let taster = sample.tasters.find(
            taster => taster.uuid_user === user.uuid_user,
          );
          if (!taster || taster.dat_sample === '' || taster.bol_ok === null) {
            resolve(true);
          }
        });
      });

      resolve(false);
    });
  };

  const handleClickDegrees = e => {
    if (e.target.value) {
      let degreeSelected = degrees.filter(
        degree => degree.str_name === e.target.value,
      );
      setComment({
        str_degree: degreeSelected[0].str_name,
        uuid_degree: degreeSelected[0].uuid_degree,
      });
    }
  };

  const handleClickAttrs = e => {
    if (e.target.value) {
      const res = {
        target: {
          value: attrs.filter(attr => attr.str_name === e.target.value)[0],
          name: 'comments',
        },
      };
      handleSelectComments(res);
    }
  };

  const handleSelectComments = evt => {
    const currentSample = currentCategory?.samples?.find(
      x => x.uuid_sample === showComments,
    );
    const currentSampleTaster =
      currentSample?.tasters?.find(o => o.uuid_user === user.uuid_user) ||
      user.uuid_user;
    const uuid_sample = currentSample?.uuid_sample;
    let selectedComment = evt.target.value;
    if (typeof evt.target.value == 'string' && !evt.target.value.trim()) return;
    if (evt.target && selectedComment) {
      if (
        comment ||
        selectedComment.uuid_comment ||
        typeof selectedComment === 'string'
      ) {
        if (
          (selectedComment.str_name && selectedComment.uuid_comment) ||
          typeof selectedComment === 'string'
        ) {
          let hasComment = currentSampleTaster?.arr_comments?.find(
            commentFilter =>
              commentFilter.uuid_comment === selectedComment.uuid_comment ||
              (!comment &&
                commentFilter.uuid_comment === selectedComment.uuid_comment) ||
              (!comment && commentFilter.str_comment === selectedComment) ||
              (commentFilter.str_comment === selectedComment &&
                comment?.uuid_degree === commentFilter.uuid_degree),
          );

          if (hasComment) {
            window.alert(t('you_can_not_add_duplicate_attributes'));
            return false;
          } else {
            let uuid_answer = uuid();
            let newComment = {
              ...comment,
              str_comment: selectedComment.str_name
                ? selectedComment.str_name
                : selectedComment,
              uuid_comment: selectedComment.uuid_comment
                ? selectedComment.uuid_comment
                : uuid(),
              uuid_round: round.uuid_round,
              uuid_sample: uuid_sample,
              uuid_answers_rounds_users_samples_categories_comments:
                uuid_answer,
              int_order: currentSampleTaster?.arr_comments?.length + 1 || 0,
            };

            if (!newComment.uuid_degree && !newComment.str_degree) {
              newComment.uuid_degree = null;
              newComment.str_degree = null;
            }

            if (typeof selectedComment === 'string') {
              newComment.uuid_comment = null;
            }

            addCommentsCategory(newComment, uuid_sample, user.uuid_user);
            sampleAppService
              .saveTastingOkNokComment(newComment)
              .then(() => { })
              .catch(() => {
                alert(t('common_error'));
                removeCommentsCategory(
                  newComment.uuid_answers_rounds_users_samples_categories_comments,
                  uuid_sample,
                  user.uuid_user,
                );
              });
          }
          setComment(null);
        }
      } else {
        if (selectedComment.str_name && selectedComment.uuid_degree) {
          setComment({
            str_degree: selectedComment.str_name,
            uuid_degree: selectedComment.uuid_degree,
          });
        }
      }
    }
  };

  const handleSearchComments = (text, sample) => {
    if (showComments !== sample) {
      setShowComments(sample);
    }
    return new Promise((resolve, reject) => {
      let searchArray = [];
      const keyword = text.toString().trim().toLowerCase();
      if (!comment?.str_degree && keyword) {
        searchArray = [...degrees, ...attrs];
      } else {
        searchArray = [...attrs];
      }

      let returnedArray = [];

      returnedArray = fuzzysort
        .go(keyword, searchArray, { key: 'str_name' })
        .map(a => a.obj);

      if (user.str_language !== 'zh') {
        if (keyword.length <= 2) {
          returnedArray = [];
        }
      }

      if (!comment?.str_degree && keyword.length == 0) {
        resolve([]);
      } else {
        resolve(returnedArray);
      }
    });
  };

  const handleRemoveComment = (rmComment, uuidSample) => {
    if (rmComment.uuid_answers_rounds_users_samples_categories_comments) {
      sampleAppService.deleteCommentOkNok(
        rmComment.uuid_answers_rounds_users_samples_categories_comments,
      );
      removeCommentsCategory(
        rmComment.uuid_answers_rounds_users_samples_categories_comments,
        uuidSample,
        user.uuid_user,
      );
    }
  };

  const handleBack = () => {
    if (sessionStorage.getItem('editOknok')) {
      history.goBack();
      return;
    }
    history.replace({
      pathname: '/',
      state: {
        from: 'discussion',
        round: props.location.state.round,
      },
    });
  };

  const handleOkForAll = () => {
    setIsOKForAll(1);
  };

  const getShowSampleDetails = () => {
    return new Promise(async (resolve, reject) => {
      if (history.location.state.edit === "edit") {
        setShowSampleDetails(true)
        resolve(true);
      } else {
        const res = await roundAppService.getShowSampleDetails(props.location.state.round.uuid_round);
        if (res.type === 'success') {
          setShowSampleDetails(
            res.results[0].round_sp_se_round_hide_sample_details,
          );
          resolve(true);
        }
      }
    })

  };

  return (
    <Fragment>
      <Modal
        className={tastingOkNokStyle.Modal}
        show={showModal || showWarningEmptySamples}
      >
        {showWarningEmptySamples ? (
          <Fragment>
            <CardRow className={tastingOkNokStyle.cardRowModal}>
              <Text className={tastingOkNokStyle.h1Title}>
                {t('review_warning_sample_title')}
              </Text>
            </CardRow>
            <CardRow className={tastingOkNokStyle.cardRowModal}>
              <Text className={tastingOkNokStyle.h3Title}>
                {t('review_warning_sample_description')}
              </Text>
            </CardRow>
            <CardRow
              className={
                tastingOkNokStyle.buttonsWrapper +
                ' ' +
                tastingOkNokStyle.cardRowModal
              }
            >
              <Button
                label={t('review_warning_sample_review')}
                remove
                onClick={() => setShowWarningEmptySamples(false)}
              ></Button>
              <Button
                label={t('review_warning_sample_ok')}
                confirm
                onClick={() => {
                  setShowWarningEmptySamples(false);
                  handleSubmit();
                }}
              ></Button>
            </CardRow>
          </Fragment>
        ) : (
          ''
        )}
        {showModal ? (
          <Fragment>
            <CardRow justifyContent="flex-end">
              <Button transparent onClick={() => setShowModal(!showModal)}>
                {' '}
                <X />
              </Button>
            </CardRow>
            <CardRow
              justifyContent="center"
              style={{
                width: '100%',
                maxHeight: '405px',
                paddingBottom: '40px',
              }}
            >
              <img
                src={`${configs.file_api_url}/v1/oknook/${currentCategory?.str_name_category_en}.png`}
                style={{ width: '90%' }}
              />
            </CardRow>
          </Fragment>
        ) : (
          ''
        )}
      </Modal>

      <CardRow transparent justifyContent="flex-end">
        <CardColumn
          className={tastingOkNokStyle.header}
          style={{ justifyContent: 'flex-start' }}
        >
          <Button
            label={''}
            onClick={() => handleBack()}
            className={tastingOkNokStyle.back}
          >
            <ArrowLeft />
          </Button>
        </CardColumn>
        <HelpCircle
          data-tip={t('continuous_typing_tips')}
          style={{ color: '#B11F24', marginTop: 14, marginRight: 5 }}
          size="20"
          data-for="continuous_typing_tips"
        />
        <ReactTooltip
          id="continuous_typing_tips"
          effect="solid"
          place="bottom"
          type="warning"
          style={{ whiteSpace: 'normal' }}
        />
        <Label
          style={{
            whiteSpace: 'nowrap',
            marginRight: '5px',
            marginTop: '17px',
          }}
          label={t('continuous_typing')}
          bold
        ></Label>
        <input
          type="checkbox"
          style={{ marginTop: '15px', marginRight: '15px' }}
          checked={isContinuous}
          onChange={() => {
            handleTypingChange();
          }}
          className={tastingOkNokStyle.checke}
        />
        <Button
          margin="10px 15px"
          bold
          label={t('tasting_help')}
          className={tastingOkNokStyle.btn}
          onClick={() => setShowModal(!showModal)}
        />
        <Button
          bold
          margin="10px 15px"
          label={t('tasting_finish_sample')}
          className={tastingOkNokStyle.btn + ' ' + tastingOkNokStyle.btnFinish}
          onClick={() => handleValidate()}
          disabled={
            currentCategoryPosition + 1 === round?.categories.length ||
              props.location.state?.edit
              ? false
              : true
          }
        />
      </CardRow>
      {props.location.state?.edit ? null : (
        <CardRow transparent>
          <CardColumn>
            <ul className={tastingOkNokStyle.steps}>
              {round?.categories.map((category, index) => {
                let objClass =
                  currentCategory?.uuid_category === category.uuid_category
                    ? tastingOkNokStyle.active + ' '
                    : '';
                let hasVoting = category.samples.filter(sample =>
                  sample.tasters.find(
                    taster => taster.uuid_user === user.uuid_user,
                  ),
                );
                if (!_.isEmpty(hasVoting)) {
                  objClass += tastingOkNokStyle.done;
                }
                let firstIndex = 1;
                let endIndex = category.samples.length;
                if (index !== 0) {
                  for (let i = 0; i < index; i++) {
                    firstIndex += round?.categories[i]?.samples.length;
                    endIndex += round?.categories[i]?.samples.length;
                  }
                }
                return (
                  <li
                    key={category.uuid_category}
                    className={objClass}
                    onClick={() =>
                      handleSelectCategory(category.uuid_category, 'goTo')
                    }
                  >
                    <i />
                    <span style={{ marginTop: '2px', lineHeight: '14px' }}>
                      {category.str_name}
                      <br />
                      <p style={{ marginTop: '2px' }}>
                        No.
                        {category.samples.length === 1
                          ? `${firstIndex}`
                          : `${firstIndex}-${endIndex}`}
                      </p>
                    </span>
                  </li>
                );
              })}
            </ul>
          </CardColumn>
        </CardRow>
      )}
      <CardRow transparent>
        <Button
          className={tastingOkNokStyle.okForAll}
          label={t('ok_for_all')}
          style={{ marginLeft: '14px' }}
          onClick={() => handleOkForAll()}
          blue
        >
          <img src={checkMark} style={{ marginRight: '6px' }} />
        </Button>
      </CardRow>
      <div>
        <div
          style={{
            display: 'flex',
            overflowX: 'auto',
            scrollBehavior: 'smooth',
          }}
        >
          {console.log(currentCategory)}
          {props.location.state?.edit
            ? currentCategory?.samples?.map((sample, index) => {
              if (
                sample.uuid_sample === props.location.state.sample.uuid_sample
              ) {
                return (
                  <ListAccordion
                    autoFocus={isContinuous}
                    key={sample.uuid_sample}
                    className={`${tastingOkNokStyle.samples} ${sample.needAddComment
                      ? tastingOkNokStyle.needAddComment
                      : ''
                      }`}
                    int_order={sampleFirstOder + index}
                    sample={sample}
                    isOpenDropdown={sample.needAddComment ? true : false}
                    funcFocusOut={
                      sample.needAddComment ? removeNeedAddComment : null
                    }
                    currentCategoryPosition={currentCategoryPosition}
                    handleShowComments={e => setShowComments(e)}
                    showComments={showComments}
                    comment={comment}
                    handleSelectComments={handleSelectComments}
                    handleSearchComments={handleSearchComments}
                    handleRemoveComment={handleRemoveComment}
                    handleRemoveDegree={() => setComment(null)}
                    bolScore={bolScore}
                    isOkForAll={isOkForAll}
                    setIsOKForAll={setIsOKForAll}
                    showSampleDetails={showSampleDetails}
                  />
                );
              }

              return null;
            })
            : currentCategory?.samples?.map((sample, index) => {
              return (
                <ListAccordion
                  autoFocus={isContinuous}
                  key={sample.uuid_sample}
                  className={`${tastingOkNokStyle.samples} ${sample.needAddComment
                    ? tastingOkNokStyle.needAddComment
                    : ''
                    }`}
                  sample={sample}
                  int_order={sampleFirstOder + index}
                  isOpenDropdown={sample.needAddComment ? true : false}
                  funcFocusOut={
                    sample.needAddComment ? removeNeedAddComment : null
                  }
                  currentCategoryPosition={currentCategoryPosition}
                  handleValidateWarning={handleValidateWarning}
                  handleShowComments={e => setShowComments(e)}
                  showComments={showComments}
                  comment={comment}
                  handleSelectComments={handleSelectComments}
                  handleSearchComments={handleSearchComments}
                  handleRemoveComment={handleRemoveComment}
                  handleRemoveDegree={() => setComment(null)}
                  bolScore={bolScore ?? false}
                  isOkForAll={isOkForAll}
                  setIsOKForAll={setIsOKForAll}
                  showSampleDetails={showSampleDetails}
                />
              );
            })}
        </div>

        {showComments && (
          <div>
            {/*DEGREES*/}
            <CardRow>
              <CardColumn>
                <IntensityScale />
              </CardColumn>
            </CardRow>
            <CardRow>
              <CardColumn>
                <ButtonList
                  style={{ width: '120px' }}
                  zIndex={0}
                  list={degrees}
                  valueField="str_name"
                  labelField="str_name"
                  onClick={e => handleClickDegrees(e)}
                  comment={comment?.str_degree}
                //comment={null}
                ></ButtonList>
              </CardColumn>
            </CardRow>

            {/*DEGREES*/}

            {/*ATTRS*/}
            <CardRow>
              <CardColumn>
                <Label label={t('common_attributes')} bold></Label>
              </CardColumn>
            </CardRow>
            <CardRow>
              <CardColumn>
                <AttributesCategory
                  list={attrs}
                  valueField="str_name"
                  labelField="str_name"
                  onClick={handleClickAttrs}
                  categories={categories}
                  freshness={false}
                ></AttributesCategory>
              </CardColumn>
            </CardRow>
            {/*ATTRS*/}
          </div>
        )}
        {props.location.state?.edit ? null : (
          <CardRow transparent justifyContent="space-between">
            <Button
              margin="10px 0px"
              bold
              label={t('common_back')}
              className={tastingOkNokStyle.btn}
              style={{
                backgroundColor: '#999999',
              }}
              disabled={currentCategoryPosition === 0 ? true : false}
              onClick={() =>
                handleSelectCategory(currentCategory?.uuid_category, 'back')
              }
            />
            <Button
              bold
              margin="10px 0px"
              label={t('common_next')}
              className={tastingOkNokStyle.btn}
              disabled={
                currentCategoryPosition + 1 === round?.categories.length
                  ? true
                  : false
              }
              onClick={() =>
                handleSelectCategory(currentCategory?.uuid_category, 'next')
              }
            />
          </CardRow>
        )}
      </div>
      <NotificationBar
        ref={nottificationRef}
        right="5px"
        notificationArr={notificationArr}
        setNotificationArr={row => {
          setNotificationArr([...row]);
        }}
      />
    </Fragment>
  );
};

export default TastingOkNok;
