import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import loginSSOStyle from './loginSSO.module.scss';
import logo from '../../logo.png';
import Spinner from '../../components/spinner/Spinner';
import { useUser } from '../../contexts/User';
import configs from '../../configs';
import AppService from '../../app.service';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import { Button, DropdownField } from '../../components';
import UserService from '../services/users/user.service';

const LoginAuth = () => {
  const { t } = useTranslation();
  const appService = new AppService();
  const userService = new UserService();
  const { pathname, search } = window.location;
  const { i18n } = useTranslation();
  const loginAuth = pathname.indexOf('/login_auth') > -1;
  const [loading, setLoading] = useState(false);
  const { handleUser, handleLogged } = useUser();
  const urlParts = pathname.split('&&');
  const key = urlParts[0].split('/')[2] ?? 'secretKey';
  const decrypt = (encryptedText) => {
    const bytes = CryptoJS.AES.decrypt(encryptedText, key);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return decrypted;
  }
  const [uuid_round, setUuid_round] = useState(null);
  const [uuid_user, setUuid_user] = useState(null);
  const dat_now_time = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  const [competitionUsers, setCompetitionUsers] = useState([]);
  const [roundID, setRoundID] = useState(null);
  const [roundName, setRoundName] = useState(null);


  const handleLogin = () => {
    setLoading(true);
    appService.NonAbiLogin({ uuid_round, uuid_user, dat_now_time }).then(res => {
      if (res.type === 'success' && res.results) {
        let user = {
          ...res.results[0],
          currentTab: 'menu_tasting_session',
          currentMenu: 'menu_tasting_session',
          showMenu: false,
          nonABI: true,
          uuid_round,
        };
        handleUser(user);
        handleLogged();
        i18n.changeLanguage(res.results[0].str_language);
      } else {
        window.alert(res.message);
        setLoading(false);
      }
      window.location.replace('/')
    }, err => {
      setLoading(false);
    });
  };

  const getCompetitionUsers = async (uuid_round) => {
    const res = await appService.getUserCompetitionRound(uuid_round);
    if (res.results) {
      setCompetitionUsers(res.results);
    }
  }

  useEffect(() => {
    if (loginAuth) {
      setUuid_round(decrypt(urlParts[urlParts.length - 2]) ?? '');
      setUuid_user(decrypt(urlParts[urlParts.length - 1]) ?? '');
      setTimeout(() => {
        setLoading(true);
        handleLogin();
      }, 300);
    } else {
      const params = search.slice(1).split('&&');
      setUuid_round(decrypt(params[0]));
      setRoundID(decrypt(params[1]));
      setRoundName(decrypt(params[2]));
      setTimeout(() => {
        getCompetitionUsers(decrypt(params[0]));
      }, 200);
    }
  }, [])
  const handleCompetitionLogin = () => {
    setLoading(true);
    appService.CompetitionLogin({ uuid_round, uuid_user, dat_now_time }).then(res => {
      if (res.type === 'success' && res.results) {
        let user = {
          ...res.results[0],
          currentTab: 'menu_tasting_session',
          currentMenu: 'menu_tasting_session',
          showMenu: false,
          nonABI: true,
          uuid_round,
        };
        handleUser(user);
        handleLogged();
        i18n.changeLanguage(res.results[0].str_language);
      } else {
        window.alert(res.message);
        setLoading(false);
      }
      window.location.replace('/')
    }, err => {
      setLoading(false);
    });
  }
  const handleChange = (evt) => {
    const value = evt.target.value;
    setUuid_user(value);
  };

  return (
    <div className={loginSSOStyle.loginContainer}>
      <div className={loginSSOStyle.loginBox}>
        <div className={loginSSOStyle.loginLogo}>
          <img src={logo} alt="ABInBev" height="50" />
        </div>

        {loading ? (
          <Spinner />
        ) : loginAuth ? (
          <p style={{ color: 'red' }}>This link has expired</p>
        ) : (
          <div className={loginSSOStyle.loginForm}>
            <div className={loginSSOStyle.Title}>
              <h3>{roundID}</h3>
              <h3>{roundName}</h3>
            </div>

            <DropdownField
              name="bol_mcs_tasting"
              options={competitionUsers}
              label={t('please_select_taster')}
              valueField="uuid_user"
              labelField="str_name"
              onChange={handleChange}
              value={uuid_user}
              placeholder={'Select Name'}
            />
            <Button disabled={!uuid_user} label="Login" onClick={handleCompetitionLogin} confirm />
          </div>
        )}
        {process.env.REACT_APP_STAGE !== 'prod' ? (
          <div
            className={loginSSOStyle.templateUser}
            style={{ color: '#ff6a12', }}
          >
            TEST ENVIRONMENT &nbsp; v<b>{configs.val_version}</b>
          </div>
        ) : (
          <div
            className={loginSSOStyle.templateUser}
            style={{ color: '#ff6a12' }}
          >
            v<b>{configs.val_version}</b>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginAuth;