import React, { useEffect, useState } from 'react';
import editEmailConfigurationStyle from './editEmailConfiguration.module.scss';
import { useHistory } from 'react-router-dom';
import {
  Label,
  Card,
  CardRow,
  DropdownField,
  CardColumn,
  Button,
} from '../../../components';
import EditEmailConfigurationTasters from '../editEmailConfigurationTasters/EditEmailConfigurationTasters';
import EditEmailConfigurationUsers from '../editEmailConfigurationUsers/EditEmailConfigurationUsers';
import EmailService from '../../services/email/email.service';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../contexts/User';

const EditEmailConfiguration = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [group, setGroup] = useState({
    ...props.location.state.values,
  });
  const [privilege, setPrivilege] = useState([]);
  const [emailTypeList, setEmailTypeList] = useState([]);
  const { user } = useUser();
  const emailService = new EmailService();

  useEffect(() => {
    setPrivilege(props.location.state.privilege);
    if (props.location.state.isNewGroup) {
      setGroup(prevState => {
        return {
          ...prevState,
          uuid_zone: user.uuid_zone,
          uuid_country: user.uuid_country,
          uuid_site: user.uuid_site,
        };
      });
    }
    emailService.listEmailType().then(res => {
      if (res.results.length) {
        setEmailTypeList(res.results);
      }
    })
  }, []);

  useEffect(() => {
    const {
      uuid_email_group,
      uuid_email_type,
      uuid_site,
      uuid_country,
      uuid_zone,
    } = group
    if (!!uuid_email_type && !!uuid_site && !!uuid_country && !!uuid_zone) {
      emailService.checkEmailGroup({
        uuid_email_type,
        uuid_site,
        uuid_country,
        uuid_zone,
        uuid_email_group,
      }).then(res => {
        if (res.results[0].bol_exist_email_group !== 0) {
          alert(t('the_current_distribution_type_already_exists'))
          setGroup((prev => {
            return {
              ...prev, uuid_email_type: null
            }
          }))
        }
      })
    }
  }, [group])

  const handleSave = () => {
    if (!group.uuid_email_type?.trim()) {
      return alert(t('distribution_type_message_empty'));
    }
    if (!group.uuid_zone) {
      return alert(t('common_message_zone_empty'));
    }
    if (!group.uuid_country) {
      return alert(t('common_message_country_empty'));
    }
    if (!group.uuid_site) {
      return alert(t('common_message_site_empty'));
    }
    if (!group.arr_users?.length) {
      return alert(t('group_no_users_message'));
    }
    if (group.arr_users.filter(item => item.bol_cc_recipient === false).length === 0) {
      return alert(t('group_no_recipients_message'));
    }



    emailService.updateEmailConfiguration(group).then(
      res => {
        if (res.type === 'success' && res.results) {
          history.goBack();
        } else {
          window.alert(res.message);
        }
      }
    );
  };

  const handleChange = evt => {
    const { name, value } = evt.target;
    setGroup({
      ...group,
      [name]: value,
    });
  };

  return (
    <div className={editEmailConfigurationStyle.pageContainer}>
      <Label
        label={
          props.location?.state?.isNewGroup
            ? t('new_email_distribution')
            : t('edit_email_distribution')
        }
        bold
        neutral
      />

      <Card transparent margin="0px">
        <CardRow transparent flexWrap="wrap" padding="0px 0px 0px 5px">
          <DropdownField
            name="uuid_email_type"
            options={emailTypeList}
            valueField="uuid_email_type"
            labelField="str_email_type_name"
            label={t('distribution_type')}
            margin="10px 10px 0px 0px"
            value={group.uuid_email_type}
            onChange={handleChange}
            isRequired
            key='uuid_email_type'
            noOnlyOne
          />
          <DropdownField
            name="uuid_zone"
            options={privilege.arr_zones ? privilege.arr_zones : []}
            valueField="uuid_zone"
            labelField="str_name"
            label={t('common_zone')}
            margin="10px 10px 0px 0px"
            value={group.uuid_zone}
            isRequired
            onChange={value => {
              group.uuid_country = null;
              group.uuid_site = null;
              handleChange(value);
            }}
          />
          <DropdownField
            name="uuid_country"
            options={
              privilege.arr_countries && group.uuid_zone
                ? privilege.arr_countries.filter(country => {
                  return country.uuid_zone === group.uuid_zone;
                })
                : []
            }
            valueField="uuid_country"
            labelField="str_name"
            label={t('common_country')}
            margin="10px 10px 0px 0px"
            value={group.uuid_country}
            isRequired
            onChange={value => {
              group.uuid_site = null;
              handleChange(value);
            }}
          />
          <DropdownField
            name="uuid_site"
            options={
              privilege.arr_sites && group.uuid_country
                ? privilege.arr_sites.filter(site => {
                  return site.uuid_country === group.uuid_country;
                })
                : []
            }
            valueField="uuid_site"
            labelField="str_name"
            label={t('common_site')}
            margin="10px 10px 0px 0px"
            isRequired
            value={group.uuid_site}
            onChange={handleChange}
          />
        </CardRow>

        <CardRow transparent flexWrap="wrap">
          <CardColumn padding="5px">
            <EditEmailConfigurationUsers
              group={group}
              setGroup={setGroup}
              privilege={privilege}
            />
          </CardColumn>

          <CardColumn padding="5px">
            <EditEmailConfigurationTasters group={group} setGroup={setGroup} />
          </CardColumn>
        </CardRow>

        <CardRow justifyContent="flex-end" flexWrap="wrap" transparent>
          <Button
            label={t('common_cancel')}
            onClick={() => {
              history.goBack();
            }}
            neutral
            margin="10px 10px 0px 0px"
          />
          <Button
            label={t('common_save')}
            onClick={handleSave}
            confirm
            margin="10px 10px 0px 0px"
          />
        </CardRow>
      </Card>
    </div>
  );
};

export default EditEmailConfiguration;
