import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-18-sortable-hoc';
import { useTranslation } from 'react-i18next';
import { Shuffle, X } from 'react-feather';
import PropTypes from 'prop-types';
import { IoEmit } from '../../../utils/socketIo';

import editRoundSamplesStyle from './editroundsamples.module.scss';
import EditRoundSamplesSample from '../editRoundSamplesSample/EditRoundSamplesSample';
import RoundService from '../../services/rounds/round.service';
import SampleService from '../../services/samples/sample.service';
import {
  GenericTable,
  Button,
  Spinner,
  Checkbox,
  TextField,
  CustomColumns,
  Modal,
  DropdownField,
} from '../../../components';
import UserService from '../../services/users/user.service';
import ReactTooltip from 'react-tooltip';
import { Edit } from 'react-feather';
import uuid from 'react-uuid';
import EditSample from '../../Samples/editSample/EditSample';

const EditRoundSamples = forwardRef((props, ref) => {
  const [samples, setSamples] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRoundSample, setLoadingRoundSample] = useState(false);
  const [temperatures, setTemperatures] = useState([]);
  const [storage_times, setStorageTimes] = useState([]);
  const roundService = new RoundService();
  const sampleService = new SampleService();
  const { t } = useTranslation();
  const [templateName, setTemplateName] = useState(
    props.round.str_template_name,
  );
  const [round, setRound] = useState({})
  const [showRoundSamples, setShowRoundSamples] = useState(false);
  const [editPermission, setEditPermission] = useState([
    { str_name: 'common_zone', bol_status: null },
    { str_name: 'common_country', bol_status: null },
    { str_name: 'common_site', bol_status: null },
    { str_name: 'common_brand', bol_status: null },
    { str_name: 'sample_production_date', bol_status: null },
    { str_name: 'common_sku', bol_status: null },
    { str_name: 'common_batch', bol_status: null },
    { str_name: 'sample_project_name', bol_status: null },
    { str_name: 'sample_trial_identifier', bol_status: null },
    { str_name: 'common_kpi_month', bol_status: null },
    { str_name: 'submission_type', bol_status: null },
    { str_name: 'sample_tasted_dates', bol_status: null },
  ]);
  const userService = new UserService();
  useImperativeHandle(ref, () => ({
    saveSamples,
    samples,
    round
  }));

  const saveSamples = () => {
    return new Promise(async (resolve, reject) => {
      if (bol_splitRound === false) {
        const res = await roundService.resetSplitRound({
          uuid_round: round.uuid_round,
          uuid_split_round: splitRounds.length > 0 ? splitRounds[0].uuid_split_round : ''
        })
        if (round.uuid_round && round.arr_samples) {
          let samplesTemp = round.arr_samples;
          samplesTemp = samplesTemp.map(sample => {
            sample.uuid_kpi_codes = round.uuid_kpi_codes;
            return sample;
          });
          roundService
            .updateRoundSamples({
              uuid_round: round.uuid_round,
              arr_samples: samplesTemp,
            })
        }

        if (res) {
          resolve(round);
        }
      } else {
        if (round.uuid_round && round.arr_samples) {
          roundService
            .updateRoundSamples({
              uuid_round: round.uuid_round,
              arr_samples: [],
            })
        }
        if (splitRounds.length > 0) {
          const res = await roundService.saveSplitRoundSamples({
            arr_round_samples: splitRounds,
            uuid_round: round.uuid_round,
          })

          if (res) {
            resolve({
              ...round,
              arr_round_samples: [],
            })
          }
          return;
        }
        resolve(true);
      }
    })

  }

  useEffect(() => {
    updateRanked();
    refreshState();
  }, []);

  const updateRanked = () => {
    if (
      props.round.uuid_round_type === 'b8082a00-e1d1-11ec-97a3-000000000000'
    ) {
      setRound({
        ...props.round,
        ...round,
        bol_ranked: true,
      })
    }
  };

  const [bol_splitRound, setBol_splitRound] = useState(JSON.parse(sessionStorage.getItem('bol_splitRound')) ?? false);
  const [checkModeShow, setCheckModeShow] = useState(false);
  const [roundSum, setRoundSum] = useState('2');
  const [roundSumOpts, setRoundSumOpts] = useState([]);
  const [splitRounds, setSplitRounds] = useState([]);
  var splitItemIndex = 0;
  const [splitLoading, setSplitLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [swapSampleID, setSwapSampleID] = useState([]);
  const [swapSampleName, setSwapSampleName] = useState('');
  const [swapSampleOrder, setSwapSampleOrder] = useState(1);
  const [swapOldIndex, setSwapOldIndex] = useState(0);

  const [showSampleList, setShowSampleList] = useState(false);
  const [checkEditSample, setCheckEditSample] = useState(0);
  const [editSampleState, setEditSampleState] = useState({
    values: { uuid_sample: uuid() },
    privilege: props.sampleEditPrivilege,
    isNewSample: true,
  });
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    sessionStorage.setItem('bol_splitRound', bol_splitRound)
  }, [bol_splitRound])

  useEffect(() => {
    if (checkEditSample === false) {
      setEditSampleState({
        values: { uuid_sample: uuid() },
        privilege: props.sampleEditPrivilege,
        isNewSample: true,
      })
      setEditPermission([...editPermission]);
    }
  }, [checkEditSample]);

  useEffect(() => {
    if (filteredRows.length > 0 && round.arr_samples?.length > 0 && checkEditSample === false) {
      setRound({
        ...round,
        arr_samples: [
          ...round.arr_samples?.map((sample, i) => {
            filteredRows.forEach(row => {
              if (row.uuid_sample === sample.uuid_sample) {
                sample = { ...sample, ...row }
              }
            })
            return sample;
          })
        ]
      });
    }
  }, [filteredRows]);

  useEffect(() => {
    let newArr = [];
    for (let i = 2; i <= round.arr_samples?.length; i++) {
      newArr.push({
        value: `${i}`,
        label: `${i}`,
      });
    }
    setRoundSumOpts([...newArr]);
  }, [round.arr_samples]);

  const refreshState = async () => {
    sampleService.listTemperatures().then(
      res => {
        if (res.type === 'success' && res.results) {
          setTemperatures(
            res.results.map(temperature => {
              return {
                uuid_temperature: temperature.uuid_temperature,
                val_temperature: temperature.val_storage_condition
                  ? `${temperature.val_storage_condition}`
                  : `${temperature.val_temperature_c}°C / ${temperature.val_temperature_f}°F`,
              };
            }),
          );
        } else {
          window.alert(res.message);
        }
      },
      error => { },
    );

    sampleService.listStorageTimes().then(
      res => {
        if (res.type === 'success' && res.results) {
          setStorageTimes(
            res.results.map(storage_time => {
              let str_week =
                storage_time.val_time > 1
                  ? t('common_weeks')
                  : t('common_week');
              let str_label = `${parseFloat(
                storage_time.val_time,
              )} ${str_week}`;
              if (storage_time.val_time > 0) {
                str_label += `/${parseInt(storage_time.val_time * 7)} ${t(
                  'common_days',
                )}`;
              }
              return {
                uuid_storage_time: storage_time.uuid_storage_time,
                val_time: str_label,
              };
            }),
          );
        } else {
          window.alert(res.message);
        }
      },
      error => { },
    );
    roundService.listRoundSamples(props.round.uuid_round).then(
      res => {
        if (res.type === 'success' && res.results) {
          const arr_samples = res.results.map((item, index) => {
            item.int_order_app = index;
            return item
          })
          setRound({
            ...props.round,
            arr_samples: [...arr_samples],
          });
        } else {
          window.alert(res.message);
        }
      },
      error => { },
    );
    if (bol_splitRound) {
      setLoadingRoundSample(true);
      const row = await roundService.getSamplesSplit(props.round.uuid_round);
      if (row.results.length > 0) {
        const Arr = row.results?.map(item => {
          return {
            ...item,
            bol_disable: true,
          };
        });
        setSplitRounds(Arr);
        setCheckModeShow(false);
        setSplitLoading(false);
        setBol_splitRound(true);
      }
      setLoadingRoundSample(false);
    }
    userService.listCustomColumns('sample_round').then(res => {
      if (
        res.type === 'success' &&
        res.results[0].user_sp_se_column_options?.length ===
        editPermission?.length
      ) {
        setEditPermission(res.results[0].user_sp_se_column_options);
      }
    });
  };

  const handleSampleOrder = (oldIndex, newIndex) => {
    //CHANGE ORDER OF SAMPLES AND UPDATE APP INDEX
    let samplesTemp = round.arr_samples;
    const sampleTemp = samplesTemp[oldIndex];

    samplesTemp.splice(oldIndex, 1);
    samplesTemp.splice(newIndex, 0, sampleTemp);

    return samplesTemp.map((sample, index) => {
      return { ...sample, int_order_app: index };
    });
  };

  const handleDropSample = ({ oldIndex, newIndex }) => {
    setRound({
      ...round,
      arr_samples: round.arr_samples
        ? [...handleSampleOrder(oldIndex, newIndex)]
        : [],
    });
  };

  const handleDropSplitSample = async ({ oldIndex, newIndex }) => {
    let samplesTemp = splitRounds[splitItemIndex].arr_samples;
    const sampleTemp = samplesTemp[oldIndex];
    samplesTemp.splice(oldIndex, 1);
    samplesTemp.splice(newIndex, 0, sampleTemp);

    splitRounds[splitItemIndex].arr_samples = samplesTemp.map(
      (sample, index) => {
        return { ...sample, int_order_app: index };
      },
    );
    setSplitRounds([...splitRounds]);
  };

  const handleRandomizeSamples = async (name, itemIndex) => {
    const arr = JSON.parse(
      JSON.stringify(
        itemIndex === null || itemIndex === undefined
          ? round.arr_samples
          : splitRounds[itemIndex].arr_samples,
      ),
    );
    const randomizedArrSamples =
      name === 'random'
        ? arr
          .map(value => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value)
          .map((sample, index) => {
            return { ...sample, int_order_app: index };
          })
        : arr.reverse().map((sample, index) => {
          return { ...sample, int_order_app: index };
        });

    if (itemIndex === null || itemIndex === undefined) {
      setRound({
        ...round,
        arr_samples: round.arr_samples ? [...randomizedArrSamples] : [],
      });
    } else {
      splitRounds[itemIndex].arr_samples = [...randomizedArrSamples];
      setSplitRounds([...splitRounds]);
    }
  };

  const handleAddSample = async isShowRadio => {
    setLoading(true);
    let samplesTemp = samples?.filter(sample => {
      return (
        sample.bol_check &&
        (round.arr_samples ?? []).filter(
          s => s.uuid_sample === sample.uuid_sample,
        ).length === 0
      );
    });
    if (isShowRadio === true) {
      const params = {
        uuid_round: props.round.uuid_round,
        uuid_sample: samplesTemp[0]?.uuid_sample,
        uuid_old_sample: swapSampleID,
        int_order_app: swapSampleOrder,
        bol_kpi: samplesTemp[0]?.bol_kpi,
        uuid_kpi_codes: props.round.uuid_kpi_codes,
        str_name: samplesTemp[0]?.str_brand_name,
        str_old_name: swapSampleName,
      };
      const res = await roundService.swapSample(params);

      if (res) {
        if (res.results[0]?.round_sp_in_up_admin_swap_sample === '1') {
          window.alert(t('sample_successfully_swapped'));
          let arr_samples = round.arr_samples;
          samplesTemp[0].int_order_app = swapSampleOrder;
          arr_samples[swapOldIndex] = samplesTemp[0];
          setRound({
            ...round,
            arr_samples,
          });
          props.setRound({
            ...round,
            arr_samples,
          });
          IoEmit('swapSample', { ...params });
        }
      }
    } else {
      samplesTemp = samplesTemp.map(sample => {
        const { arr_in_samples } = sample;
        if (arr_in_samples && arr_in_samples.length > 0) {
          const hasNowRound = arr_in_samples.some(round =>
            round.uuid_round === props.round.uuid_round &&
            round.uuid_site === props.round.uuid_site);
          const hasNowSite = arr_in_samples.some(round => round.uuid_site === props.round.uuid_site);
          if (hasNowRound || !hasNowSite) {
            sample.isCountKPIControl = false
            if (sample.bol_count_as_kpi === null
              || typeof sample.bol_count_as_kpi !== 'boolean'
              || typeof sample.bol_count_as_kpi !== 'number'
              || sample.bol_count_as_kpi != 0
            ) {
              sample.bol_count_as_kpi = sample.bol_kpi &&
                !!props.round.bol_mcs_tasting;
            }
          } else {
            sample.isCountKPIControl = true
          }
          sample.bol_count_as_kpi =
            !sample.isCountKPIControl &&
            sample.bol_kpi &&
            !!round.bol_mcs_tasting;
        }
        return sample;
      });

      setRound({
        ...round,
        arr_samples: ([...round.arr_samples] ?? [])
          .concat(samplesTemp)
          .map((e, i) => ({ ...e, int_order_app: i })),
      });
      props.setAddUpdateSample && props.setAddUpdateSample([...samplesTemp]);
    }
    setLoading(false);
    setSamples(
      samples.map(sampleTemp => {
        sampleTemp.bol_check = 0;
        return sampleTemp;
      }),
    );
    setShowSampleList(false);
  };

  const handleRemoveSample = sample => {
    const samplesTemp = round.arr_samples.filter(sampleTemp => {
      return sample.int_order_app !== sampleTemp.int_order_app;
    });
    setRound({
      ...round,
      arr_samples: samplesTemp
        ? samplesTemp.map((e, i) => ({ ...e, int_order_app: i }))
        : [],
    });
  };
  const handleRemoveSplitSample = async (sample, indexX) => {
    splitRounds[indexX].arr_samples = splitRounds[indexX].arr_samples
      .filter(sampleTemp => {
        return sample.int_order_app !== sampleTemp.int_order_app;
      })
      .map((e, i) => ({ ...e, int_order_app: i }));

    setSplitRounds([...splitRounds]);
    setResetLoading(true);
    setResetLoading(false);
  };

  const handelSplitRow = async (indexX, keyName, value, index) => {
    splitRounds[indexX].arr_samples[index][keyName] = value;
    setSplitRounds([...splitRounds]);
  };

  const handleCheckSample = async (sample, evt) => {
    const hasSample = round.arr_samples?.filter(
      s => s.uuid_sample == sample.uuid_sample,
    ) ?? [];
    if (hasSample.length > 0 && evt.target.value === 1) {
      alert(
        `${t('error_sample_already_in_round')}:\n${sample.str_site_name}-${sample.str_brand_name
        }`,
      );
    } else {
      // let isBreweryBrandKPISample = await sampleService.isBreweryBrandKPISample(
      //   round.uuid_site,
      //   sample.uuid_sample,
      // );
      // let isCountKPIControl =
      //   isBreweryBrandKPISample.results[0].kpi_sp_se_existing_sample_kpi;
      const tempSamples = [
        ...samples.map(sampleTemp => {
          if (evt.target && sampleTemp.uuid_sample === sample.uuid_sample) {
            sampleTemp.bol_check = evt.target.value;
            // sampleTemp.isCountKPIControl = isCountKPIControl;
          }
          return sampleTemp;
        }),
      ];
      setSamples(tempSamples);
    }
  };

  const handleCheck = sample => {
    if (sample.target) {
      setRound({
        ...round,
        [sample.target.name]: sample.target.value
      });
      props.setRound({
        ...props.round,
        [sample.target.name]: sample.target.value
      })
    }
  };

  const handleSetSamplesPag = res => {
    setSamples(res);
  };

  const filterHeaders = [
    { description: t('common_zone'), field: 'str_zone_name' },
    { description: t('common_country'), field: 'str_country_name' },
    { description: t('common_site'), field: 'str_site_name' },
    { description: t('common_brand'), field: 'str_brand_name' },
    {
      description: t('sample_production_date'),
      field: 'dat_production',
      type: 'datetime',
    },
    { description: t('common_sku'), field: 'str_sku_name' },
    { description: t('common_batch'), field: 'str_batch' },
    { description: t('sample_project_name'), field: 'str_name_project' },
    {
      description: t('sample_trial_identifier'),
      field: 'str_trial_identifier',
    },
    {
      description: t('common_kpi_month'),
      field: 'dat_kpi',
      type: 'datemonth',
      cache: true,
      fieldCache: 'sample.dat_kpi',
      notSearchable: true,
      selectKpiMonth: true,
    },
    { description: t('submission_type'), field: 'str_submission_type_name' },
    {
      description: t('sample_tasted_dates'),
      field: 'str_type_name',
      type: 'multi-action',
      data: 'arr_tasted',
    },
  ];
  const headers = filterHeaders.filter((item, index) => {
    return (
      editPermission[index].bol_status === null ||
      editPermission[index].bol_status === 1
    );
  });
  const SortableItem = SortableElement(({ itemIndex, value, rowIndex }) => (
    <EditRoundSamplesSample
      state={props.state}
      sample={value}
      round={round}
      itemIndex={itemIndex}
      temperatures={temperatures}
      storage_times={storage_times}
      setRound={setRound}
      index={rowIndex}
      onRemove={!bol_splitRound ? handleRemoveSample : handleRemoveSplitSample}
      splitRound={!round.uuid_split_round && bol_splitRound}
      onChangeSplitRow={(keyName, value) => {
        handelSplitRow(itemIndex, keyName, value, rowIndex);
      }}
      showSampleList={() => {
        setSwapSampleName(value.str_brand_name);
        setSwapSampleID(value.uuid_sample);
        setSwapSampleOrder(value.int_order_app);
        setSwapOldIndex(rowIndex);
        setShowSampleList(true);
      }}
    />
  ));

  const SortableList = SortableContainer(({ items, itemIndex }) => {
    return (
      <ul>
        {items.map((value, index) => (
          <SortableItem
            key={`sample-${index}`}
            index={index}
            itemIndex={itemIndex}
            rowIndex={index}
            value={value}
          />
        ))}
      </ul>
    );
  });

  const handleSubmit = async () => {
    const samples = [...round.arr_samples];

    const samplesSum = samples.length;

    let hasRemainder = samplesSum % roundSum;
    const groupSize = Math.floor(samplesSum / roundSum);
    const splitRounds = [];
    const uuid_split_round = uuid();
    for (let i = 0; i < roundSum; i++) {
      let splitRound = {
        'str_split_round_order': i + 1,
        'str_name_round': round.str_name_round ?? null,
        'uuid_round': uuid(),
        'uuid_split_round': uuid_split_round,
        'bol_disable': true,
      }
      const groupSizeNow = groupSize + (hasRemainder > 0 ? 1 : 0);
      hasRemainder--;
      const arr_samples = [...samples.splice(0, groupSizeNow)];
      splitRound = {
        ...splitRound,
        arr_samples: arr_samples.map((item, index) => {
          item.uuid_round = round.uuid_split_round;
          item.int_order_app = index;
          return item;
        })
      }
      splitRounds.push(splitRound);
    }
    setSplitRounds(splitRounds);
    setCheckModeShow(false);
    setSplitLoading(false);
    setBol_splitRound(true);
  };


  const handleSplitRoundName = index => {
    splitRounds[index].bol_disable = !splitRounds[index].bol_disable;
    setSplitRounds([...splitRounds]);
  };

  const checkNowSplit = () => {
    if (
      window.location.pathname === '/edit_round' &&
      !round.uuid_split_round &&
      (props.round.arr_status[props.round.arr_status?.length - 1] ===
        'SAMPLES' ||
        props.round.arr_status[props.round.arr_status?.length - 1] ===
        'TASTERS' ||
        props.round.arr_status[props.round.arr_status?.length - 1] ===
        'SCHEDULED') &&
      !bol_splitRound
    ) {
      return true;
    } else if (
      round.arr_samples?.length >= 2 &&
      !bol_splitRound &&
      (window.location.pathname === '/new_round' || props.isCopyRound)
    ) {
      return true;
    } else if (bol_splitRound) {
    }
    return false;
  };


  const handleNewSample = useCallback(() => {
    setCheckEditSample(true);
  }, [props.sampleEditPrivilege]);

  const handleEditSample = useCallback(
    values => () => {
      setEditSampleState({
        values: values,
        privilege: props.sampleEditPrivilege,
        isNewSample: false
      });
      setCheckEditSample(true);
    },
    [props.sampleEditPrivilege],
  );

  return (
    <div className={editRoundSamplesStyle.droppableCard}>
      {round && round.arr_samples && !loading ? (
        <div>
          {showRoundSamples && (
            <CustomColumns
              title={t('column_options')}
              explain={t(
                'select_which_of_the_columns_do_you_want_to_be_displayed',
              )}
              round={'sample_round'}
              arrHeader={headers}
              setShow={setShowRoundSamples}
              onChange={setEditPermission}
              values={editPermission}
            />
          )}
          <GenericTable
            editPermission={editPermission}
            setShowRoundSamples={setShowRoundSamples}
            originHeader={filterHeaders}
            arrHeader={headers}
            dataServer={sampleService.listSamplesByServer}
            searchable={true}
            onCheck={handleCheckSample}
            loading={loading}
            rowsPage={6}
            handleSetSamplesPag={handleSetSamplesPag}
            checkboxFilter={{
              fieldName: 'show_all_samples',
              label: t('sample_show_all_samples_label'),
              helpCircle: t('sample_show_all_samples_hint'),
            }}
            handleAddSample={handleAddSample}
            newRegister={{
              onClick: handleNewSample,
              label: t('sample_new_sample'),
            }}
            onEdit={props.sampleEditPrivilege.bol_access ? handleEditSample : null}
            handleFilteredRows={setFilteredRows}
          />
          <div className={editRoundSamplesStyle.buttonsRow}>
            <div className={editRoundSamplesStyle.buttonsRowLeft}>
              <Checkbox
                label={t('common_ranked')}
                name={'bol_ranked'}
                style={{ display: 'flex', alignItems: 'center' }}
                value={round.bol_ranked}
                margin="2px 0 0"
                onChange={evt => {
                  handleCheck(evt);
                }}
              />

              {props.round.uuid_round_type ===
                '4ed984d9-021d-4d06-a992-991a24b3d7ce' && (
                  <Checkbox
                    label={t('common_approve_reject')}
                    name="bol_is_approve_reject"
                    value={round.bol_is_approve_reject}
                    margin="2px 0 0 2px"
                    style={{ display: 'flex', alignItems: 'center' }}
                    onChange={evt => {
                      handleCheck(evt);
                    }}
                  />
                )}
              <div className={editRoundSamplesStyle.box}>
                <Checkbox
                  name={'bol_template'}
                  style={{ display: 'flex', alignItems: 'center' }}
                  value={
                    round.bol_template &&
                      round.bol_template !== 'No' &&
                      round.bol_template !== 0
                      ? true
                      : false
                  }
                  margin="3px 0 0"
                  padding="8px 4px 8px 24px"
                  onChange={evt => {
                    handleCheck(evt);
                  }}
                />
                <TextField
                  name={'bol_template'}
                  disabled={
                    round.bol_template === undefined
                    || round.bol_template === "No"
                    || round.bol_template === 0
                  }
                  placeholder={t('common_name_template')}
                  style={{ minHeight: '40px', margin: '0' }}
                  value={templateName}
                  onBlur={e => {
                    handleCheck(e);
                  }}
                  onChange={e => {
                    setTemplateName(e.target.value);
                    setRound({
                      ...round,
                      str_template_name: e.target.value,
                    })
                  }}
                ></TextField>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {props.round.uuid_round_type ===
                  '4ed984d9-021d-4d06-a992-991a24b3d7ce' &&
                  props.round.bol_mcs_tasting === true &&
                  !bol_splitRound &&
                  <Button
                    confirm
                    onClick={() => {
                      const arr_samples = round.arr_samples.map(sample => {
                        if (sample.isCountKPIControl != true) {
                          sample.bol_count_as_kpi = true;
                        }
                        return sample;
                      })
                      setRound({
                        ...round,
                        arr_samples: arr_samples
                      })
                    }}
                    style={{
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '10px',
                    }}
                  >
                    {t('all_count_as_kpi')}
                  </Button>
                }
                {(props.round.uuid_round_type ===
                  '4ed984d9-021d-4d06-a992-991a24b3d7ce' ||
                  props.round.uuid_round_type ===
                  'b8082a00-e1d1-11ec-97a3-000000000000') &&
                  round.arr_samples?.length >= 2 &&
                  !loadingRoundSample && (
                    <>
                      <Button
                        blue={checkNowSplit()}
                        onClick={() => {
                          setCheckModeShow(true);
                        }}
                        readOnly={!checkNowSplit()}
                        margin="0 0 0 15px"
                        dataTip={
                          !checkNowSplit() &&
                          t(
                            'you_cannot_split_a_round_that_has_already_been_split_or_in_progress',
                          )
                        }
                      >
                        {t('split_round')}
                      </Button>
                      {!checkNowSplit() && (
                        <ReactTooltip effect="solid" place="top" />
                      )}
                    </>
                  )}
                {bol_splitRound && !loadingRoundSample && (
                  <Button
                    blue
                    confirm
                    loading={resetLoading}
                    onClick={async () => {
                      setBol_splitRound(false);
                      const arr_samples = splitRounds.map(item => { return item.arr_samples })
                        .reduce((acc, curr) => acc.concat(curr), [])
                        .map((item, index) => {
                          item.int_order_app = index
                          return item
                        })
                      setRound({
                        ...round,
                        arr_samples: arr_samples
                      })
                      setResetLoading(false);
                    }}
                    style={{
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '10px',
                    }}
                  >
                    {t('reset')}
                  </Button>
                )}
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              {!bol_splitRound && (
                <>
                  <Button
                    confirm
                    onClick={handleRandomizeSamples}
                    disabled={
                      !round.arr_samples ||
                      round.arr_samples.length === 0
                    }
                    style={{
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: '15px',
                    }}
                  >
                    {t('reverse')}
                  </Button>
                  <Button
                    confirm
                    onClick={() => handleRandomizeSamples('random')}
                    disabled={
                      !round.arr_samples ||
                      round.arr_samples.length === 0
                    }
                    style={{
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {t('round_randomize_samples_order_button')}
                    <Shuffle size="18" />
                  </Button>
                </>
              )}
            </div>
          </div>
          {loadingRoundSample ? (
            <Spinner />
          ) : !bol_splitRound ? (
            <SortableList
              distance={5}
              itemIndex={''}
              items={round?.arr_samples ?? []}
              onSortEnd={handleDropSample}
            />
          ) : (
            <>
              {splitRounds.length > 0 &&
                splitRounds.map((item, index) => {
                  return (
                    <div className={editRoundSamplesStyle.splitItem} key={item}>
                      <div className={editRoundSamplesStyle.splitItemTitle}>
                        <div
                          className={editRoundSamplesStyle.splitItemTitleName}
                        >
                          <div className={editRoundSamplesStyle.nameNum}>
                            {`Round ${item.str_split_round_order}`}
                          </div>
                          <div className={editRoundSamplesStyle.nameOpts}>
                            <p className={editRoundSamplesStyle.nameOptsText}>
                              {t('common_name')}
                            </p>
                            <div
                              className={editRoundSamplesStyle.nameOptstitle}
                            >
                              <TextField
                                value={item.str_name_round}
                                onChange={evt => {
                                  splitRounds[index].str_name_round =
                                    evt.target.value;
                                  setSplitRounds([...splitRounds]);
                                }}
                                disabled={item.bol_disable}
                                maxLength="40"
                                padding="11px 5px"
                                className={editRoundSamplesStyle.nameOptsInput}
                              />
                              <Edit
                                className={editRoundSamplesStyle.editBtn}
                                onClick={() => {
                                  handleSplitRoundName(index);
                                }}
                              ></Edit>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            editRoundSamplesStyle.splitItemTitleOperation
                          }
                        >
                          <Button
                            confirm
                            onClick={() => {
                              handleRandomizeSamples('', index);
                            }}
                            disabled={
                              !item.arr_samples || item.arr_samples.length === 0
                            }
                            style={{
                              whiteSpace: 'nowrap',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginRight: '15px',
                            }}
                          >
                            {t('reverse')}
                          </Button>
                          <Button
                            confirm
                            onClick={() =>
                              handleRandomizeSamples('random', index)
                            }
                            disabled={
                              !item.arr_samples || item.arr_samples.length === 0
                            }
                            style={{
                              whiteSpace: 'nowrap',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {t('round_randomize_samples_order_button')}
                            <Shuffle size="18" />
                          </Button>
                        </div>
                      </div>
                      <div>
                        <SortableList
                          distance={5}
                          items={item.arr_samples}
                          itemIndex={index}
                          onSortEnd={async sort => {
                            splitItemIndex = index;
                            handleDropSplitSample(sort);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      ) : null
      }
      <Modal
        className={editRoundSamplesStyle.attention}
        show={checkModeShow}
        style={{ height: '100%' }}
      >
        <h2 className={editRoundSamplesStyle.attentionTitle}>
          {t('attention')}
        </h2>
        <div className={editRoundSamplesStyle.attentionLittleTitle}>
          {t('how_many_rounds_splitting_do_you_want_to_make')}？
        </div>
        <DropdownField
          name="round_sum"
          options={roundSumOpts}
          valueField="value"
          labelField="label"
          bolOrder={false}
          arrowTop="10px"
          margin="10px 50px"
          value={roundSum}
          onChange={evt => {
            setRoundSum(evt.target.value);
          }}
        />
        <div className={editRoundSamplesStyle.buttonContainer}>
          <Button
            className={editRoundSamplesStyle.cancelButton}
            label={t('common_cancel')}
            remove
            margin="0px 10px 0px 0px"
            onClick={() => {
              setCheckModeShow(false);
            }}
          ></Button>

          <Button
            className={editRoundSamplesStyle.confirmButton}
            label={t('confirm')}
            loading={splitLoading}
            confirm
            disabled={!roundSum}
            onClick={handleSubmit}
          ></Button>
        </div>
      </Modal>
      <Modal
        className={editRoundSamplesStyle.editSamples}
        show={checkEditSample}
        style={{ height: '100%', }}
      >
        <Button
          onClick={() => {
            setCheckEditSample(false);
          }}
          className={editRoundSamplesStyle.btnClose}
        >
          <X />
        </Button>
        <EditSample
          key={editSampleState.values.uuid_sample}
          location={{
            state: editSampleState,
          }}
          closeAction={() => setCheckEditSample(false)}
        />
      </Modal>

      {
        showSampleList && (
          <div
            className={editRoundSamplesStyle.showListModal}
            style={{ height: '100%' }}
          >
            <div className={editRoundSamplesStyle.showListBox}>
              <div className={editRoundSamplesStyle.closeButton}>
                <Button
                  round
                  style={{
                    display: 'flex',
                    width: '30px',
                    fontSize: '18px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '5px',
                  }}
                  onClick={() => setShowSampleList(false)}
                >
                  <X width='16px' />
                </Button>
              </div>
              <GenericTable
                editPermission={editPermission}
                setShowRoundSamples={setShowRoundSamples}
                originHeader={filterHeaders}
                arrHeader={headers}
                dataServer={sampleService.listSamplesByServer}
                searchable={true}
                onCheck={handleCheckSample}
                loading={loading}
                rowsPage={6}
                handleSetSamplesPag={handleSetSamplesPag}
                checkboxFilter={{
                  fieldName: 'show_all_samples',
                  label: t('sample_show_all_samples_label'),
                  helpCircle: t('sample_show_all_samples_hint'),
                }}
                isShowRadio={true}
                handleAddSample={() => {
                  handleAddSample(true);
                }}
                bol_splitRound={bol_splitRound}
                hidedropdownOuterList={true}
              />
            </div>
          </div>
        )
      }
    </div >
  );
});

EditRoundSamples.propTypes = {
  privilege: PropTypes.any,
  round: PropTypes.any,
  setRound: PropTypes.any,
};

export default EditRoundSamples;