export default {
  sample_no_production_date_message:
    "It's mandatory to select a production date.",
  free_comment: 'Free comment',
  round_list_rounds: 'Rounds',
  round_edit_round: 'Edit Round',
  round_new_round: 'New Round',
  round_remove_round: 'Are you sure you want to delete the round',
  round_schedule: 'Schedule',
  round_tasters_unselect_all: 'Unselect all Tasters',
  round_tasters_select_all: 'Select all Tasters',
  round_close: 'Close Round',
  round_close_message: 'Are you sure you want to finish the Round',
  round_create_site_message:
    'You are creating a round that is not from your site!',
  round_closed_message: "Closed rounds can't be edited",
  round_status_created: 'Created',
  round_status_samples: 'Samples',
  round_status_scheduled: 'Scheduled',
  round_status_tasters: 'Tasters',
  round_status_in_progress: 'In Progress',
  round_status_finished: 'Finished',
  round_status_closed: 'Closed',
  round_schedule_start_finish_date:
    'The Start Date cannot be greater than the Finish Date!',
  round_schedule_finish_date_no_start_date:
    'You have to select a Start date first!',
  round_disabled_discussion_tooltip:
    "You can't go to a Discussion of an unfinished round",
  round_delete_other_users_rounds:
    "You don't have the permission to delete a round that wasn't created by you",
  round_randomize_samples_order_button: 'Shuffle',
  round_block_results_tooltip:
    'Lock the results of this round from being downloaded in the reports screen. If the button is disabled, you do not have the privilege to lock the results of rounds from this location',
  round_block_results_confirm:
    'Are you sure you want block/unblock the results of the round ',
  round_block_results_no_privilege:
    "You don't have the privilege to block/unblock round's results from this location.",
  sample_list_samples: 'Samples',
  sample_edit_sample: 'Edit Sample',
  sample_new_sample: 'New Sample',
  sample_remove_sample: 'Remove Sample',
  sample_upload_document: 'Upload Documents',
  sample_download_documents: 'Sample Documents',
  sample_tasted_dates: 'Taste Date',
  sample_upload_message: 'To upload multiple documents, add them to a ',
  sample_not_for_kpi: 'Not for KPI',
  sample_package: 'Package',
  sample_volume: 'Volume',
  sample_production_date: 'Production Date',
  sample_expire_date: 'Expiration Date',
  sample_trial_identifier: 'Trial Identifier',
  sample_project_name: 'Project Name',
  sample_linked: 'Linked to a Round',
  sample_expire_bigger_than_production:
    'The Production Date cannot be greater than the Expire Date!',
  sample_no_site_message:
    "It's mandatory to select a Zone, Country and a Site to create a sample.",
  sample_no_brand_message: "It's mandatory to select a Brand.",
  sample_duplicated_kpi_message:
    'There is already a KPI for the month, brand and site you selected and you will not be able to save',
  sample_duplicated_project_message:
    'There is already a sample with this Project Name and Trial Identifier for this Site and Brand, and you will not be able to save',
  sample_project_no_name_or_trial:
    'Project Name and Trial Identifier are mandatory when creating a sample of Project type',
  sample_production_to_project_confirm_message:
    'You are changing the type of this sample from PRODUCTION to PROJECT. Do you wish to continue?',
  sample_project_to_production_confirm_message:
    'You are changing the type of this sample from PROJECT to PRODUCTION. Do you wish to continue?',
  sample_no_volume_message:
    'Package and Volume are mandatory when creating/editing a sample.',
  sample_id_code_message: 'Please include the complete batch number',
  sample_show_all_samples_hint:
    'Showing samples created up until six months from today. Check this box if you want to see older samples as well',
  sample_show_all_samples_label: 'Show All Samples',
  package_duplicate: 'There is a package with this name already',
  common_production: 'Production',
  common_project: 'Project',
  group_list_groups: 'Groups',
  group_edit_group: 'Edit Group',
  group_new_group: 'New Group',
  group_remove_group: 'Remove Group',
  group_close_modal: 'Close',
  group_duplicated_user: 'This user has already been added to this group!',
  group_no_name_message: "It's necessary to enter a group name",
  group_no_zone_message: "It's necessary to select a Zone",
  group_no_users_message: "It's necessary to select at least one taster",
  family_list_families: 'Styles',
  family_edit_family: 'Edit Style',
  family_new_family: 'New Style',
  family_remove_family: 'Remove Style',
  family_has_no_english_name:
    "Attention: it's mandatory to have the Style name in English",
  brand_list_brands: 'Brands',
  brand_edit_brand: 'Edit Brand',
  brand_new_brand: 'New Brand',
  brand_remove_brand: 'Remove Brand',
  brand_profile_picture: 'Upload Beer Profile Picture',
  brand_upload_brand_logo: 'Upload Brand Logo',
  brand_duplicate: 'Warning: There is a beer with this name',
  brand_has_no_english_name:
    'You must have at least one brand name and a brand profile match with it.',
  brand_upload_help_message: 'PNG and JPG files only',
  brand_has_no_product_type: 'You must select a Product Type',
  package_list_packages: 'Packages',
  package_edit_package: 'Edit Packages',
  package_new_package: 'New Package',
  package_remove_package: 'Remove Package',
  package_has_no_english_name:
    "Attention: it's mandatory to have the Package name in English",
  package_volumes: 'Volumes',
  package_new_volume: 'New Volume',
  package_remove_volume: 'Remove Volume',
  degree_list_degrees: 'Intensities',
  degree_edit_degree: 'Edit Intensity',
  degree_new_degree: 'New Intensity',
  degree_remove_degree: 'Remove Intensity',
  category_list_categories: 'Categories',
  category_edit_category: 'Edit Flavor Category',
  category_new_category: 'New Flavor Category',
  category_remove_category: 'Remove Flavor Category',
  comment_list_comments: 'Attributes',
  comment_edit_comment: 'Edit Flavor Attribute',
  comment_new_comment: 'New Flavor Attribute',
  comment_remove_comment: 'Remove Flavor Attribute',
  user_list_users: 'Users',
  user_edit_user: 'Edit User',
  user_new_user: 'New ABI Contractor',
  user_export_users: 'Export Users',
  user_list_profiles: 'Profiles',
  user_profile_name: 'Profile Name',
  user_profile_select: 'Select a Profile',
  user_profile_remove: 'Remove Profile',
  user_profile_save: 'Save Profile',
  user_remove_user: 'Remove User',
  user_use_terms: 'Accepted Terms Of Use',
  report_list_report: 'Reports',
  report_end_date: 'End Date',
  common_privileges: 'Privileges',
  common_abi_name: 'ABI Name',
  common_id: 'ID',
  common_ranked: 'Ranked',
  common_week: 'Week',
  common_weeks: 'Weeks',
  common_days: 'Days',
  common_category: 'Flavor Category',
  common_category_sample: 'Category',
  common_degree: 'Intensity',
  common_comment: 'Flavor Attribute',
  common_status: 'Status',
  common_zone: 'Zone',
  common_bu: 'BU',
  common_country: 'Country',
  common_site: 'Site',
  common_start_date: 'Start Date',
  common_finish_date: 'Finish Date',
  common_round_type: 'Test Type',
  common_round: 'Round',
  common_round_id: 'Round ID',
  common_tasters: 'Tasters',
  common_taster: 'Taster',
  common_sample_performance: 'Sample Performance',
  common_taste: 'Taste',
  common_key_taster: 'Key Taster',
  common_sample: 'Sample',
  common_samples: 'Samples',
  common_sample_type: 'Sample Type',
  common_family: 'Style',
  common_brand: 'Brand',
  common_product_type: 'Product Type',
  common_temperature: 'Storage Condition',
  common_storage_times: 'Storage Time',
  common_sku: 'SKU',
  common_batch: 'ID Code',
  common_kpi_month: 'KPI Month',
  common_logo: 'Logo',
  common_name: 'Name',
  common_notes: 'Notes',
  common_email: 'E-mail',
  common_language: 'Language',
  common_terms_of_use: 'Terms of Use',
  common_new: 'New',
  common_profile: 'Profile',
  common_filter: 'Filter Users',
  common_download: 'Download',
  common_save: 'Save',
  common_cancel: 'Cancel',
  common_search_users: 'Search Users',
  common_search_group: 'Search Group',
  common_search_taster: 'Search Taster',
  common_no_data: 'No data to be shown',
  common_loading: 'Loading',
  common_cannot_be_empty: '{{field}} cannot be empty',
  common_select_option: 'Select Option...',
  common_per_page: 'Rows per page',
  common_attributes: 'Attributes',
  common_not_started: 'Not Started',
  common_in_progress: 'In Progress',
  common_finished: 'Finished',
  common_start: 'Start',
  common_detail: 'Details',
  common_position: 'Position',
  common_average_key_score: 'Average key score',
  common_score: 'Score',
  common_score_scale: 'Score Scale',
  common_intensity: 'Intensity',
  common_report_not_have_data: 'The selected rounds had no votes',
  month_0: 'January',
  month_1: 'February',
  month_2: 'March',
  month_3: 'April',
  month_4: 'May',
  month_5: 'June',
  month_6: 'July',
  month_7: 'August',
  month_8: 'September',
  month_9: 'October',
  month_10: 'November',
  month_11: 'December',
  dropdown_change_picture: 'Change Picture',
  dropdown_language: 'Language',
  dropdown_logout: 'Log out',
  round_not_started: 'Not Started',
  round_in_progress: 'In Progress',
  round_finished: 'Finished',
  round_filter_start: 'Start',
  round_round_type_in_profile: 'In-Profile',
  tasting_list_rounds: 'List Rounds',
  menu_tasting_session: 'Tasting Session',
  menu_panel_management: 'Panel Management',
  menu_process_management: 'Process Management',
  menu_product_management: 'Product Management',
  menu_attributes_management: 'Attributes Management',
  tasting_finish_sample: 'Finish Sample',
  tasting_comments: 'Comments',
  tasting_beer_profile: 'Beer Profile',
  tasting_need_select_score_comments:
    'You must set a score and add at least one comment to continue...',
  tasting_finish_taste: 'Finish Taste',
  common_no_comments: 'No Comments',
  review_submit_title: 'Are you sure you are ready to submit your results?',
  review_submit_description: "You won't be able to edit later",
  review_finish_taste: 'Finish Taste',
  review_warning_sample_title: 'You have untasted samples!',
  review_warning_sample_description:
    'Do you wish to continue without tasting them?',
  review_warning_sample_review: 'Review',
  review_warning_sample_ok: 'Continue',
  review_warning_tie_sample_title: 'You have tie samples!',
  review_warning_tie_sample_description:
    'You cannot continue without rank them!',
  review_warning_tie_sample_review: 'Review',
  common_submit: 'Submit',
  common_discussion: 'Discussion',
  common_not_informed: 'Not informed',
  discussion_order_number: 'Order By Position',
  discussion_order_brewery: 'Order By Brewery',
  discussion_order_ranking: 'Order By Ranking',
  discussion_order_brand: 'Order By Brand',
  discussion_order_score: 'Order By Score',
  discussion_order_key_score: 'Order By Key Score',
  discussion_order_overall_rank: 'Order By Overall Rank',
  discussion_order_key_rank: 'Order By Key Rank',
  discussion_export_error: 'The file could not be saved!',
  discussion_ranking: 'Ranking',
  discussion_overall_ranking: 'Overall Ranking',
  discussion_key_ranking: 'Key Ranking',
  common_brewery: 'Brewery',
  common_trial_identifier: 'ID Trial',
  common_key_score: 'Key Score',
  round_report: 'Export to Excel',
  common_comments: 'Comments',
  message_you_cannot_delete_all_comments:
    "You can't delete all comments when editing a vote",
  common_message_empty_profile: 'Profile name not permitted empty or blank',
  common_message_out_score_range: 'out of range score',
  tasting_need_select_score: 'You must set a score first!',
  common_category_name: 'Category',
  categories_new_category: 'New category',
  categories_remove_category: 'Do you really want to remove the category',
  categories_list_categories: 'Categories',
  categories_list_all_categories: 'All Categories',
  common_category_sample_name: 'Sample',
  categories_new_category_sample: 'New sample',
  categories_remove_category_sample: 'Do you really want to remove the sample',
  categories_list_categories_samples: 'Samples',
  tasting_help: 'Tasting Help',
  common_back: 'Back',
  common_next: 'Next',
  common_sample_id: 'Sample ID',
  common_sample_date: 'Sample Date',
  common_duplicate: 'Duplicate',
  tasting_raw_materials: 'Raw materials',
  tasting_example_sensory_risk:
    'Example of main sensory risk: non-conformity with expected taste',
  tasting_procedure: 'Process',
  tasting_sample_preparation: 'Sample preparation',
  tasting_help_comments: 'Comments',
  common_collection_point: 'Collection Point',
  common_collection_date: 'Collection Date',
  common_brew_number: 'Brew Numbers/MES number',
  common_need_explain_nok: 'You cannot have samples NOK without a comment!',
  common_percent_ok: 'Percentage OK',
  common_show_all: 'Show All',
  common_import: 'Import',
  common_download_template: 'Download Template',
  sample_import_message: 'xlsx',
  common_template: 'Template',
  common_yes: 'Yes',
  common_no: 'No',
  common_need_volte_first: "You can't comment samples without a vote first!",
  common_name_template: 'Template Name',
  interaction_log: 'Interaction Log',
  common_message_zone_empty: 'Zone not permitted empty or blank',
  common_message_country_empty: 'Country not permitted empty or blank',
  common_message_site_empty: 'Site not permitted empty or blank',
  user_new_profile_save: 'New profile created',
  common_in_privileges: 'in privileges',
  common_download_files: 'Download Files',
  pdf_viewer: 'Viwer PDF',
  common_all: 'All',
  common_continue: 'Continue',
  common_minimum_score: 'Min',
  common_maximum_score: 'Max',
  common_average_score: 'Avg',
  file_upload_fail: 'Failed to upload the file, the following errors occured: ',
  file_upload_success_with_error:
    'The upload was successful except for the following: ',
  missing_fields: 'missing fields',
  column_error: 'column error',
  common_line: 'Line',
  file_no_samples: 'No samples found on the file!',
  file_success_upload: 'Success upload file!',
  file_duplicated_kpi_project: 'Duplicated KPI or Project',
  round_schedule_maximum_length:
    'The maximum length of the round name is 40 characters.',
  sample_malt_varieties: 'Malt Variety',
  error_sample_already_in_round: 'The sample is already in the round',
  malt_varieties_has_no_english_name:
    'You must have at least one malt varieties name.',
  malt_varieties_duplicate:
    'Attention: There is a variety of malt with this name',
  malt_varieties_remove: 'Remove Variety',
  malt_varieties_new: 'New Variety',
  reports_no_dates: 'Start Date and End Date are mandatory!',
  reports_date_range_message:
    'The range of dates cannot be bigger than 1 year!',
  round_save_select_site: 'To save a round you must select a site',
  common_error: 'Oops! Something went wrong, please try again',
  common_horizontal: 'Horizontal Layout',
  common_unit: 'Unit',
  common_supplier: 'Supplier',
  common_flavor: 'Flavor',
  concentration_of_the_base_beer: 'Concentration of the base beer',
  threshold_aml_method: 'AML Method (Ascending Method of Limits)',
  threshold_component_concentration: 'Component Concentration',
  threshold_sample_different_help: 'Which sample is different?',
  threshold_each_set_random_order: 'In each set, random order.',
  threshold_select_base_beer: 'Select Base Beer',
  common_unity: 'Unity',
  common_finish_session: 'Finish Taste',
  review_submit_threshold: 'Are you sure you want to save?',
  threshold_coder_wrong:
    'The code entered is wrong. Please enter the corrected code.',
  threshold_didnt_enter_the_code: 'Please enter your code',
  code: 'Code',
  common_batch_code: 'Batch Code',
  threshold_samples_required: 'Base beer and Flavor are required',
  threshold_information_helper_sample:
    'To save threshold sample information, please click on the Save button to keep your changes',
  code_digits: 'Enter the 3 digits code assigned to you',
  threshold_sample_spiked_with:
    'On each set, choose the sample that has been spiked with ',
  threshold_code_already_tasted:
    'This code has already been tasted by another user. Enter another code.',
  common_kpi_brands: 'KPI Brands',
  common_year: 'Year',
  common_new_kpi_brand: 'New KPI Brand',
  common_action: 'Action',
  common_comment_by_category: 'Comments by Category',
  common_alphabetic_comment: 'Alphabetical Comments',
  common_name_pts_code: 'PTS code',
  common_name_pts_code_placeholder: 'Code',
  common_name_process_item: 'Process Item',
  common_name_process_item_placeholder: 'Item',
  common_global_brands: 'Global Brands',
  common_global_brand: 'Global Brand',
  common_target_global_brand: 'Target for Global Brand',
  common_focus_brand: 'Focus Brand',
  common_target_focus_brand: 'Target for Focus Brand',
  common_strategic_brand: 'Strategic Brand',
  common_target_strategic_brand: 'Target for Strategic Brand',
  common_maximun_sites: 'Maximun sites to compare is',
  common_state_reason_rejecting: 'State the reason for rejecting for this site',
  common_rejected_note: 'Rejected note',
  report_list_report_kpi: 'KPI Reports',
  report_list_report_taste: 'Tasting Reports',
  select_brand_to_filter: 'Select a Global Brand!',
  select_year_to_filter: 'Select a Year!',
  kpi_only_numbers_warning: 'Accept only numbers on Target values',
  kpi_max_number_range_warning: 'Your Target Brand must be between 1 and 9',
  kpi_already_selected_brand_warning:
    'You have this brand selected, please choose another brand',
  kpi_no_kpi_informed_warning:
    'No KPI value has been entered. Please add the value.',
  kpi_select_year_warning: 'Select a year',
  kpi_select_site_warning: 'Select a site',
  kpi_existing_configuration_warning:
    'You have kpi values from same site and year',
  kpi_target_empty_warning: 'A target of one of your brands is empty',
  kpi_saved_successfully: 'Your values ​​have been saved successfully!',
  kpi_submitted_successfully: 'Your values ​​have been submitted successfully!',
  kpi_max_decimals_warning: 'Your target value must be up to 3 decimal',
  kpi_count_as_kpi_sample: 'Count as KPI',
  samples_uncheck_kpi_sample_tooltip:
    "Uncheck this box if you don't want this sample to count in the KPI",
  reports_mandatory_zone_filter: 'Select at least one zone',
  common_approve_reject: 'Approve/Reject',
  common_approve: 'Approve',
  submission_type: 'Submission type',

  custom_scale_title: 'Customized Scale',
  custom_scale_grid_header_score: 'Score',
  custom_scale_grid_header_ageind_scale: 'Forced Ageing Scale',
  the_value_cannot_be_null: 'The value cannot be null!',
  unique_id: 'Unique ID',
  report_filter_rounds_description:
    'You must select the mandatory fields to load rounds options.',
  reports_mandatory_test_type: 'Select the test type.',
  round_reports_month_warning:
    'The period between the start date and the end date must be a maximum of 6 months.',
  zone_can_not_empty: 'Zone can not be empty!',
  error_loading_data:
    'Error loading data.Please check your connection and reload.',
  flavor_identification: 'Flavor Identification',
  beer_batch: 'Beer batch',
  list_of_beers: 'List of beers',
  random: 'Random',
  preview: 'Seat Preview',
  select_the_flavor: 'Select the flavor',
  flavor_threshold: 'Flavor Threshold',
  input_digital_number: 'Input digital number...',
  concentration: 'Concentration',
  flavor_order: 'Flavor Order:',
  last: 'Last',
  tasting: 'Tasting',
  confirmation: 'Confirmation',
  do_you_confirm_the_evaluation_of_this_sample:
    'Do you confirm the evaluation of this sample?',
  confirm: 'Confirm',
  evaluate: 'Evaluate',
  correct_answer: 'Correct Answer',
  threshold: 'Threshold',
  let_a_comment_about_the_tasting: 'Let a comment about the tasting',
  send: 'Send',
  right_now: 'Right Now',
  minute_ago: 'm ago',
  hour_ago: 'h ago',
  day_before: 'd before',
  year_ago: 'y ago',
  add_a_flavor: 'Add a flavor',
  soft_drinks: 'Soft drinks',
  soft_drinks_brand: 'Soft Drink Brand',
  soft_drinks_brand_target: 'Soft Drink Brand Target',
  column_options: 'Column Options',
  select_which_of_the_columns_do_you_want_to_be_displayed:
    'Select which of the columns do you want to be displayed.',
  countdown: 'Countdown',
  less_than_five_minutes:
    'This tasting session will end in 5 minutes,  if you need to proceed, please contact admin to delay finish date.',
  the_countdown_to_the_evaluation_has_ended:
    'You can not enter as the countdown of this tasting session has ended',
  counting_how_much_time_does_you_have_to_finish_tasting_the_samples:
    'Counting how much time does you have to finish tasting the samples',
  only_six_flavors_can_be_added_at_most: 'Only 6 flavors can be added at most!',
  concentration_of_the_flavor: 'Concentration of the flavor',
  input_value: 'Please enter',
  Concentration_of_the_flavor_is_required:
    'Concentration of the flavor is required',
  previous_round: 'Previous Round',
  next_round: 'Next Round',
  the_end_time_is_greater_than_the_current_time:
    'The end time must be greater than the current time, if you select the same day as the current day, select the hour and minute first!',
  check_the_answer_at_the_end_of_the_countdown:
    'Please wait until the countdown goes to zero to check the result.',
  incremental_step: 'Incremental Step',
  set3_concentration: 'Set3 Concentration',
  concentration_increments_between_levels:
    'Concentration increments between levels',
  incremental_set3_concentration_required:
    'Incremental Step and Set3 Concentration are required',
  sprint: 'Sprint',
  sprint_number_greater_than_zero: 'Sprint number must be greater than 0',
  sprint_notes_at_least_one_note: 'Sprint notes must have at least one note',
  sprint_patch_notes_success_update: 'Sprint patch notes updated with success.',
  sprint_patch_notes_error_update: 'It was not possible to save the notes.',
  update_sprint_patch_notes: 'Update Sprint Patch Notes',
  sprint_number: 'Sprint number',
  sprint_patch_notes: 'Sprint patch notes',
  note: 'Note',
  select_all: 'Select All',
  please_add_samples_first: 'Please add samples first',
  reverse: 'Reverse',
  score_scale: 'Ab InBev scale',
  description: 'Description',
  aditional_criteria: 'Aditional Criteria',
  new_test: 'New Test',
  triangle_test: 'Triangle Test',
  triangle_test_name: 'Triangle Test Name',
  you_can_not_set_three_identical_samples:
    'You can not set 3 identical samples',
  its_mandatory_to_input_test_name: "It's mandatory to input Test Name.",
  its_mandatory_to_input_sample_information:
    "It's mandatory to input Sample Information.",
  attention: 'Attention',
  test_number: 'Test Number',
  you_selected_the: 'You selected the',
  next_sample: 'Next Sample',
  remove_triangle_test: 'Remove Triangle Test',
  test: 'Test',
  confirm_remove: 'Do you confirm to delete this test?',
  you_can_only_select_up_to_five: 'You can only select up to 5 varieties.',
  reports_mandatory_zone_country_site_filter:
    'Zone, Country, Site are mandatory!',
  reports_mandatory_brand_filter: 'Select at least one brand',
  download_label: 'Download Label',
  reports_by_producing_site: 'Reports by Producing Site',
  standard: 'Standard',
  input_test: 'Input Test',
  detecting: 'Detecting···',
  connected: 'Connected',
  current_taster: ' Current Taster',
  alcohol_concentration: 'Alcohol Concentration',
  your_alcohol_test_result_exceeds_standard_please_test_again_later:
    'Your alcohol test result exceeds standard. Please test again later!',
  alcohol_test_qualified: 'Alcohol test qualified',
  configuration: 'Configuration',
  input_result: 'Input Result',
  maximum_alcohol_concentration: 'Excess Alcohol Concentration',
  breathalyzer: 'Breathalyzer',
  please_input_a_positive_number_up_to_n_decimal_places:
    'Please input a positive number up to {{field}} decimal places',
  you_must_input: 'You must input',
  driving: 'Driving',
  test_time: 'Test Time',
  input_type: 'Input Type',
  list_breathalyzer: 'Breathalyzer',
  Manual: 'Manual',
  Breathalyzer: 'Breathalyzer',
  you_do_not_have_privilege_to_export_this_report:
    'You do not have privilege to export this report',
  competitor_beer: 'Competitor Beer',
  split_round: 'Split Round',
  reset: 'Reset',
  how_many_rounds_splitting_do_you_want_to_make:
    'How many rounds of splitting do you want to make',
  you_cannot_split_a_round_that_has_already_been_split_or_in_progress:
    'You cannot split a round that has already been split or in-progress',
  hide_sample_details: 'Hide Sample Details',
  data_will_be_hidden_from_the_taster_brewery_production_date_brand_malt_variety_and_sample_id:
    'Data will be hidden from the tasters: Brewery, Production date, Brand, Malt Variety and Sample ID',
  data_will_be_hidden_for_ok_nok:
    'All relevant data will be hidden from tasters',
  you_can_not_add_duplicate_attributes: 'You can not add duplicate attributes.',
  menu: 'MENU',
  link_breathalyzer: 'Connected',
  not_link_breathalyzer: 'Unconnected',
  breathalyzer_to_link: 'Connect Breathalyzer',
  breathalyzer_can_not_to_link_for_this_browser:
    'The breathalyzer capability is developed to work on Google Chrome, using PC devices.',
  reopen: 'Reopen',
  round_reopen_message: 'Are you sure you want to reopen the Round',
  common_production_line: 'Production line',
  common_packaged_beer_data_code: 'Packaged Beer Data Code',
  common_masking_process_for_bottles: 'Masking Process for Bottles',
  common_str_number_days_over_aged: 'Number of days when over-aged',
  common_over_aged_beer: 'Over-aged Beer',
  sample_successfully_swapped: 'Sample successfully swapped.',
  scoring: 'Scoring',
  configure_available_flavors: 'Configure Available Flavors',
  process_check: 'Process Check',
  new_process_check: 'New Process Check',
  edit_process_check: 'Edit Process Check',
  remove_process_check: 'Remove Process Check',
  common_package: 'Package',
  common_department: 'Department',
  common_shift: 'Shift',
  common_tank: 'Tank',
  common_oknok: 'OKNOK',
  common_remark: 'Remark',
  show_all_process_check: 'Show All Process Check',
  show_all_process_check_help_circle:
    'Showing Process Check created six months from today, Check this box if you also want to see Process Check created one year from today',
  the_sample_date_cannot_be_earlier_than_the_current_time:
    'The Sample Date cannot be greater than the current time!',
  common_job_position: 'Job Position',
  decimal_sep: 'Decimal Sep',
  is_swapped_to: 'swap to',
  review_warning_comment_title: 'You have uncommented samples!',
  continuous_typing: 'Continuous Typing',
  continuous_typing_tips:
    "Countinue typing 'Attribute' after selecting/typing 'Intensity'.",
  BSSI_Reports: 'BSSI Reports',
  data_analysis_sip: 'Data Analysis - SIP',
  select_the_color: 'Select the color',
  Color: 'Color',
  download_document: 'Download Documents',
  Month_of_Discontinued_Production: 'Month of Discontinued Production',
  Suspension_List: 'Suspension List',
  New_Suspension: 'New Suspension',
  new_suspension_page: 'New Suspension',
  edit_suspension_page: 'Edit Suspension',
  Brand_name: 'Brand Name',
  List_Brewery_Production_Suspension: 'Brewery Production Suspension List',
  Upload_successfully: 'File uploaded successfully!',
  training_materials: 'Click to view training materials.',
  Remove_Suspension: 'Remove Suspension: ',
  Add_Absence_Reason: 'Add Absence Reason',
  Reason: 'Reason',
  You_have_duplicate_absence_dates: 'You have duplicate absence dates',
  Remove_Absence: 'Remove absence: ',
  Absence_Title: 'Please select time and reason for your tasting absence',
  Shipping_Site: 'Shipping Site',
  shipping_received: 'Shipping Received',
  receive_sample_alert: 'Are you sure you have received the sample?',
  receive_uncheck_alert: 'Are you sure you want to uncheck it?',
  all_received: 'All Received',
  not_all_received: 'Not All Received',
  not_for_shipping: 'Not For Shipping',
  receive_hint: 'Select all sites that will be receiving this sample',
  absence_reason: 'Absence Reason',
  Page1: 'Page {{field}}',
  Search: 'Search',
  page_total: 'Total',
  start_date: 'Start Date',
  end_date: 'Finish Date',
  ok_for_all: 'OK for All',
  last_days: 'Last {{field}} days',
  today: 'Today',
  its_mandatory_to_select_sample_A_and_sample_B:
    "It's mandatory to select sample A and sample B.",
  its_mandatory_to_select_sample_A: "It's mandatory to select sample A.",
  its_mandatory_to_select_sample_B: "It's mandatory to select sample B.",
  triangle_comment: 'Comment',
  select_sample: 'Select Sample',
  sample_date_filter_last_7_days_in_default_export_to_Excel_based_on_this_filtered_duration:
    'Sample Date filter, last 7 days in default, export to Excel based on this filtered duration.',
  select_all_flavor: 'Select All',
  deselect_all: 'Unselect All',
  email_distribution: 'Email Distribution',
  recipients: 'To',
  cc_recipients: 'CC',
  new_email_distribution: 'New Email Distribution',
  edit_email_distribution: 'Edit Email Distribution',
  distribution_type: 'Distribution Type',
  the_score_entered_is_invalid:
    'The score entered is invalid.  Please refer to the score scale and enter your score again.',
  service_now:
    'Click to create an incident ticket to report an issue and get help.',
  service_now_tips:
    'Hey, if you see any issue or would like to ask for support you can click here and report it directly in serviceNow, our support team will get in touch with you as soon as possible.',
  done: 'Done',
  changes_you_made_may_not_be_saved:
    'Changes you made may not be saved. Do you want to continue and lose changes?',
  cannel_auto_save_tips:
    'Now the performance of tasting page is improved, please remember to click Finish Sample to save and submit your tasting result.',
  list_email_page_tips:
    'This is the instruction of how to setup correct KPI sample, in order to make sure the Zone Monthly Report can reflect exact analysis for your zone MCS tasting',
  list_email_page_helps:
    'The instruction of how to setup KPI sample to reflect exact data in your Zone Monthly Report.',
  kpi_instruction: 'KPI Instruction',
  group_no_recipients_message: 'At least one recipient is needed.',
  the_current_distribution_type_already_exists:
    'The current distribution type already exists, please reselect the type or return the list.',
  distribution_type_message_empty:
    'Distribution Type not permitted empty or blank',
  add_samples: 'Add sample(s) to the Round',
  copy_this_sample: 'Duplicate the sample',
  remove_this_sample: 'Remove the Sample',
  multiple_selection_tips: `You can select several samples at once for the same sample type. Click on '+' and '-' to try it out.`,
  all_add_once_tips: `Click on the 'Select All' button to automatically add 1 sample to each selected sample type.`,
  copy_confirm_tip: 'Are you sure you want to copy the sample?',
  user_non_abi_user: 'New Temporary User',
  language_of_email_invitation: 'Language of Email Invitation',
  add_non_abi_users_to_round:
    'Temporary user will be receiving email invitation with a link to attend the specific tasting.',
  change_non_abi_users_to_round:
    'Temporary taster you invited will be receiving email invitation with a link to attend this tasting.',
  guest: 'Guest',
  email_to_all: 'Email To All',
  send_email: 'Send invitation email',
  temporary_non_abi_taster: 'Temporary Taster',
  user_type: 'User Type',
  email_invitation_delivery_request_sent: 'Round invitation email is sent!',
  incorrect_e_mail_formatting: 'Incorrect e-mail formatting',
  all_tasters: 'All Tasters',
  key_tasters: 'Key Tasters',
  non_key_tasters: 'Non Key Tasters',
  temporary: 'Temporary User',
  maximum_number_of_samples_is: 'Maximum number of samples is',
  sample_added: 'Samples added',
  click_to_view_scale_of_flavour_intensities:
    'Click the link to see the detailed intensity scales',
  list_next_week_plan: 'Planning Week Sessions',
  common_time_zone: 'Time Zone',
  common_last_response_time: 'Last Response Time',
  new_week_plan: 'New Week Sessions',
  invited_week: 'Invited Week',
  email_language: 'Email Language',
  save_and_send_email: 'Save and Send Email',
  common_start_time: 'Start Time',
  common_end_time: 'End Time',
  session_name_email_subject: 'Session Name / Email Subject',
  location: 'Location',
  calendar_confirm: 'Calendar Confirm',
  edit_week_plan: 'Edit Week Sessions',
  common_session: 'Sessions',
  round_remove_plan: 'Are you sure you want to delete the plan',
  email_response: 'Email Response',
  are_you_confirming_to_send_the_invitation_email:
    'Are you confirming to send the invitation email',
  view_week_plan: 'View Week Sessions',
  are_you_confirming_to_send_the_confirmation_email:
    'Are you confirming to send the confirmation email',
  every_10_minutes_system_will_update_the_email_response_from_next_week_plan_invitation:
    'Every 10 minutes system will update the email response from planning week sessions invitation',
  documents_upload_tips: 'File upload only supports {{field}}',
  ignore_submit: 'Ignore&Submit',
  if_count_as_kpi:
    'If this is necessary for the calculation of BSSI and GBSI, don’t forget to ‘Count as KPI’ for the sample KPIs below',
  confirmed_count: 'Confirmed Count',
  accepted: 'Accepted',
  declined: 'Declined',
  no_response: 'No Response',
  apply_to_all_sessions: 'Apply To All Sessions',
  reminder_time: 'Reminder Time',
  are_you_sure_you_want_to_change_the_week_of_invitation:
    'Are you sure you want to remove the Session Start/End Time you have selected, and change to another Invited Week',
  apply_to_all_sessions_location_tasters:
    'Apply "Location" and "Tasters" to following sessions',
  upload_tasters: 'Upload Tasters',
  mcs_tasting: 'MCS Tasting',
  mcs_tasting_tips:
    'Select Yes if this round contains MCS/KPI samples, system will apply related KPI Code per the sample brand.',
  round_samples_shuffle_confirm: 'Are you sure you want to shuffle the Sets?',
  all_count_as_kpi: 'All Count as KPI',
  msc_tasting_tour_mask:
    'System will identify related KPI Code automatically based on counted sample with right KPI Month, so you DO NOT have to split different brands in one round',
  competition: 'Competition',
  copy_link: 'Copy Link',
  open_link: 'Open Link',
  common_download_img: 'Download IMG',
  please_select_taster: 'Please chose your name',
  qr_code: 'QR Code',
  global_id: 'Global ID',
  user_principal_name: 'User Principal Name',
  user_status: 'User Status',
  termination_date: 'Termination Date',
  function_level: 'Function Level',
  last_update_time: 'Last Update Time',
  system_related: 'System Related',
  workday_related: 'WorkDay Related',
  employee_id: 'Employee ID',
};
