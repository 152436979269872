import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Label from '../label/Label';
import datePickerStyle from './datePickerField.module.scss';
import './react-datepicker.scss';
import { Calendar, X } from 'react-feather';
import Moment from 'moment';

const DatePickerField = props => {
  const [date, setDate] = useState(null);
  useEffect(() => {
    if (props.value !== '') {
      setDate(props.value);
    } else {
      setDate(null);
    }
  }, [props.value]);

  const handleChangeDateTimePicker = evt => {
    props.onChange(evt, props.funcData ? props.funcData : '');
  };

  // function to prevent user from typing in the input
  const onKeyDown = evt => {
    evt.preventDefault();
  };

  const addYears = (dt, n) => {
    return new Date(dt.setFullYear(dt.getFullYear() + n));
  };

  return (
    <div
      style={{
        margin: props.margin,
        flex: '1 1',
        minWidth: '160px',
        ...props.style,
      }}
    >
      <div className={datePickerStyle.datePickerContainer}>
        <div>
          <Label label={props.label} required={props.isRequired} />
        </div>
        <input
          className={
            props.alcohol
              ? datePickerStyle.datePickerAlcoholContainer
              : datePickerStyle.datePickerWrapper
          }
          name={props.name}
          type={props.type}
          value={props.value}
          disabled={props.disabled}
          onChange={handleChangeDateTimePicker}
          onKeyDown={onKeyDown}
          step={props.step ? props.step : ''}
          max={props.maxDate}
          min={props.minDate}
          style={{
            padding: props.padding ? props.padding : '',
            height: props.height ? props.height : '39px',
          }}
        ></input>
      </div>
    </div>
  );
};

DatePickerField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  value: PropTypes.any,
  format: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  funcData: PropTypes.object,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  disabled: PropTypes.bool,
  showClock: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default DatePickerField;
