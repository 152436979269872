import React, { useState, useEffect } from 'react';
import editUserStyle from './edituser.module.scss';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Label,
  Card,
  CardRow,
  DropdownField,
  Button,
  CardColumn,
} from '../../../components';
import ProfileService from '../../services/users/profile.service';
import UserService from '../../services/users/user.service';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../../contexts/User';
import _ from 'lodash';
import CNIMG from '../../../assets/languagePic/CN.png';
import CSIMG from '../../../assets/languagePic/CS.png';
import ENIMG from '../../../assets/languagePic/EN.png';
import PTIMG from '../../../assets/languagePic/PT.png';
import ESIMG from '../../../assets/languagePic/ES.jpg';
import NLIMG from '../../../assets/languagePic/NL.jpg';
import FRIMG from '../../../assets/languagePic/FR.jpg';
import DEIMG from '../../../assets/languagePic/DE.jpg';
import RUIMG from '../../../assets/languagePic/RU.jpg';
import UAIMG from '../../../assets/languagePic/UA.jpg';
import KRIMG from '../../../assets/languagePic/KR.jpg';
import ITIMG from '../../../assets/languagePic/IT.jpg';
import DefaultAvatar from '../../../assets/default_avatar.png';

const EditUser = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const [user, setUser] = useState({
    ...props.location.state.values,
  });
  const [profiles, setProfiles] = useState([]);
  const [jobPositions, setJobPosition] = useState([]);
  const [privilege, setPrivilege] = useState([]);
  const profileService = new ProfileService();
  const userService = new UserService();
  const loggedUser = useUser();
  const [saveDisable, setSaveDisable] = useState(true)

  const languageOpts = [
    {
      value: 'en',
      label: 'EN',
      imgUrl: ENIMG,
    },
    {
      value: 'zh',
      label: 'CN',
      imgUrl: CNIMG,
    },
    {
      value: 'pt',
      label: 'PT',
      imgUrl: PTIMG,
    },
    {
      value: 'es',
      label: 'ES',
      imgUrl: ESIMG,
    },
    {
      value: 'nl',
      label: 'NL',
      imgUrl: NLIMG,
    },
    {
      value: 'fr',
      label: 'FR',
      imgUrl: FRIMG,
    },
    {
      value: 'de',
      label: 'DE',
      imgUrl: DEIMG,
    },
    {
      value: 'ru',
      label: 'RU',
      imgUrl: RUIMG,
    },
    {
      value: 'uk',
      label: 'UA',
      imgUrl: UAIMG,
    },
    {
      value: 'kr',
      label: 'KR',
      imgUrl: KRIMG,
    },
    {
      value: 'it',
      label: 'IT',
      imgUrl: ITIMG,
    },
    {
      value: 'cs',
      label: 'CZ',
      imgUrl: CSIMG,
    },
  ];


  useEffect(() => {
    refreshState();
    if (!props.location.state.show) {
      setUser(prevState => {
        return {
          ...prevState,
          uuid_zone: JSON.parse(sessionStorage.getItem('user')).uuid_zone,
          uuid_country: JSON.parse(sessionStorage.getItem('user')).uuid_country,
          uuid_site: JSON.parse(sessionStorage.getItem('user')).uuid_site,
        };
      });
    }
  }, []);

  const refreshState = () => {
    //GET PROFILES
    profileService.listProfiles(null).then(
      res => {
        if (res.type === 'success' && res.results) {
          setProfiles(res.results);
        } else {
          window.alert(res.message);
        }
      },
      error => {
        //console.log(error);
      },
    );
    setPrivilege(props.location.state.privilege);
    userService.getJobPosition(null).then(res => {
      if (res.type === 'success' && res.results) {
        setJobPosition(res.results);
      } else {
        window.alert(res.message);
      }
    });
  };

  const handleChange = evt => {
    const value = evt.target.value;
    const trim_value = _.trim(value);
    setUser({
      ...user,
      [evt.target.name]: evt.target.name === 'str_name' ? value : trim_value,
    });
  };

  useEffect(() => {
    changeDisable();
  }, [user])

  const handleSave = async () => {
    if (!user.uuid_profile) {
      user.uuid_profile = null;
    }
    if (!user.uuid_job_position) {
      user.uuid_job_position = null;
    }
    if (!user.uuid_zone) {
      window.alert(t('zone_can_not_empty'));
      return;
    }
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!pattern.test(user.str_email)) {
      window.alert(t('incorrect_e_mail_formatting'));
      return;
    }
    let res = null;
    if (props.location.state.values.str_user_type !== 'Temporary') {
      res = await userService.updateUser(user)
    } else {
      res = await userService.updateNonAbiUser(user)
    }
    if (res.type === 'success' && res.results) {
      if (user.uuid_user === loggedUser.user.uuid_user) {
        const tempUser = loggedUser.user;
        const userSite = privilege.arr_sites.filter(
          item => item.uuid_site === user.uuid_site,
        )[0];
        const userBU = privilege.arr_bu.filter(bu => bu.uuid_site === userSite.uuid_site)[0]
        tempUser.uuid_bu = userBU.uuid_bu
        tempUser.str_name_bu = userBU.str_name
        tempUser.uuid_country = user.uuid_country;
        tempUser.uuid_profile = user.uuid_profile;
        tempUser.uuid_job_position = user.uuid_job_position;
        tempUser.uuid_site = user.uuid_site;
        tempUser.uuid_zone = user.uuid_zone;
        tempUser.str_name_country = privilege.arr_countries.filter(
          item => item.uuid_country === user.uuid_country,
        )[0]?.str_name;
        tempUser.str_name_site = userSite.str_name
        tempUser.str_name_zone = privilege.arr_zones.filter(
          item => item.uuid_zone === user.uuid_zone,
        )[0]?.str_name;

        loggedUser.handleUser(tempUser);
      }
      history.goBack();
    } else {
      window.alert(res.message);
    }
  }

  const changeDisable = () => {
    if (props.location.state.values.bol_abi_account != false) {
      // setSaveDisable(!user.str_abi_id || !user.str_name || (!props.location.state.show && !user.str_email) || !user.uuid_zone)
      setSaveDisable(!user.str_name || (!props.location.state.show && !user.str_email) || !user.uuid_zone)
    } else if (props.location.state.values.str_user_type != 'ABI') {
      setSaveDisable(!user.str_name || !user.str_email || !user.str_language || !user.uuid_zone)
    }
  }

  const renderUser = (row) => {
    return <div className={editUserStyle.teamUser}>
      <img
        src={DefaultAvatar}
        alt="Picture"
        height="40"
        width="40"
      />
      <div className={editUserStyle.teamUserInfo}>
        <p className={editUserStyle.teamUserName}>{row.str_name ?? 'Supper Admin'}</p>
        <p className={editUserStyle.teamUserEmail}>{row.str_email ?? 'supper.admin@budweiserapac.com'}</p>
      </div>
    </div>
  };

  return (
    <div className={editUserStyle.pageContainer}>
      <Label label={t('user_edit_user')} bold neutral />
      <CardRow justifyContent="space-between" flexWrap="wrap" transparent>
        <p style={{ color: '#F49b00', display: 'flex', alignItems: 'center', fontWeight: '600' }}>
          {
            props.location.state.values.str_user_type === 'Temporary' &&
            <span>Tips:&ensp;
              {t('add_non_abi_users_to_round')}
            </span>
          }
        </p>
        <div>
          <Button
            label={t('common_cancel')}
            onClick={() => {
              history.goBack();
            }}
            neutral
            margin="0 10px 0px 0px"
          />
          <Button
            label={t('common_save')}
            onClick={handleSave}
            confirm
            margin="0"
            disabled={saveDisable}
          />
        </div>
      </CardRow>
      <Card transparent padding="0px" margin="12px 0 0">
        <CardRow transparent flex={1}>
          <CardColumn
            padding="24px"
            style={{
              borderRadius: '10px',
              alignItems: 'center',
              background: '#fff',
              minWidth: '450px',
            }}
            bordered

          >
            <CardRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
              <img
                className={editUserStyle.avatar}
                src={DefaultAvatar}
                alt="Picture"
                height="100"
                width="100"
              />
              {
                user.str_abi_id &&
                  props.location.state.values.str_user_type != 'ABI' ?
                  <p className={editUserStyle.abiID}>
                    {t('employee_id') + ": " + user.str_abi_id}
                  </p> :
                  null
              }
            </CardRow>
            <CardColumn
              padding="30px 0 0 0"
            >
              {
                props.location.state.values.str_user_type != 'ABI' &&
                <CardRow flexWrap="wrap" >
                  <DropdownField
                    name="uuid_zone"
                    options={privilege.arr_zones ? privilege.arr_zones : []}
                    valueField="uuid_zone"
                    labelField="str_name"
                    label={t('common_zone')}
                    margin="10px 10px 0px 0px"
                    value={user.uuid_zone}
                    onChange={value => {
                      user.uuid_country = null;
                      user.uuid_site = null;
                      handleChange(value);
                    }}
                    isRequired
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                  <DropdownField
                    name="uuid_country"
                    options={
                      privilege.arr_countries && user.uuid_zone
                        ? privilege.arr_countries.filter(country => {
                          return country.uuid_zone === user.uuid_zone;
                        })
                        : []
                    }
                    valueField="uuid_country"
                    labelField="str_name"
                    label={t('common_country')}
                    margin="10px 10px 0px 0px"
                    value={user.uuid_country}
                    onChange={value => {
                      user.uuid_site = null;
                      handleChange(value);
                    }}
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                  <DropdownField
                    name="uuid_site"
                    options={
                      privilege.arr_sites && user.uuid_country
                        ? privilege.arr_sites.filter(site => {
                          return site.uuid_country === user.uuid_country;
                        })
                        : []
                    }
                    valueField="uuid_site"
                    labelField="str_name"
                    label={t('common_site')}
                    margin="10px 10px 0px 0px"
                    value={user.uuid_site}
                    onChange={handleChange}
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                </CardRow>
              }
              {
                user.str_abi_id && props.location.state.values.str_user_type === 'ABI' ?
                  <TextField
                    name="str_user_type"
                    label={t('Employee ID')}
                    margin="10px 0 0px 0px"
                    value={user.str_abi_id}
                    disabled
                    className={editUserStyle.onlyWorkDayShowText}
                    labelStyle={{
                      fontWeight: 'bold',
                      flex: '1',
                      textAlign: 'center'
                    }}
                    style={{
                      textAlign: 'center'
                    }}
                  />
                  :
                  null
              }
              <CardRow flexWrap="wrap">
                <TextField
                  name="str_name"
                  label={t('common_name')}
                  margin="10px 10px 0px 0px"
                  value={user.str_name}
                  onChange={handleChange}
                  disabled={props.location.state.values.str_user_type === 'ABI'}
                  className={
                    props.location.state.values.str_user_type === 'ABI'
                      ? editUserStyle.onlyWorkDayShowText : ''
                  }
                  isRequired={props.location.state.values.str_user_type !== 'ABI'}
                  labelStyle={
                    props.location.state.values.str_user_type === 'ABI' ?
                      {
                        fontWeight: 'bold',
                        flex: '1',
                        textAlign: 'center'
                      } :
                      {
                        fontWeight: 'bold',
                      }
                  }
                  style={
                    props.location.state.values.str_user_type === 'ABI' ?
                      {
                        textAlign: 'center'
                      } :
                      null
                  }
                  minWidth='402px'
                />
                <TextField
                  name="str_email"
                  disabled={
                    typeof props.location.state.show === 'boolean' &&
                    props.location.state.show
                  }
                  className={
                    typeof props.location.state.show === 'boolean' &&
                      props.location.state.show
                      ? (
                        props.location.state.values.str_user_type === 'ABI' ?
                          editUserStyle.onlyWorkDayShowText
                          : editUserStyle.onlyShowText
                      ) : ''
                  }
                  label={t('common_email')}
                  margin="10px 10px 0px 0px"
                  value={
                    props.location.state.values.str_user_type === 'ABI' ?
                      user.str_email ?? "N/A"
                      : user.str_email ?? ''
                  }
                  onChange={handleChange}
                  isRequired={
                    !(typeof props.location.state.show === 'boolean' &&
                      props.location.state.show)
                  }
                  labelStyle={
                    props.location.state.values.str_user_type === 'ABI' ?
                      {
                        fontWeight: 'bold',
                        flex: '1',
                        textAlign: 'center'
                      } :
                      {
                        fontWeight: 'bold',
                      }
                  }
                  style={
                    props.location.state.values.str_user_type === 'ABI' ?
                      {
                        textAlign: 'center'
                      } :
                      null
                  }
                  minWidth='402px'
                />
                {
                  props.location.state.values.str_user_type === 'Temporary' &&
                  <DropdownField
                    name="str_language"
                    options={languageOpts}
                    valueField="value"
                    labelField="label"
                    label={t('language_of_email_invitation')}
                    margin="10px 10px 0px 0px"
                    value={user.str_language}
                    onChange={handleChange}
                    bolOrder={false}
                    isRequired
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                }
              </CardRow>
              {
                props.location.state.values.str_user_type === 'Contractor' &&
                <CardRow
                  flexWrap="wrap"
                >
                  <DropdownField
                    name="uuid_profile"
                    options={profiles}
                    valueField="uuid_profiles"
                    labelField="str_name"
                    label={t('common_profile')}
                    margin="10px 10px 0px 0px"
                    value={user.uuid_profile}
                    onChange={handleChange}
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                  <DropdownField
                    name="uuid_job_position"
                    options={jobPositions}
                    valueField="uuid_job_position"
                    labelField="str_name_job_position"
                    label={t('common_job_position')}
                    margin="10px 10px 0px 0px"
                    value={user.uuid_job_position}
                    onChange={handleChange}
                    bolOrder={false}
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                </CardRow>
              }
              <TextField
                name="str_user_type"
                label={t('user_type')}
                margin="10px 0 0px 0px"
                value={props.location.state.values.str_user_type}
                disabled
                className={editUserStyle.onlyWorkDayShowText}
                labelStyle={
                  props.location.state.values.str_user_type === 'ABI' ?
                    {
                      fontWeight: 'bold',
                      flex: '1',
                      textAlign: 'center'
                    } :
                    {
                      fontWeight: 'bold',
                    }
                }
                style={
                  props.location.state.values.str_user_type === 'ABI' ?
                    {
                      textAlign: 'center'
                    } :
                    null
                }
              />
            </CardColumn>

          </CardColumn>
          {
            props.location.state.values.str_user_type == 'ABI' &&
            <CardColumn transparent padding={'0px 0 0 10px'} flex={4}>
              <CardColumn
                className={editUserStyle.card}
                style={{ marginBottom: '10px' }}
              >
                <h3 className={editUserStyle.title}>{t('system_related')}</h3>
                <CardRow flexWrap="wrap">
                  <DropdownField
                    name="uuid_zone"
                    options={privilege.arr_zones ? privilege.arr_zones : []}
                    valueField="uuid_zone"
                    labelField="str_name"
                    label={t('common_zone')}
                    margin="10px 10px 0px 0px"
                    value={user.uuid_zone}
                    onChange={value => {
                      user.uuid_country = null;
                      user.uuid_site = null;
                      handleChange(value);
                    }}
                    isRequired
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                  <DropdownField
                    name="uuid_country"
                    options={
                      privilege.arr_countries && user.uuid_zone
                        ? privilege.arr_countries.filter(country => {
                          return country.uuid_zone === user.uuid_zone;
                        })
                        : []
                    }
                    valueField="uuid_country"
                    labelField="str_name"
                    label={t('common_country')}
                    margin="10px 10px 0px 0px"
                    value={user.uuid_country}
                    onChange={value => {
                      user.uuid_site = null;
                      handleChange(value);
                    }}
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                  <DropdownField
                    name="uuid_site"
                    options={
                      privilege.arr_sites && user.uuid_country
                        ? privilege.arr_sites.filter(site => {
                          return site.uuid_country === user.uuid_country;
                        })
                        : []
                    }
                    valueField="uuid_site"
                    labelField="str_name"
                    label={t('common_site')}
                    margin="10px 10px 0px 0px"
                    value={user.uuid_site}
                    onChange={handleChange}
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                </CardRow>
                <CardRow
                  flexWrap="wrap"
                >
                  <DropdownField
                    name="uuid_profile"
                    options={profiles}
                    valueField="uuid_profiles"
                    labelField="str_name"
                    label={t('common_profile')}
                    margin="10px 10px 0px 0px"
                    value={user.uuid_profile}
                    onChange={handleChange}
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                  <DropdownField
                    name="uuid_job_position"
                    options={jobPositions}
                    valueField="uuid_job_position"
                    labelField="str_name_job_position"
                    label={t('common_job_position')}
                    margin="10px 10px 0px 0px"
                    value={user.uuid_job_position}
                    onChange={handleChange}
                    bolOrder={false}
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                </CardRow>
              </CardColumn>

              <CardColumn className={editUserStyle.card}>
                <h3 className={editUserStyle.title}>{t('workday_related')}</h3>
                <CardRow
                  flexWrap="wrap"
                >
                  <TextField
                    name="str_global_id"
                    label={t('global_id')}
                    margin="10px 10px 0px 0px"
                    value={user.str_global_id ?? 'N/A'}
                    disabled
                    className={editUserStyle.onlyWorkDayShowText}
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                  <TextField
                    name="str_function_level_1"
                    label={t('function_level')}
                    margin="10px 10px 0px 0px"
                    value={user.str_function_level_1 ?? 'N/A'}
                    disabled
                    className={editUserStyle.onlyWorkDayShowText}
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                </CardRow>
                <CardRow flexWrap='wrap'>
                  <TextField
                    name="str_employee_status"
                    label={t('user_status')}
                    margin="10px 10px 0px 0px"
                    value={user.str_employee_status ?? 'N/A'}
                    disabled
                    className={editUserStyle.onlyWorkDayShowText}
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                  <TextField
                    name="dat_termination_date"
                    label={t('termination_date')}
                    margin="10px 10px 0px 0px"
                    value={user.dat_termination_date ?? 'N/A'}
                    disabled
                    className={editUserStyle.onlyWorkDayShowText}
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                </CardRow>
                <CardRow
                  flexWrap="wrap"
                >
                  <div className={editUserStyle.upnContainer}>
                    <p className={editUserStyle.upnTitle}>{t('user_principal_name')}</p>
                    <p className={editUserStyle.upnText}>
                      {user.str_user_principal_name ?? 'N/A'}
                    </p>
                  </div>
                  <TextField
                    name="dat_update_date"
                    label={t('last_update_time')}
                    margin="10px 10px 0px 0px"
                    value={user.dat_update_date ?? 'N/A'}
                    disabled
                    className={editUserStyle.onlyWorkDayShowText}
                    labelStyle={{
                      fontWeight: 'bold',
                    }}
                  />
                </CardRow>
              </CardColumn>
            </CardColumn>
          }
        </CardRow>
      </Card>
    </div >
  );
};

export default EditUser;